import React, { useMemo } from "react";
import {
  DOCUMENT_STATUS,
  getLocalDateTimeStringFromISO,
  roundToNearestNumber,
} from "../../../utils";
import { Modal, Progress, Steps, Button } from "antd";
import { CloseOutlined, ReloadOutlined } from "@ant-design/icons";
import { DocumentProcessingDetailStatusProps } from ".";

const { Step } = Steps;

const getTotalSteps = () => {
  let count = 0;
  for (const item in DOCUMENT_STATUS) {
    if (isNaN(Number(item))) count++;
  }
  // remove error status
  return count - 1;
};

const RetryMessage = () => {
  return (
    <span className="re-try">
      <div className="ant-steps-item-tail"></div>
      <p>The uploading failed. Please retry.</p>
      <Button className="outline" icon={<ReloadOutlined />} disabled={true}>
        Retry
      </Button>
    </span>
  );
};

export const DocumentProcessingStatus = ({
  visibility,
  documentDetail,
  handleClose,
}: DocumentProcessingDetailStatusProps) => {
  const stepsProgress = useMemo(() => {
    const steps = documentDetail.DocumentProcessStatuses.length;
    return steps;
  }, [documentDetail.DocumentProcessStatuses]);
  return (
    <Modal
      className="autonomizeModal addClient"
      centered
      width={587}
      visible={visibility}
      footer={null}
      closeIcon={<CloseOutlined onClick={handleClose} />}
    >
      <div className="addClient-title no-bd-bt">
        <h1 className="mb-0">Documents Processing Status</h1>
      </div>
      <div className="uploading-status">
        <p>File Processing Status</p>
        <Progress
          percent={roundToNearestNumber((stepsProgress / getTotalSteps()) * 100, 5)}
          status={
            stepsProgress > 1 &&
            documentDetail.DocumentProcessStatuses[stepsProgress - 1].Status.toUpperCase().includes(
              DOCUMENT_STATUS.ERROR
            )
              ? "exception"
              : stepsProgress === getTotalSteps()
              ? "success"
              : "active"
          }
        />
        {documentDetail && documentDetail.DocumentProcessStatuses?.length > 0 && (
          <div className="uploading-status-description">
            <Steps direction="vertical">
              {documentDetail.DocumentProcessStatuses.map((item, index) => (
                <Step
                  key={`document-status-${index}`}
                  title={item.Status}
                  subTitle={item.message}
                  description={
                    item.Status.toUpperCase().includes(DOCUMENT_STATUS.ERROR) ? (
                      <RetryMessage />
                    ) : (
                      getLocalDateTimeStringFromISO(item.createdAt)
                    )
                  }
                  status={
                    item.Status.toUpperCase().includes(DOCUMENT_STATUS.ERROR) ? "error" : "finish"
                  }
                />
              ))}
            </Steps>
          </div>
        )}
      </div>
    </Modal>
  );
};
