import React, { useState, useContext, useLayoutEffect } from "react";
import { THEME } from "../utils";

interface Context {
  theme: THEME;
  toggleTheme: () => void;
}
export const ThemeContext = React.createContext<Context>({
  theme: THEME.LIGHT,
  toggleTheme: () => {},
});

export const useThemeProvider = () => {
  const [theme, setTheme] = useState(THEME.LIGHT);

  const toggleTheme = () => setTheme((item) => (item === THEME.LIGHT ? THEME.DARK : THEME.LIGHT));

  useLayoutEffect(() => {
    if (theme === THEME.LIGHT) {
      document.documentElement.classList.remove("dark-mode");
      document.documentElement.classList.add("light-mode");
    } else {
      document.documentElement.classList.remove("light-mode");
      document.documentElement.classList.add("dark-mode");
    }
  }, [theme]);

  return { theme, toggleTheme };
};

export const useTheme = () => {
  return useContext(ThemeContext);
};
