import React, { useEffect, useMemo, useState } from "react";
import { Table, Space, Button } from "antd";
import { openModal, setDocumentDetail } from "../../../store";
import { MODAL_TYPE, getLocalDateTimeStringFromISO } from "../../../utils";
import { useDispatch } from "react-redux";
import { AppPagination, DatePicker, Loading } from "../../../components";
import { getClass, rangePresets } from "./helper";
import { RangeValue } from "rc-picker/lib/interface";
import moment, { Moment } from "moment";
import { useFetchDocuments } from "../../../hooks";
import "./copilots-details.scss";
import { SearchOutlined } from "@ant-design/icons";
import Search from "antd/lib/input/Search";

const { RangePicker } = DatePicker;

interface IDocumentsDataType extends IDocument {
  key: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  status: string;
  action: string;
  pageStatus: string;
}

const ProcessingReport = () => {
  const {
    documents,
    fetchDocuments,
    getDocumentsFilterObject,
    isDocumentsLoading,
    setIsDocumentsLoading,
    paginationObj,
    setDocuments,
    setTotalDocuments,
    sortFilter,
    totalDocuments,
    isAuthorized,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    defaultFromDate,
    defaultToDate,
    searchDocumentUUID,
    setSearchDocumentUUID,
  } = useFetchDocuments();
  const { onPaginationChange, page, pageSize, pageSizeOptions } = paginationObj;
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();

  const handleSearch = (event: any) => {
    setSearchDocumentUUID(searchText);
  };

  const handleChange = (event: any) => {
    setSearchText(event.target.value);
    if (event.target.value == "") {
      setSearchDocumentUUID("");
    }
  };

  const getColumns = () => [
    {
      title: "Document UUID",
      key: "DocumentUUID",
      dataIndex: "DocumentUUID",
    },
    {
      title: "Processing Status",
      dataIndex: "status",
      render: (text: any, value: any) => (
        <div>
          <div className="status" style={{ color: getClass(value.status) }}>
            <p style={{ color: getClass(value.status) }}>{value.status}</p>
          </div>
        </div>
      ),
    },
    {
      title: "Start Time",
      key: "createdAt",
      dataIndex: "createdAt",
    },
    {
      title: "End Time",
      key: "updatedAt",
      dataIndex: "updatedAt",
    },
    {
      title: "Detailed Logs",
      dataIndex: "Detailed Logs",
      render: (text: any, value: any) => (
        <a
          onClick={() => {
            dispatch(setDocumentDetail(value));
            dispatch(openModal(MODAL_TYPE.DOCUMENT_PROCESSING_DETAIL_HEDIS_STATS));
          }}
        >
          View
        </a>
      ),
    },
  ];

  useEffect(() => {
    fetchDocuments();
  }, [fetchDocuments, fromDate, toDate, searchDocumentUUID]);

  const dataSource: IDocumentsDataType[] = useMemo(
    () =>
      documents.map((document) => ({
        key: document.DocumentUUID,
        name: document.FileName.split("/").splice(-1)[0],
        ...document,
        createdAt: getLocalDateTimeStringFromISO(document.createdAt),
        updatedAt: getLocalDateTimeStringFromISO(document.updatedAt),
        status:
          document.DocumentProcessStatuses.length !== 0
            ? document.DocumentProcessStatuses[document.DocumentProcessStatuses.length - 1].Status
            : "",
        pageStatus: "",
        action: "",
      })),
    [documents]
  );

  const onRangeChange = (dates: RangeValue<Moment>, dateStrings: string[]) => {
    if (dates) {
      if (dates.length > 0 && dates[0]) setFromDate(dates[0]);
      if (dates.length > 1 && dates[1]) setToDate(dates[1]);
    } else {
      setFromDate(defaultFromDate);
      setToDate(defaultToDate);
    }
  };

  return (
    <div>
      <div style={{ display: "flex", marginBottom: "2em" }}>
        <div style={{ display: "flex", marginRight: "2em" }}>
          <Search
            className="record-search m-b"
            placeholder="Search for Document UUID"
            enterButton={
              isDocumentsLoading ? <Loading tip="" /> : <Button icon={<SearchOutlined />} />
            }
            value={searchText}
            onChange={(e) => handleChange(e)}
            onSearch={(e) => handleSearch(e)}
            disabled={isDocumentsLoading}
            style={{ width: "20em", marginRight: "1em" }}
          />
        </div>
        <RangePicker
          defaultValue={[fromDate, toDate]}
          presets={rangePresets}
          onChange={onRangeChange}
          className="range-picker"
        />
        <AppPagination
          className="tr m-b paginationDiv"
          {...{
            showSizeChanger: true,
            current: page,
            total: totalDocuments,
            pageSize,
            pageSizeOptions,
            onChange: onPaginationChange,
          }}
        />
      </div>
      <Table
        className="document-container-table"
        columns={getColumns()}
        rowClassName={"cursor-pointer"}
        dataSource={dataSource}
        loading={isDocumentsLoading}
        pagination={false}
      />
    </div>
  );
};

export default ProcessingReport;
