import { Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { EvidencePageResponseInfo, PegaPageDataSummary } from "../../api";
import { Loading, PdfViewer } from "../../components";
import { useAuthUser } from "../../hooks";
import { COPILOTS_NAME, DATA_ADMIN_PERMISSIONS, SEARCH_KEYWORD_DELIMINATOR } from "../../utils";
import {
  SearchResultOccurrences,
  EvidenceContent,
  FeedbackForm,
  Legends,
  CATEGORY_COLOR_MAPPING,
  EvidenceContentJSON,
  EvidenceContentPega,
  EvidenceContentAng,
} from "./evidence-viewer-container";
import TiffViewer from "../../components/tiff-viewer/TiffViewer";

const { TabPane } = Tabs;

type EnhancedViewerTabType = {
  [key: string]: string;
};

export const ENHANCED_VIEW_TABS_ANG_CCA: EnhancedViewerTabType = {
  SUMMARY: "Summary",
};

export const ENHANCED_VIEW_TABS: EnhancedViewerTabType = {
  PAGE: "AI Assisted Review",
  JSON: "JSON Output",
  OCR: "Extraction Output",
};

export const ENHANCED_VIEW_TABS_PEGA: EnhancedViewerTabType = {
  SUMMARY: "Summary",
  JSON: "JSON Output",
  PAGE: "Extraction Output",
};

interface Props {
  searchKeywords: string | null;
  currPage: number;
  setCurrPage: React.Dispatch<React.SetStateAction<number>>;
  isTiffFile: boolean;
  fileUrl: string;
  evidenceContentLoading: boolean;
  pageData: EvidencePageResponseInfo;
  pageSearchKey: string | null;
  fileId: string;
  pegaPageData: PegaPageDataSummary;
  copilotName: string;
  angData: any;
}

const determineInitialTab = (copilotName: string) => {
  if (copilotName === COPILOTS_NAME.PEGA_PRIOR_AUTH) {
    return ENHANCED_VIEW_TABS_PEGA.SUMMARY;
  } else if (copilotName === COPILOTS_NAME.CCA || copilotName === COPILOTS_NAME.ANG_INSIGHTS) {
    return ENHANCED_VIEW_TABS_ANG_CCA.SUMMARY;
  } else {
    return ENHANCED_VIEW_TABS.PAGE;
  }
};

const getTabsConfig = (copilotName: string) => {
  if (copilotName === COPILOTS_NAME.PEGA_PRIOR_AUTH) {
    return ENHANCED_VIEW_TABS_PEGA;
  } else if (copilotName === COPILOTS_NAME.CCA || copilotName === COPILOTS_NAME.ANG_INSIGHTS) {
    return ENHANCED_VIEW_TABS_ANG_CCA;
  } else {
    return ENHANCED_VIEW_TABS;
  }
};

const renderContent = (props: Props, selectedTab: string, selectedLegends: string[]) => {
  const {
    searchKeywords,
    currPage,
    setCurrPage,
    evidenceContentLoading,
    fileUrl,
    isTiffFile,
    pageData,
    fileId,
    pageSearchKey,
    pegaPageData,
    copilotName,
    angData,
  } = props;

  if (copilotName === COPILOTS_NAME.PEGA_PRIOR_AUTH) {
    return (
      <EvidenceContentPega
        pageInfo={pegaPageData}
        pageData={pageData}
        loading={evidenceContentLoading}
        selectedTab={selectedTab}
        searchKeywords={pageSearchKey || searchKeywords || ""}
        selectedLegends={selectedLegends}
        fileId={fileId}
      />
    );
  } else if (copilotName === COPILOTS_NAME.ANG_INSIGHTS || copilotName === COPILOTS_NAME.CCA) {
    return (
      <EvidenceContentAng
        pageInfo={angData}
        loading={evidenceContentLoading}
        selectedTab={selectedTab}
        searchKeywords={pageSearchKey || searchKeywords || ""}
        selectedLegends={selectedLegends}
        fileId={fileId}
      />
    );
  } else {
    return (
      <EvidenceContent
        pageInfo={pageData}
        loading={evidenceContentLoading}
        selectedTab={selectedTab}
        searchKeywords={pageSearchKey || searchKeywords || ""}
        selectedLegends={selectedLegends}
        fileId={fileId}
        currPage={currPage}
      />
    );
  }
};

export const EvidenceViewerContainer = (props: Props) => {
  const {
    searchKeywords,
    currPage,
    setCurrPage,
    evidenceContentLoading,
    fileUrl,
    isTiffFile,
    pageData,
    fileId,
    pageSearchKey,
    pegaPageData,
    copilotName,
    angData,
  } = props;

  const [selectedTab, setSelectedTab] = useState(determineInitialTab(copilotName));
  const [selectedLegends, setSelectedLegends] = useState([] as string[]);
  const { roleCode } = useAuthUser();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const insightType = queryParams.get("insight_type");
    if (insightType) {
      setSelectedLegends((prevSelectedLegends) => [...prevSelectedLegends, insightType]);
    }
  }, [location.search]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const searchTerm = queryParams.get("search");
    const categories = [] as string[];
    if (searchTerm && Object.keys(pageData).length !== 0) {
      const terms = searchTerm.toLowerCase().split(SEARCH_KEYWORD_DELIMINATOR);
      pageData.medicalEntities.forEach((entity) => {
        terms.forEach((term) => {
          if (entity.Text.toLowerCase().includes(term)) {
            categories.push(entity.Category);
          }
        });
      });
    }
    const filteredCategories = [...new Set(categories)].filter((item) =>
      Object.keys(CATEGORY_COLOR_MAPPING).includes(item)
    );
    setSelectedLegends((prevSelectedLegends) => [...prevSelectedLegends, ...filteredCategories]);
  }, [location.search, pageData.medicalEntities, pageData]);

  const onChange = (key: string) => {
    setSelectedTab(key);
  };

  const tabsConfig = getTabsConfig(copilotName);

  return (
    <div>
      <div className="tabs">
        <Tabs onChange={onChange} defaultActiveKey={selectedTab}>
          {Object.keys(tabsConfig).map((key) => (
            <TabPane tab={tabsConfig[key]} key={tabsConfig[key]} />
          ))}
        </Tabs>
      </div>
      {selectedTab === ENHANCED_VIEW_TABS.PAGE && !evidenceContentLoading && (
        <Legends {...{ selectedLegends, setSelectedLegends }} />
      )}
      <div className="evidence-page">
        {renderContent(props, selectedTab, selectedLegends)}
        {copilotName !== COPILOTS_NAME.CCA &&
          copilotName !== COPILOTS_NAME.ANG_INSIGHTS &&
          (fileUrl ? (
            isTiffFile ? (
              <TiffViewer
                tiff={fileUrl}
                pageInfo={{ height: 700, width: 550, scale: 1 }}
                setCurrPage={setCurrPage}
                currPage={currPage}
              />
            ) : (
              <PdfViewer
                pdf={fileUrl}
                pageInfo={{ height: 700, width: 550, scale: 1 }}
                displayPagination
                setCurrPage={setCurrPage}
                currPage={currPage}
              />
            )
          ) : (
            <Loading />
          ))}
      </div>
    </div>
  );
};
