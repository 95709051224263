import { ColumnsType } from "antd/es/table";
import React, { Dispatch, useEffect, useState } from "react";
import { RBACService } from "../../../api/rbac";
import { MODAL_TYPE, STATUS_CODE, openNotificationWithIcon } from "../../../utils";
import { Table } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { AnyAction } from "redux";
import { setRoleId } from "../../../store/rbac/slice";
import { openModal } from "../../../store";
import { Loading, NotAccessibleFallback } from "../../../components";
import "./role-mapping.scss";
interface IRoleMappingDataType {
  key: number;
  azureRole: string;
  autonomizeRole: string;
  permissions: string;
}

const getColumns = (dispatch: Dispatch<AnyAction>): ColumnsType<IRoleMappingDataType> => [
  {
    title: "Azure Group",
    dataIndex: "azureRole",
  },
  {
    title: "Autonomize Role",
    dataIndex: "autonomizeRole",
  },
  {
    title: "Permission",
    dataIndex: "permission",
    render: (value, record) => (
      <div>
        <EyeOutlined
          className="link-color"
          onClick={() => {
            dispatch(setRoleId(record.key));
            dispatch(openModal(MODAL_TYPE.PERMISSIONS));
          }}
        />
      </div>
    ),
  },
];

export const RolesMappings = () => {
  const [roleMappings, setRoleMappings] = useState([] as IGetAllRolesMappingResponse[]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [isAuthorized, setIsAuthorized] = useState(true);

  useEffect(() => {
    const getRolesMapping = async () => {
      try {
        setIsLoading(true);
        const { data } = await RBACService.getAllRolesMapping();
        setRoleMappings(data);
      } catch (err: any) {
        if (err.response.status === STATUS_CODE.FORBIDDEN) {
          setIsAuthorized(false);
        } else {
          openNotificationWithIcon("", err.response.data.message, "error");
        }
      } finally {
        setIsLoading(false);
      }
    };
    getRolesMapping();
  }, []);

  const dataSource: IRoleMappingDataType[] = roleMappings.map((roleMapping) => {
    return {
      key: roleMapping.role.id,
      azureRole: roleMapping.adGroup.name,
      autonomizeRole: roleMapping.role.name,
      permissions: "",
    };
  });

  if (!isAuthorized) {
    return <NotAccessibleFallback />;
  }
  return (
    <div>
      <Table
        className="role-mapping"
        pagination={false}
        loading={isLoading}
        columns={getColumns(dispatch)}
        dataSource={dataSource}
      />
    </div>
  );
};
