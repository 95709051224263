import { apiClient } from "./apiClient";

const getApiKeys = async (reqObj: IGetApiKeysRequest) => {
  console.log(reqObj);
  const res = await apiClient.get<IGetApiKeysResponse>("");
  return res;
};
export const APIKeysService = {
  getApiKeys,
};
