import React, { useEffect } from "react";
import { Form } from "../../../api";
import { Table } from "antd";

interface EvidenceContentFormDataProps {
  formData: Form[];
}

export const EvidenceContentFormData = ({ formData }: EvidenceContentFormDataProps) => {
  const columns = [
    {
      title: "Key",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Values",
      dataIndex: "value",
      key: "value",
    },
  ];

  return (
    <div className="extractionData">
      <Table
        columns={columns}
        dataSource={formData}
        pagination={false}
        size="small"
      />
    </div>
  );
};
