import React, { useCallback, useEffect, useMemo, useState } from "react";
import { TokenOverview } from "./TokenOverview";
import { tokenBillingService } from "../../../api/tokenBilling";
import { openNotificationWithIcon } from "../../../utils";
import { STATUS_CODE } from "../../../utils";
import { NotAccessibleFallback } from "../../../components";
import { DatePicker } from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import { RootState, setSelectedDateRange } from "../../../store";
import { RangeValue } from "rc-picker/lib/interface";
import moment from "moment";
import { Moment } from "moment";
import { Column, ColumnConfig } from "@ant-design/charts";
import { useFetchDocuments } from "../../../hooks";
import { getClass, rangePresets } from "./helper";

const { RangePicker } = DatePicker;

interface Props {
  copilotName?: string;
}

export const TokenUtilization: React.FC<Props> = ({ copilotName }: Props) => {
  const { defaultFromDate, defaultToDate } = useFetchDocuments(copilotName);
  const [loading, setLoading] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(true);
  const { fromDate: selectedFromDate, toDate: selectedToDate } = useSelector(
    (state: RootState) => state.ui.selectedDateRange
  );
  const dispatch = useDispatch();
  const [tokenData, setTokenData] = useState({
    totalTokenCount: "0",
    totalCompletionTokens: "0",
    totalPromptTokens: "0",
    tokenUsageData: [] as { type: string; value: number }[],
  });

  const getExtractionDataFilterObject = useCallback(() => {
    const obj: IGetDocumentsRequestToken = {
      fromDate: moment(selectedFromDate).startOf("day").format("YYYY-MM-DD HH:mm:ss"),
      toDate: moment(selectedToDate).endOf("day").format("YYYY-MM-DD HH:mm:ss"),
      copilot: copilotName,
    };
    return { ...obj };
  }, [selectedFromDate, selectedToDate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await tokenBillingService.getTokens(getExtractionDataFilterObject());
        const responseData = response.data;

        if (responseData && responseData.data) {
          const data = responseData.data;
          const totalTokenCounts = data.reduce(
            (acc: number, item: { total_token_count: string }) =>
              acc + parseInt(item.total_token_count, 10),
            0
          );
          const totalCompletionCounts = data.reduce(
            (acc: number, item: { total_completion_tokens: string }) =>
              acc + parseInt(item.total_completion_tokens, 10),
            0
          );
          const totalPromptCounts = data.reduce(
            (acc: number, item: { total_prompt_tokens: string }) =>
              acc + parseInt(item.total_prompt_tokens, 10),
            0
          );
          const chartData = data.map((item: { date: string; total_token_count: string }) => ({
            date: moment(item.date).format("YYYY-MM-DD"),
            totalTokenCount: parseInt(item.total_token_count, 10),
          }));

          setTokenData({
            totalTokenCount: totalTokenCounts.toString(),
            totalCompletionTokens: totalCompletionCounts.toString(),
            totalPromptTokens: totalPromptCounts.toString(),
            tokenUsageData: chartData,
          });
        }
      } catch (err: any) {
        if (err.response && err.response.status === STATUS_CODE.FORBIDDEN) {
          setIsAuthorized(false);
        } else {
          openNotificationWithIcon(
            "Error",
            err.response?.data?.message || "Unknown error",
            "error"
          );
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedFromDate, selectedToDate]);

  const onRangeChange = (dates: RangeValue<Moment>, dateStrings: string[]) => {
    if (dates) {
       dispatch(
         setSelectedDateRange({
           fromDate: dates[0]?.toString() ?? null,
           toDate: dates[1]?.toString() ?? null,
         })
       );
    } else {
      dispatch(
        setSelectedDateRange({
          fromDate: defaultFromDate?.toString() ?? null,
          toDate: defaultToDate?.toString() ?? null,
        })
      );
    }
  };

  const tokenUsageConfig: ColumnConfig = useMemo(() => {
    return {
      data: tokenData.tokenUsageData,
      isStack: true,
      xField: "date",
      yField: "totalTokenCount",
      seriesField: "date",
      label: {
        fields: ["totalTokenCount"],
        callback: (Count: number) => ({
          content: `${Count}`, // Adjust label content as needed
          layout: [{ type: "interval-adjust-position" }, { type: "element-active" }],
        }),
      },
      columnStyle: {
        radius: 0,
      },
      color: ["#F43F5F"],
    };
  }, [tokenData.tokenUsageData]);

  if (!isAuthorized) {
    return <NotAccessibleFallback />;
  }

  return (
    <div className="billing-dashboard-container">
      <div className="ant-card">
        <TokenOverview
          copilotName={copilotName || "Unknown"}
          totalTokens={tokenData.totalTokenCount}
          completionTokens={tokenData.totalCompletionTokens}
          promptTokens={tokenData.totalPromptTokens}
        />
        <h4>{`Usage (tokens)`}</h4>
        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "2em" }}>
          <p>{`${tokenData.totalTokenCount} tokens consumed by ${copilotName}`}</p>
          <RangePicker
            allowClear={false}
            defaultValue={[
              selectedFromDate ? moment(selectedFromDate) : null,
              selectedToDate ? moment(selectedToDate) : null,
            ]}
            presets={rangePresets}
            onChange={onRangeChange}
            className="range-picker"
            disabledDate={(current) => {
              return current && current.valueOf() > moment().endOf("day").valueOf();
            }}
          />
        </div>
        <Column {...tokenUsageConfig} />
      </div>
    </div>
  );
};

export default TokenUtilization;
