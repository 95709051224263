import { Table } from 'antd';
import React, { useEffect, useState } from 'react'
import moment from 'moment';
import qaFeedback from './qa_feedback.json';
import { DatePicker } from "../../../components";

interface CleanedQaFeedbackObject {
  id: number;
  qaHistoryId: number;
  feedbackType: string;
  feedback: string;
  createdAt: string;
  createdBy: number;
  qa_id: string;
}

const cleanedQAFeedback: CleanedQaFeedbackObject[] = qaFeedback.map((item) => {
  const cleanedItem: CleanedQaFeedbackObject = {
    id: item.id,
    qaHistoryId: item.qaHistoryId,
    feedbackType: item.feedbackType == 'true' ? 'True' : 'False',
    feedback: item.feedback,
    createdAt: item.createdAt,
    createdBy: item.createdBy,
    qa_id: item.qa_id,
  };
  return cleanedItem;
});

const getColumns = () => [
  {
    title: "Feedback Type",
    key: "feedbackType",
    dataIndex: "feedbackType",
  },
  {
    title: "Feedback",
    key: "feedback",
    dataIndex: "feedback",
  },
  {
    title: "Created Date",
    key: "createdAt",
    dataIndex: "createdAt",
  }
];

const QAFeedback = () => {
  const defaultFromDate = '2000-01-01';
  const defaultToDate = moment();
  const [fromDate, setFromDate] = useState(moment(defaultFromDate, 'YYYY-MM-DD'));
  const [toDate, setToDate] = useState(defaultToDate);
  const [filteredReportData, setFilteredReportData] = useState(cleanedQAFeedback);
  useEffect(() => {
    const updatedReportData = cleanedQAFeedback.filter((data) => {
      return moment(data['createdAt'], 'YYYY-MM-DD').isBetween(fromDate, toDate, 'day', '[]')
    }
    )
    setFilteredReportData(updatedReportData);
  }, [fromDate, toDate])
  return (
    <div>
      <div style={{ display: 'flex', marginBottom: '2em' }}>
        <p style={{marginBottom: 'auto', marginTop: 'auto'}}>From: </p>
        <DatePicker defaultValue={fromDate} onChange={(value) => value ? setFromDate(value): moment(defaultFromDate, 'YYYY-MM-DD')} style={{marginBottom: 'auto', marginTop: 'auto', marginLeft: '1em', marginRight: '1em'}}></DatePicker>
        <p style={{marginBottom: 'auto', marginTop: 'auto', marginLeft: '2em'}}>To: </p>
        <DatePicker defaultValue={toDate} onChange={(value) => value ? setToDate(value): defaultToDate} style={{marginBottom: 'auto', marginTop: 'auto', marginLeft: '1em', marginRight: '1em'}}></DatePicker>
      </div>
      <Table
        className="table"
        columns={getColumns()}
        rowClassName={"cursor-pointer"}
        dataSource={filteredReportData}
        pagination={false}
      />
    </div>
  );
}

export default QAFeedback