import moment from "moment";
import { COPILOTS_NAME } from "../utils";
import { apiClient } from "./apiClient";
import qs from "qs";

const getData = async (copilotName?: string) => {
  const res = await apiClient.get<ITokenUsageResponse>(
    `/token-billing?fromDate=${moment()
      .startOf("month")
      .format("YYYY-MM-DD")}&toDate=${moment()}&copilot=${
      copilotName === COPILOTS_NAME.HEDIS_INSIGHTS
        ? "hedis"
        : copilotName === COPILOTS_NAME.PEGA_PRIOR_AUTH
        ? "pega"
        : copilotName === COPILOTS_NAME.CAREGPT_CHATBOT
        ? "mosaic"
        : "all"
    }`
  );
  return res;
};

const getTokens = async (reqObj: IGetDocumentsRequestToken) => {
  const { fromDate,toDate} = reqObj;
  let copilot = "";
  if (reqObj.copilot === COPILOTS_NAME.CCA) {
    copilot = "CCA";
  } else if (reqObj.copilot === COPILOTS_NAME.ANG_INSIGHTS) {
    copilot = "ANG";
  }

  const query = qs.stringify(
    {
      fromDate,
      toDate,
      copilot,
    },
    { addQueryPrefix: true, skipNulls: true }
  );

  const url = `/requests/token${query}`;
  const res = await apiClient.get(url);
  return res;
};
export const tokenBillingService = { getData,getTokens };
