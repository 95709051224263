import React from "react";
import { PageWrapper } from "../containers";
import CopilotDetailsContainer from "../containers/copilot-details/copilot-details-container";
import { useLocation, useParams } from "react-router-dom";
import { Copilots, IApplication } from "../utils";

export interface MyData {
  key: string;
  application?: IApplication;
}

const CopilotDetails = () => {
  const location = useLocation();
  const { copilotId } = useParams<{ copilotId: string }>();
  const id = copilotId ? parseInt(copilotId, 10) : undefined;
  const copilotInfo = { key: `${id}`, application: Copilots.find((item) => item.id === id) };

  return (
    <PageWrapper pageContainerChildren={<CopilotDetailsContainer copilotInfo={copilotInfo} />} />
  );
};

export default CopilotDetails;
