import { useCallback, useState } from "react";
import { openNotificationWithIcon } from "../utils";
import { APIKeysService } from "../api";
import { usePagination } from "./usePagination";
import { SorterResult } from "antd/es/table/interface";

interface ColumnSort extends SorterResult<any> {
  sortOrder: "asc" | "desc" | null;
}

export const useFetchApiKeys = () => {
  const { page, onPaginationChange, pageSize, pageSizeOptions } = usePagination();
  const [sort, setSort] = useState({} as ColumnSort);
  const [isLoading, setIsLoading] = useState(false);
  const [apiKeys, setApiKeys] = useState([] as IAPIKey[]);
  const [total, setTotal] = useState(0);

  const onSortChange = (newSort: any) => {
    if (newSort.order === "ascend") setSort({ ...newSort, sortOrder: "asc" });
    else if (newSort.order === "descend") setSort({ ...newSort, sortOrder: "desc" });
    else setSort({ ...newSort, sortOrder: null });
  };

  const getApiKeysFilterObject = useCallback(() => {
    let obj: IGetApiKeysRequest = {
      limit: pageSize,
      offset: page - 1,
    };
    if (sort.sortOrder && sort.field) {
      obj = { ...obj, sortField: sort.field.toString(), sortType: sort.sortOrder };
    }
    return { ...obj };
  }, [page, pageSize, sort.field, sort.sortOrder]);

  const fetchApiKeys = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await APIKeysService.getApiKeys(getApiKeysFilterObject());
      setApiKeys(data.data);
      setTotal(data.pagination.totalCount ? data.pagination.totalCount : 0);
    } catch (err: any) {
      openNotificationWithIcon("", err.response.data.message, "error");
    } finally {
      setIsLoading(false);
    }
  }, [getApiKeysFilterObject]);

  return {
    paginationObj: { onPaginationChange, pageSizeOptions, page, pageSize },
    sortFilter: { sort, setSort: onSortChange },
    getApiKeysFilterObject,
    fetchApiKeys,
    apiKeysLoading: isLoading,
    apiKeys,
    totalApiKeys: total,
    setApiKeysLoading: setIsLoading,
    setTotalApiKeys: setTotal,
    setApiKeys,
  };
};
