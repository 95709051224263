// Keyword.tsx

import React, { useEffect, useState } from "react";
import { useFetchDocuments } from "../../../../hooks";
import { Loading } from "../../../../components";
import { documentService } from "../../../../api/document";
import "./keywords.scss"; // Import your CSS file for styling
import { Button, Collapse, Input, Modal } from "antd";
import DeleteConfirmation from "../../../modals/delete-confirmation/DeleteConfirmation";

const Keywords = () => {
  const { fetchKeywords, field } = useFetchDocuments();
  const [keywords, setKeywords] = useState(field);
  const fields = field.map((item) => item.Key);
  const [selectedField, setSelectedField] = useState<string>("");
  const [loadingFields, setLoadingFields] = useState<boolean>(false);
  const [loadingVariations, setLoadingVariations] = useState<boolean>(false);
  const [keywordVariations, setKeywordVariations] = useState<string[]>([]);

  const handleFieldClick = async (keys: string) => {
    setSelectedField(keys);
    setLoadingVariations(true);

    try {
      const { data } = await documentService.getKeywordVariations(keys);
      setKeywordVariations(data.data[keys]);
    } catch (error) {
      console.error("Error fetching keyword variations:", error);
      setKeywordVariations([]);
    } finally {
      setLoadingVariations(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoadingFields(true);
      try {
        // Fetch initial keywords
        await fetchKeywords(); // <-- Separate function call
        setKeywords(keywords);
      } catch (error) {
        console.error("Error fetching keywords:", error);
        setKeywords([]);
      } finally {
        setLoadingFields(false);
      }
    };

    fetchData();
  }, [fetchKeywords]);

  const [editMode, setEditMode] = useState<boolean>(false);
  const [editedKeyword, setEditedKeyword] = useState<string>("");
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState<boolean>(false);
  const [variationToDelete, setVariationToDelete] = useState<string>("");
  const [editedKeywordIndex, setEditedKeywordIndex] = useState(-1);

  const handleDelete = (variation: string) => {
    setVariationToDelete(variation);
    setDeleteConfirmationVisible(true);
  };

  const handleDeleteConfirm = () => {
    setDeleteConfirmationVisible(false);

    const updatedVariations = keywordVariations.filter(
      (variation) => variation !== variationToDelete
    );
    setKeywordVariations(updatedVariations);

    const reqObj = {
      data: JSON.stringify({ [selectedField as string]: updatedVariations }),
    };
    console.log(JSON.stringify(reqObj));

    documentService.saveKeywordVariation(reqObj);

    setVariationToDelete("");
  };

  const handleDeleteCancel = () => {
    setDeleteConfirmationVisible(false);
    setVariationToDelete("");
  };

  const handleEdit = (variation: string, index: number) => {
    setEditMode(true);
    setEditedKeyword(variation);
    setEditedKeywordIndex(index);
  };

  const handleCancelEdit = () => {
    setEditMode(false);
    setEditedKeyword("");
    setEditedKeywordIndex(-1);
  };

  const handleSave = async (index: number) => {
    let updatedVariations;

    if (editMode) {
      updatedVariations = [...keywordVariations];

      if (index !== -1) {
        updatedVariations[index] = editedKeyword;
        setKeywordVariations(updatedVariations);
      }
    } else {
      updatedVariations = [editedKeyword, ...keywordVariations];
      setKeywordVariations(updatedVariations);
    }

    const reqObj = {
      data: JSON.stringify({ [selectedField as string]: updatedVariations }),
    };
    console.log(JSON.stringify(reqObj));
    await documentService.saveKeywordVariation(reqObj);

    setEditMode(false);
    setEditedKeyword("");
    setEditedKeywordIndex(-1);
  };

  const items = [
    {
      key: "1",
      label: "Keywords",
      children: (
        <div style={{ display: "flex" }}>
          <div className="fields-container">
            <h3>Fields</h3>
            {loadingFields ? (
              <Loading style={{ width: "100%" }} />
            ) : (
              <ul>
                {fields.map((item) => (
                  <li
                    key={item}
                    onClick={() => handleFieldClick(item)}
                    className={`field-button ${selectedField === item ? "selected" : ""}`}
                  >
                    {item}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className="variations-container">
            <h3>Keyword Variations</h3>
            {loadingVariations ? (
              <Loading style={{ width: "100%" }} />
            ) : selectedField || keywordVariations.length > 0 ? (
              Array.isArray(keywordVariations) && keywordVariations.length > 0 ? (
                <ul>
                  {keywordVariations.map((variation, index) => (
                    <li key={variation} className="keyword-variation">
                      <span>{variation}</span>
                      <div className="actions">
                        <span className="edit-icon" onClick={() => handleEdit(variation, index)}>
                          ✎
                        </span>
                        <span className="delete-icon" onClick={() => handleDelete(variation)}>
                          🗑
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>Select a field to view keyword variations.</p>
              )
            ) : (
              <p>Select a field to view keyword variations.</p>
            )}
          </div>
          <div className="third-division-container">
            <h3>{editMode ? "Edit Keyword" : "Add Keyword"}</h3>
            <Input
              style={{ marginBottom: "10px", float: "right" }}
              value={editedKeyword}
              onChange={(e) => setEditedKeyword(e.target.value)}
            />
            <Button className="outline" style={{ marginTop: "10px" }} onClick={() => handleSave(editedKeywordIndex)}>
              {editMode ? "SAVE" : "ADD"}
            </Button>
            {editMode && (
              <Button
                className="outline"
                style={{ marginTop: "10px", marginLeft: "10px" }}
                onClick={handleCancelEdit}
              >
                CANCEL
              </Button>
            )}
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="page-container">
      <Collapse items={items} style={{ display: "flex" }} />
      <DeleteConfirmation
        visibility={deleteConfirmationVisible}
        handleClose={handleDeleteCancel}
        handleDelete={handleDeleteConfirm}
      />
    </div>
  );
};

export default Keywords;
