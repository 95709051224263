import React, { useEffect, useState } from "react";
import { PageWrapper, ServiceStatusContainer } from "../containers";
import { ServiceStatusService } from "../api";
import { openNotificationWithIcon } from "../utils";
import { CopilotsDashboardContainer } from "../containers/copilots-dashboard";

export const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [services, setServices] = useState([] as IService[]);

  return <PageWrapper loading={isLoading} pageContainerChildren={<CopilotsDashboardContainer />} />;
};

export default Dashboard;
