import { useSelector } from "react-redux";
import { RootState } from "../store";

export const useAuthUser = () => {
  const { user } = useSelector((state: RootState) => state.authUser);
  const { accessToken, firstName, lastName, email } = user;

  return {
    firstName,
    lastName,
    accessToken,
    roleCode: user.role ? user.role.code : "",
    roleName: user.role ? user.role.name : "",
    email,
  };
};
