import moment, { Moment } from "moment";
import { PresetDate, EventValue, RangeValue } from "rc-picker/lib/interface";
import { DOCUMENT_STATUS } from "../../../utils";

export const getLastWeekDates = () => {
  const startDateOfCurrentWeek = moment().startOf("week");
  const endDateOfLastWeek = startDateOfCurrentWeek.clone().subtract(1, "day");
  const startDateOfLastWeek = endDateOfLastWeek.clone().subtract(6, "days");
  return [startDateOfLastWeek, endDateOfLastWeek] as [EventValue<Moment>, EventValue<Moment>];
};

export const getLastMonthDates = () => {
  const startDateOfCurrentMonth = moment().startOf("month");
  const endDateOfLastMonth = startDateOfCurrentMonth.clone().subtract(1, "day");
  const startDateOfLastMonth = endDateOfLastMonth.clone().startOf("month");
  return [startDateOfLastMonth, endDateOfLastMonth] as [EventValue<Moment>, EventValue<Moment>];
};

export const rangePresets: PresetDate<[EventValue<Moment>, EventValue<Moment>]>[] = [
  {
    label: "Today",
    value: [moment().startOf("day"), moment().endOf("day")],
  },
  {
    label: "Last 7 Days",
    value: [moment().add(-7, "d"), moment()],
  },
  {
    label: "Last Week",
    value: getLastWeekDates(),
  },
  {
    label: "Last 30 Days",
    value: [moment().add(-30, "d"), moment()],
  },
  {
    label: "Last Month",
    value: getLastMonthDates(),
  },
];

export const getClass = (value: string) => {
  switch (value) {
    case DOCUMENT_STATUS.COMPLETED:
      return "#44c658";
    case DOCUMENT_STATUS.SUCCEEDED:
      return "#44c658";
    case DOCUMENT_STATUS.ERROR:
      return "#f44336";
    case DOCUMENT_STATUS.ON_HOLD:
      return "#FFD300";
    default:
      return "#475569";
  }
};
