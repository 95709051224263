import React, { useEffect, useState } from "react";
import { EvidencePageResponseInfo } from "../../../api";
import {
  EvidenceViewerDefault,
  EvidenceViewerOCR,
  EvidenceContentMedicalEntities,
  EvidenceContentNormalEntities,
  EvidenceContentJSON,
  ExtractionOutput,
} from ".";
import { ENHANCED_VIEW_TABS } from "../EvidenceViewerContainer";
import { Loading } from "../../../components";
import "./evidence-content.scss";
import { isEmptyObject } from "../../../utils";
import { EvidenceContentJSONOutput } from "./EvidenceContentJSONOutput";

interface Props {
  pageInfo: EvidencePageResponseInfo;
  loading: boolean;
  selectedTab: string;
  searchKeywords: string;
  selectedLegends: string[];
  fileId: string;
  currPage: number;
}

export const EvidenceContent = ({
  pageInfo,
  loading,
  selectedTab,
  searchKeywords,
  selectedLegends,
  fileId,
  currPage,
}: Props) => {
  const [component, setComponent] = useState(<></>);

  useEffect(() => {
    if (!isEmptyObject(pageInfo)) {
      let res = <></>;
      switch (selectedTab) {
        case ENHANCED_VIEW_TABS.PAGE:
          res = <EvidenceViewerDefault {...{ pageInfo, searchKeywords, selectedLegends, loading }} />;
          break;
        case ENHANCED_VIEW_TABS.OCR:
          // res = <EvidenceViewerOCR pageInfo={pageInfo} />;
          res = <ExtractionOutput {...{ pageInfo, searchKeywords, selectedLegends, loading }} />;
          break;
        case ENHANCED_VIEW_TABS.MEDICAL_ENTITIES:
          res = <EvidenceContentMedicalEntities pageInfo={pageInfo} />;
          break;
        case ENHANCED_VIEW_TABS.NORMAL_ENTITIES:
          res = <EvidenceContentNormalEntities pageInfo={pageInfo} />;
          break;
        case ENHANCED_VIEW_TABS.JSON:
          res = <EvidenceContentJSONOutput {...{ pageInfo, fileId, currPage, loading }} />;
          break;
      }
      setComponent(res);
    }
  }, [pageInfo, searchKeywords, selectedLegends, selectedTab, loading]);

  return (
    <>
      <div className="e-content">{component}</div>
    </>
  );
};
