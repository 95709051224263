import React, { useEffect, useMemo, useRef, useState } from "react";
import { useFetchDocuments } from "../../../hooks";
import Table, { ColumnsType, TableProps } from "antd/es/table";
import {
  COPILOTS_NAME,
  DOCUMENT_STATUS,
  PAGE_URL,
  getLocalDateTimeString,
  getLocalDateTimeStringFromISO,
  openNotificationWithIcon,
} from "../../../utils";
import { AppPagination, Loading, NotAccessibleFallback } from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import "./copilots-details.scss";
import { useNavigate } from "react-router";
import { Moment } from "moment";
import { DatePicker } from "../../../components";
import { RangeValue } from "rc-picker/lib/interface";
import Search from "antd/lib/input/Search";
import { Button, Checkbox, InputRef, Select, Empty } from "antd";
import {
  CaretDownFilled,
  CaretUpFilled,
  MinusOutlined,
  PlusOutlined,
  RedoOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { getClass, rangePresets } from "./helper";
import ExpandedRowContent from "./expanded-row-content/ExpandedRowContent";
import { SorterResult } from "antd/es/table/interface";
import { StopProcessIcon } from "../../../assets/icons";
import { documentService } from "../../../api/document";
import { useParams } from "react-router-dom";
import { RootState, setSelectedDateRange } from "../../../store";
import moment from "moment";
import HoldFilter from "./hold-filter/HoldFilter";

const { Option } = Select;

const disableEvidenceViewer = process.env.REACT_APP_HIDE_EVIDENCE_VIEWER === "true";

const { RangePicker } = DatePicker;

interface IDocumentsDataType extends IDocument {
  key: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  status: string;
  action: string;
  pageStatus: string;
  documentId: number;
}

interface Props {
  copilotName?: string;
}

const Documents = ({ copilotName }: Props) => {
  const {
    documents,
    fetchDocuments,
    getDocumentsFilterObject,
    isDocumentsLoading,
    setIsDocumentsLoading,
    paginationObj,
    setDocuments,
    setTotalDocuments,
    sortFilter,
    totalDocuments,
    isAuthorized,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    defaultFromDate,
    defaultToDate,
    searchTerm,
    searchDocumentUUID,
    setSearchTerm,
    setSearchDocumentUUID,
    setSortBy,
    setStatus,
  } = useFetchDocuments(copilotName);
  const { onPaginationChange, page, pageSize, pageSizeOptions } = paginationObj;
  const { setSort, sort } = sortFilter;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [searchUuid, setSearchUuid] = useState("");
  const [expandedRowKey, setExpandedRowKey] = useState<string | null>(null);
  const [isSearchInputFocused, setIsSearchInputFocused] = useState(false);
  const searchInputRef = useRef(null as any);
  const [sorterState, setSorterState] = useState() as any;
  const [selectedRowKeys, setSelectedRowKeys] = useState<
    { key: string; status: string; documentId: number }[]
  >([]);
  const { copilotId } = useParams();
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);

  const { fromDate: selectedFromDate, toDate: selectedToDate } = useSelector(
    (state: RootState) => state.ui.selectedDateRange
  );

  const onTableChange: TableProps<IDocumentsDataType>["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {
    if (searchInputRef.current && document.activeElement === searchInputRef.current.input) {
      return;
    }
    setSorterState(sorter);
    const firstSorter = sorter as SorterResult<IDocumentsDataType>;
    switch (firstSorter.order) {
      case "descend":
        setSortBy(`-${firstSorter.field}`);
        break;
      case "ascend":
        setSortBy(firstSorter.field as string);
        break;
      default:
        setSortBy("");
        break;
    }
  };

  const handleExpand = (expanded: boolean, record: IDocumentsDataType) => {
    if (expanded) {
      setExpandedRowKey(record.key);
    } else {
      setExpandedRowKey(null);
    }
  };

  const handleSearch = (event: any, type?: string) => {
    if (type === "uuid") {
      setSearchDocumentUUID(searchUuid);
    } else {
      setSearchTerm(searchText);
    }
    onPaginationChange(1, pageSize);
  };

  const handleChange = (event: any, type?: string) => {
    if (type === "uuid") {
      setSearchUuid(event.target.value);
      if (event.target.value == "") {
        setSearchDocumentUUID("");
        onPaginationChange(1, pageSize);
      }
    } else {
      setSearchText(event.target.value);
      if (event.target.value == "") {
        setSearchTerm("");
        onPaginationChange(1, pageSize);
      }
    }
  };

  const handleSort = (column: string) => {
    if (!sorterState) {
      setSortBy(`${column}`);
    } else if (sorterState.order === "ascend") {
      setSortBy(`-${column}`);
    } else if (sorterState.order === "descend") {
      setSortBy(``);
    }
  };

  const getSortIcon = (column: string) => {
    let caretUpFilledStyle = { color: "#0000004a" };
    let caretDownFilledStyle = { marginTop: "-0.3em", color: "#0000004a" };
    if (sorterState && sorterState.field === column) {
      if (sorterState.order === "ascend") {
        caretUpFilledStyle = { color: "#000000" };
        caretDownFilledStyle = { marginTop: "-0.3em", color: "#0000004a" };
      } else if (sorterState.order === "descend") {
        caretUpFilledStyle = { color: "#0000004a" };
        caretDownFilledStyle = { marginTop: "-0.3em", color: "#000000" };
      }
    }
    return (
      <>
        <CaretUpFilled style={caretUpFilledStyle} />
        <CaretDownFilled style={caretDownFilledStyle} />
      </>
    );
  };

  const handleStopProcessing = async (documentId: number) => {
    await documentService.holdProcessing([documentId]);
    fetchDocuments();
  };

  const handleRestartProcessing = async (documentUUID: string) => {
    await documentService.restartProcessing(documentUUID, copilotName);
    fetchDocuments();
  };

  const getRestartStopButton = (value: any) => {
    switch (value.status) {
      case DOCUMENT_STATUS.SUCCEEDED:
      case DOCUMENT_STATUS.COMPLETED:
        return null;
      case DOCUMENT_STATUS.ERROR:
      case DOCUMENT_STATUS.ON_HOLD:
      case DOCUMENT_STATUS.COMPLETED_WITH_ERROR:
      case "Failed":
        return (
          <Button
            onClick={() => handleRestartProcessing(value.DocumentUUID)}
            style={{ padding: "2px 10px", border: "none", width: "44px" }}
          >
            <RedoOutlined width={"24px"} height={"24px"} />
          </Button>
        );
      default:
        return (
          <Button
            onClick={() => handleStopProcessing(value.Id)}
            style={{ padding: "2px 10px", border: "none", width: "44px" }}
          >
            <StopProcessIcon width={"24px"} height={"24px"} />
          </Button>
        );
    }
  };

  const dataSource: IDocumentsDataType[] = useMemo(
    () =>
      documents.map((document) => ({
        key: document.DocumentUUID,
        name: document.FileName.split("/").splice(-1)[0],
        ...document,
        createdAt: getLocalDateTimeString(document.createdAt),
        updatedAt: getLocalDateTimeString(document.updatedAt),
        status: document.DocumentProcessStatuses
          ? document.DocumentProcessStatuses.length !== 0 && !document.holdStatus
            ? document.DocumentProcessStatuses[document.DocumentProcessStatuses.length - 1].Status
            : document.holdStatus
            ? "ON HOLD"
            : ""
          : document.Status,
        pageStatus: "",
        action: "",
        documentId: document.Id,
      })),
    [documents]
  );

  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      const newSelectedRows = dataSource
        .map((item) => ({ key: item.key, status: item.status, documentId: item.Id }))
        .filter((item) => !selectedRowKeys.some((selected) => selected.key === item.key));

      setSelectedRowKeys([...selectedRowKeys, ...newSelectedRows]);
      setIsSelectAllChecked(true);
    } else {
      setSelectedRowKeys(
        selectedRowKeys.filter((item) => !dataSource.some((d) => d.key === item.key))
      );
      setIsSelectAllChecked(false);
    }
  };

  const handleSelectRow = (checked: boolean, key: string, status: string, documentId: number) => {
    if (checked) {
      setSelectedRowKeys([...selectedRowKeys, { key, status, documentId }]);
    } else {
      setSelectedRowKeys(selectedRowKeys.filter((item) => item.key !== key));
    }
  };
  const handleDeleteSelected = async () => {
    try {
      await documentService.deleteDocuments(selectedRowKeys, copilotName);
      setSelectedRowKeys([]);
      fetchDocuments();
    } catch (error: any) {
      if (error.response && error.response.status === 403) {
        openNotificationWithIcon("", "You cannot purge items in production", "error");
      } else {
        openNotificationWithIcon("", "An error occurred while deleting documents", "error");
      }
    }
  };

  const getColumns = (): ColumnsType<IDocumentsDataType> => {
    const columns = [
      {
        title: (
          <Checkbox
            onChange={(e) => handleSelectAll(e.target.checked)}
            indeterminate={selectedRowKeys.length > 0 && selectedRowKeys.length < dataSource.length}
            checked={isSelectAllChecked}
          />
        ),
        dataIndex: "checkbox",
        render: (_: any, record: IDocumentsDataType) => (
          <Checkbox
            onChange={(e) =>
              handleSelectRow(e.target.checked, record.key, record.status, record.documentId)
            }
            checked={selectedRowKeys.some((item) => item.key === record.key)}
          />
        ),
        className: "document-container-table-checkbox",
      },
      {
        title: (
          <div>
            <div
              onClick={() => handleSort("FileName")}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              Name
              <div style={{ display: "flex", flexDirection: "column" }}>
                {getSortIcon("FileName")}
              </div>
            </div>
            <Search
              ref={(input) => (searchInputRef.current = input)}
              className="record-search document-container-table-search"
              placeholder="Search Name"
              enterButton={
                isDocumentsLoading ? (
                  <Button disabled></Button>
                ) : (
                  <Button icon={<SearchOutlined />} />
                )
              }
              value={searchText}
              onChange={(e) => handleChange(e)}
              onSearch={(e) => handleSearch(e)}
              disabled={isDocumentsLoading}
            />
          </div>
        ),
        dataIndex: "FileName",
        render: (item: any, record: any) => (
          <p
            className="cc flex cursor-pointer"
            onClick={() => {
              if (disableEvidenceViewer) return;
              record.status === DOCUMENT_STATUS.SUCCEEDED || DOCUMENT_STATUS.COMPLETED
                ? navigate(
                    `${PAGE_URL.COPILOTS_DETAILS}/${copilotId}${
                      PAGE_URL.EVIDENCE_VIEWER
                    }?fileId=${record.DocumentUUID.toLowerCase()}`,
                    {
                      state: { data: copilotName },
                    }
                  )
                : openNotificationWithIcon(
                    "",
                    "The File is still processing or failed. Please try with completed files",
                    "info"
                  );
            }}
          >
            {item.split("/").splice(-1)[0]}
          </p>
        ),
        sorter: true,
        className: "document-container-table-name",
        showSorterTooltip: false,
      },
      {
        title: (
          <div>
            <div
              onClick={() => handleSort("DocumentUUID")}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              Document UUID
              <div style={{ display: "flex", flexDirection: "column" }}>
                {getSortIcon("DocumentUUID")}
              </div>
            </div>
            <Search
              ref={(input) => (searchInputRef.current = input)}
              className="record-search document-container-table-search"
              placeholder="Search UUID"
              enterButton={
                isDocumentsLoading ? (
                  <Button disabled></Button>
                ) : (
                  <Button icon={<SearchOutlined />} />
                )
              }
              value={searchUuid}
              onChange={(e) => handleChange(e, "uuid")}
              onSearch={(e) => handleSearch(e, "uuid")}
              disabled={isDocumentsLoading}
            />
          </div>
        ),
        key: "DocumentUUID",
        dataIndex: "DocumentUUID",
        className: "document-container-table-DocumentUUID",
        sorter: true,
        showSorterTooltip: false,
      },
      {
        title: "Created At",
        dataIndex: "createdAt",
        className: "document-container-table-createdAt",
        sorter: true,
      },
      {
        title: "Updated At",
        dataIndex: "updatedAt",
        className: "document-container-table-updatedAt",
        sorter: true,
      },
      {
        title: "Time Taken(Sec)",
        dataIndex: "executionTime",
        className: "document-container-table-timeTaken",
        sorter: true,
      },
      {
        title: (
          <div style={{ display: "flex", flexDirection: "column" }}>
            Status
            <Select
              defaultValue=""
              style={{ width: 170 }}
              onChange={(value) => {
                setStatus(value);
                onPaginationChange(1, pageSize);
              }}
            >
              <Option value="">ALL</Option>
              <Option value="SUCCEEDED">SUCCEEDED</Option>
              <Option value="ERROR">FAILED</Option>
              <Option value="ON_HOLD">ON HOLD</Option>
              {copilotName === COPILOTS_NAME.PEGA_PRIOR_AUTH && (
                <Option value="PROCESSING">PROCESSING</Option>
              )}
            </Select>
          </div>
        ),
        dataIndex: "status",
        render: (text: any, value: any) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              justifyContent: "space-between",
            }}
          >
            <div className="status" style={{ color: getClass(value.status) }}>
              <p style={{ color: getClass(value.status) }}>{value.status}</p>
            </div>
            {getRestartStopButton(value)}
          </div>
        ),
        align: "left",
        className: "document-container-table-status",
      },
      {
        title: "Total Pages",
        dataIndex: "TotalPages",
        className: "document-container-table-totalpages",
        sorter: true,
      },
    ];
    // columns.forEach((column) => {
    //   if (column.sorter) {
    //     column.sortDirections = [];
    //   }
    // });
    return columns.filter((column) => column !== null) as ColumnsType<IDocumentsDataType>;
  };

  useEffect(() => {
    // setSelectedRowKeys([]);
    setIsSelectAllChecked(false);
  }, [page, pageSize]);

  useEffect(() => {
    fetchDocuments();
  }, [fetchDocuments, selectedFromDate, selectedToDate, searchTerm, sort]);

  const onRangeChange = (dates: RangeValue<Moment>, dateStrings: string[]) => {
    if (dates) {
      dispatch(
        setSelectedDateRange({
          fromDate: dates[0]?.toString() ?? null,
          toDate: dates[1]?.toString() ?? null,
        })
      );
    } else {
      dispatch(
        setSelectedDateRange({
          fromDate: defaultFromDate?.toString() ?? null,
          toDate: defaultToDate?.toString() ?? null,
        })
      );
    }
  };

  if (!isAuthorized) {
    return <NotAccessibleFallback />;
  }

  const expandedRowRender = (record: IDocumentsDataType) => {
    return (
      <ExpandedRowContent
        record={record}
        expandedRowKey={expandedRowKey}
        copilotName={copilotName}
      />
    );
  };

  const CustomExpandIcon = ({ expanded, onExpand, record }: any) => {
    const iconStyle = { fontSize: "20px", cursor: "pointer" };
    if (expanded) {
      return (
        <Button style={{ padding: "5px" }} onClick={(e) => onExpand(record, e)}>
          <MinusOutlined style={iconStyle} />
        </Button>
      );
    }
    return (
      <Button style={{ padding: "5px" }} onClick={(e) => onExpand(record, e)}>
        <PlusOutlined style={iconStyle} />
      </Button>
    );
  };

  const handleHoldSelected = async () => {
    const invalidStatuses = [
      DOCUMENT_STATUS.SUCCEEDED,
      DOCUMENT_STATUS.ON_HOLD,
      DOCUMENT_STATUS.ERROR,
      DOCUMENT_STATUS.COMPLETED,
    ];
    const isValid = selectedRowKeys.every((item) => {
      const status = item.status as DOCUMENT_STATUS;
      return !invalidStatuses.includes(status);
    });
    if (!isValid) {
      openNotificationWithIcon(
        "",
        "Only documents with statuses other than 'SUCCEEDED', 'ON HOLD','COMPLETED' and 'ERROR' can be put on hold.",
        "info"
      );
      return;
    }

    const documentIDs = selectedRowKeys.map((item) => item.documentId);
    try {
      await documentService.holdProcessing(documentIDs);
      setSelectedRowKeys([]);
      fetchDocuments();
      console.log("Documents put on hold successfully.");
    } catch (error) {
      console.error("Failed to put documents on hold:", error);
    }
  };

  return (
    <div>
      <div style={{ display: "flex", marginBottom: "2em" }} className="tabHeader">
        <RangePicker
          allowClear={false}
          defaultValue={[
            selectedFromDate ? moment(selectedFromDate) : null,
            selectedToDate ? moment(selectedToDate) : null,
          ]}
          presets={rangePresets}
          onChange={onRangeChange}
          className="range-picker"
          disabledDate={(current) => {
            return current && current.valueOf() > moment().endOf("day").valueOf();
          }}
          disabled={!!(searchTerm || searchDocumentUUID)}
        />
        <div className="tabHeader-delete">
          {selectedRowKeys.length ? (
            <p className="tabHeader-selected">{`${selectedRowKeys.length} ${
              selectedRowKeys.length === 1 ? "item" : "items"
            } selected`}</p>
          ) : (
            <></>
          )}
          <HoldFilter />
          <Button
            className="outline"
            onClick={handleHoldSelected}
            disabled={selectedRowKeys.length === 0}
          >
            Hold Selected Item
          </Button>
          <Button
            className="outline"
            onClick={handleDeleteSelected}
            disabled={selectedRowKeys.length === 0}
          >
            Purge Selected Items
          </Button>
          <AppPagination
            className="tr m-b paginationDiv"
            {...{
              showSizeChanger: true,
              current: page,
              total: totalDocuments,
              pageSize,
              pageSizeOptions,
              onChange: onPaginationChange,
            }}
          />
        </div>
      </div>
      <Table
        className="document-container-table"
        columns={getColumns()}
        dataSource={dataSource}
        onChange={onTableChange}
        loading={isDocumentsLoading}
        pagination={false}
        expandable={{
          expandedRowRender,
          expandIconColumnIndex: 8,
          columnTitle: "Detailed Logs",
          onExpand: handleExpand,
          expandIcon: CustomExpandIcon,
        }}
        locale={{ emptyText: <Empty>No Documents Found</Empty> }}
      />
    </div>
  );
};

export default Documents;
