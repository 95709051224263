import React from "react";
import { IconsProps } from "./Icons";

export const HomeIcon = ({ className, fill, width, height, viewBox }: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : "0 0 15 14"}
      width={width ? width : "15"}
      height={height ? height : "14"}
      fill={fill ? fill : "none"}
    >
      <path
        d="M5.33339 1.125H2.00004C1.63185 1.125 1.33337 1.42348 1.33337 1.79167V5.12501C1.33337 5.4932 1.63185 5.79168 2.00004 5.79168H5.33339C5.70158 5.79168 6.00006 5.4932 6.00006 5.12501V1.79167C6.00006 1.42348 5.70158 1.125 5.33339 1.125Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.33339 8.45836H2.00004C1.63185 8.45836 1.33337 8.75684 1.33337 9.12503V12.4584C1.33337 12.8266 1.63185 13.125 2.00004 13.125H5.33339C5.70158 13.125 6.00006 12.8266 6.00006 12.4584V9.12503C6.00006 8.75684 5.70158 8.45836 5.33339 8.45836Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6667 1.125H9.33336C8.96517 1.125 8.66669 1.42348 8.66669 1.79167V5.12501C8.66669 5.4932 8.96517 5.79168 9.33336 5.79168H12.6667C13.0349 5.79168 13.3334 5.4932 13.3334 5.12501V1.79167C13.3334 1.42348 13.0349 1.125 12.6667 1.125Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6667 8.45836H9.33336C8.96517 8.45836 8.66669 8.75684 8.66669 9.12503V12.4584C8.66669 12.8266 8.96517 13.125 9.33336 13.125H12.6667C13.0349 13.125 13.3334 12.8266 13.3334 12.4584V9.12503C13.3334 8.75684 13.0349 8.45836 12.6667 8.45836Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
