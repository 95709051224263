import { ClockCircleOutlined } from "@ant-design/icons";
import { ColumnsType, TableProps } from "antd/es/table";
import React, { Dispatch, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { AnyAction } from "redux";
import { DeleteIcon, EditIcon } from "../../assets/icons";
import { Button, Table } from "antd";
import { useFetchApiKeys, usePagination } from "../../hooks";
import {
  formatKeys,
  getLocalDateTimeStringFromISO,
  getOffsetFromPageAndPageSize,
} from "../../utils";
import { AppPagination } from "../../components";
import "./api-key-container.scss";

interface IAPIKeysDataType {
  key: string;
  serviceName: string;
  clientId: string;
  clientSecretKey: string;
  createdAt: string;
  action: string;
}

const DATA: IAPIKey[] = [
  {
    id: 1,
    serviceName: "Service A",
    clientId: "ABC123",
    clientSecretKey: "xyz789",
    createdAt: "2023-07-24T14:44:19.316Z",
  },
  {
    id: 2,
    serviceName: "Service B",
    clientId: "DEF456",
    clientSecretKey: "pqr987",
    createdAt: "2023-07-24T14:44:19.316Z",
  },
  {
    id: 3,
    serviceName: "Service C",
    clientId: "GHI789",
    clientSecretKey: "mno654",
    createdAt: "2023-07-24T14:44:19.316Z",
  },
  {
    id: 4,
    serviceName: "Service D",
    clientId: "JKL012",
    clientSecretKey: "stu345",
    createdAt: "2023-07-24T14:44:19.316Z",
  },
  {
    id: 5,
    serviceName: "Service E",
    clientId: "MNO678",
    clientSecretKey: "vwx012",
    createdAt: "2023-07-24T14:44:19.316Z",
  },
  {
    id: 6,
    serviceName: "Service F",
    clientId: "PQR901",
    clientSecretKey: "ghi234",
    createdAt: "2023-07-24T14:44:19.316Z",
  },
  {
    id: 7,
    serviceName: "Service G",
    clientId: "STU345",
    clientSecretKey: "abc567",
    createdAt: "2023-07-24T14:44:19.316Z",
  },
  {
    id: 8,
    serviceName: "Service H",
    clientId: "VWX678",
    clientSecretKey: "def890",
    createdAt: "2023-07-24T14:44:19.316Z",
  },
  {
    id: 9,
    serviceName: "Service I",
    clientId: "YZA901",
    clientSecretKey: "jkl234",
    createdAt: "2023-07-24T14:44:19.316Z",
  },
  {
    id: 10,
    serviceName: "Service J",
    clientId: "BCD234",
    clientSecretKey: "mno567",
    createdAt: "2023-07-24T14:44:19.316Z",
  },
  {
    id: 11,
    serviceName: "Service K",
    clientId: "EFG567",
    clientSecretKey: "pqr890",
    createdAt: "2023-07-24T14:44:19.316Z",
  },
  {
    id: 12,
    serviceName: "Service L",
    clientId: "HIJ890",
    clientSecretKey: "stu123",
    createdAt: "2023-07-24T14:44:19.316Z",
  },
  {
    id: 13,
    serviceName: "Service M",
    clientId: "KLM123",
    clientSecretKey: "vwx456",
    createdAt: "2023-07-24T14:44:19.316Z",
  },
  {
    id: 14,
    serviceName: "Service N",
    clientId: "NOP456",
    clientSecretKey: "ghi789",
    createdAt: "2023-07-24T14:44:19.316Z",
  },
  {
    id: 15,
    serviceName: "Service O",
    clientId: "QRS789",
    clientSecretKey: "abc012",
    createdAt: "2023-07-24T14:44:19.316Z",
  },
];

const getColumns = (dispatch: Dispatch<AnyAction>): ColumnsType<IAPIKeysDataType> => [
  {
    title: "Service Name",
    dataIndex: "serviceName",
  },
  {
    title: "Client Id",
    dataIndex: "clientId",
  },
  {
    title: "Client Secret Key",
    dataIndex: "clientSecretKey",
  },
  {
    title: "Created At",
    dataIndex: "createdAt",
    sorter: true,
  },
  {
    title: "Action",
    dataIndex: "action",
    render: (value, record) => (
      <div className="flex jcc aic gp link-color">
        <ClockCircleOutlined className="cursor-pointer" />
        <EditIcon className="cursor-pointer" />
        <DeleteIcon className="cursor-pointer" />
      </div>
    ),
  },
];

export const APIKeysContainer = () => {
  const dispatch = useDispatch();
  const {
    apiKeys,
    apiKeysLoading,
    fetchApiKeys,
    setApiKeysLoading,
    totalApiKeys,
    setTotalApiKeys,
    setApiKeys,
    getApiKeysFilterObject,
    paginationObj,
    sortFilter,
  } = useFetchApiKeys();
  const { onPaginationChange, page, pageSize, pageSizeOptions } = paginationObj;
  const { setSort, sort } = sortFilter;

  const onTableChange: TableProps<IAPIKeysDataType>["onChange"] = (pagination, filters, sorter) => {
    setSort(sorter);
  };

  // useEffect(() => {
  //   getApiKeys();
  // }, [getApiKeys]);

  useEffect(() => {
    setApiKeys(DATA);
    setTotalApiKeys(100);
  }, [setApiKeys, setTotalApiKeys]);

  const dataSource: IAPIKeysDataType[] = useMemo(
    () =>
      apiKeys.map((apiKey) => ({
        key: apiKey.id.toString(),
        serviceName: apiKey.serviceName,
        clientId: apiKey.clientId,
        clientSecretKey: formatKeys(apiKey.clientSecretKey),
        createdAt: getLocalDateTimeStringFromISO(apiKey.createdAt),
        action: "",
      })),
    [apiKeys]
  );

  return (
    <div>
      <div className="flex jcsb gp aic m-b">
        <h3>List of API Keys Provided</h3>
        <Button className="fill">Add New Key</Button>
      </div>
      <AppPagination
        className="m-b tr"
        {...{
          showSizeChanger: true,
          current: page,
          total: totalApiKeys,
          pageSize,
          pageSizeOptions,
          onChange: onPaginationChange,
        }}
      />
      <Table
        className="api-key-table"
        columns={getColumns(dispatch)}
        dataSource={dataSource}
        onChange={onTableChange}
        loading={apiKeysLoading}
        pagination={false}
      />
    </div>
  );
};
