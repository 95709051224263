import React from "react";
import { IconsProps } from "./Icons";

export const MailIcon = ({ className, fill, width, height, viewBox }: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : "0 0 88 76"}
      width={width ? width : "88"}
      height={height ? height : "76"}
      fill={fill ? fill : "currentColor"}
    >
      <path
        d="M75.3333 0.666748H8.66665C4.08331 0.666748 0.37498 4.41675 0.37498 9.00008L0.333313 59.0001C0.333313 63.5834 4.08331 67.3334 8.66665 67.3334H42V59.0001H8.66665V17.3334L42 38.1667L75.3333 17.3334V38.1667H83.6666V9.00008C83.6666 4.41675 79.9166 0.666748 75.3333 0.666748ZM42 29.8334L8.66665 9.00008H75.3333L42 29.8334ZM64.25 75.6667L49.5 60.9167L55.375 55.0417L64.2083 63.8751L81.875 46.2084L87.8333 52.0834L64.25 75.6667Z"
        fill="#475569"
      />
    </svg>
  );
};
