import React, { useState } from "react";
import { Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { ForgotPasswordProps } from ".";
import { ForgotPasswordSuccess } from "./ForgotPasswordSuccess";
import { ForgotPasswordForm } from "./ForgotPasswordForm";
import "../../../pages/auth/login.scss";
import "../modal.scss";

const ForgotPassword = ({ visibility, handleClose }: ForgotPasswordProps) => {
  const [email, setEmail] = useState("");
  return (
    <Modal
      className="autonomizeModal"
      centered
      width={690}
      visible={visibility}
      footer={null}
      closeIcon={<CloseOutlined onClick={handleClose} />}
    >
      <div className="addClient-title">
        <h1>{email ? "Email Sent" : "Forgot Password"}</h1>
      </div>
      <div className="login-content-modal">
        {email ? (
          <ForgotPasswordSuccess email={email} />
        ) : (
          <ForgotPasswordForm setEmail={setEmail} />
        )}
      </div>
    </Modal>
  );
};

export default ForgotPassword;
