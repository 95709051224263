import { Button } from "antd";
import React, { Dispatch, SetStateAction } from "react";

export enum FooterClick {
  CLOSE,
  SEARCH,
  NONE,
}

interface FooterProps {
  setFooterClick: Dispatch<SetStateAction<FooterClick>>;
  disableSearch: boolean;
}

export const SearchOccurrencesFilterFooter = ({ setFooterClick, disableSearch }: FooterProps) => {
  const onClick = (footerClick: FooterClick) => {
    setFooterClick(footerClick);
  };

  return (
    <div className="drawer-close flex gp aic jce">
      <a onClick={() => onClick(FooterClick.CLOSE)}>Close</a>
      <Button
        type="primary"
        className="outline"
        disabled={disableSearch}
        onClick={() => onClick(FooterClick.SEARCH)}
      >
        Search
      </Button>
    </div>
  );
};
