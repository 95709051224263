import React, { useEffect, useState } from "react";
import { Button, Form, Input } from "antd";
import { useForm } from "antd/lib/form/Form";
import { MandatoryFieldsText } from "../../../components";
import { useAuthUser } from "../../../hooks";

interface Props {
  onClose?: () => void;
}

export const EditProfileForm = (props: Props) => {
  const { onClose } = props;
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [disableSave, setDisableSave] = useState(true);
  const [error, setError] = useState("");
  const user = useAuthUser();

  useEffect(() => {
    const { firstName, lastName, email } = user;
    form.setFieldsValue({
      name: firstName + " " + lastName,
      email,
      password: "",
    });
  }, [form, user]);

  const handleFormChange = () => {
    const hasErrors =
      form.isFieldsTouched(true) && form.getFieldsError().some(({ errors }) => errors.length);
    setDisableSave(hasErrors);
  };

  const handleEditSubmit = async (e: any) => {
    setLoading(true);
    setError("");
    const nameSplit = e.target[0].value.split(" ");
    const firstName = nameSplit[0];
    const lastName = nameSplit.slice(1).join(" ");
    const password = e.target[2].value;
    let request: any = { firstName, lastName };
    if (password) request = { ...request, password };
    try {
      // const { data } = await userService.editSelf(request);
      // dispatch(updateCurrentUser(data.data));
      onClose && onClose();
    } catch (err: any) {
      setError(err.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {/* <div className="addClient-title"><h2>{title}</h2></div> */}
      <div className="addClient-content">
        <Form
          form={form}
          onSubmitCapture={handleEditSubmit}
          onFieldsChange={handleFormChange}
          colon={false}
          requiredMark={false}
        >
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: "Please enter name" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              { required: true, message: "Please enter email" },
              { type: "email", message: "Please enter valid email" },
            ]}
          >
            <Input disabled />
          </Form.Item>

          <Form.Item name="password" label="Password (Optional)">
            <Input
              type="password"
              readOnly
              onFocus={(e: React.ChangeEvent<HTMLInputElement>) => {
                e.target.removeAttribute("readonly");
              }}
            />
          </Form.Item>
          {error && <h3 className="error">{error}</h3>}
          <Form.Item className="tr mandatory-text">
            <MandatoryFieldsText />
            <Button
              className="outline"
              // type="primary"
              htmlType="submit"
              disabled={disableSave}
              loading={loading}
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};
