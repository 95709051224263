import React from "react";
import { ExternalLinkIcon } from "../../assets/icons";
import "./ml-configuration.scss";

const mlServerBaseUrl = process.env.REACT_APP_ML_SERVER_BASE_URL;
const annotationStudioBaseUrl = process.env.REACT_APP_ANNOTATION_STUDIO_BASE_URL;

export const MLConfigurationContainer = () => {
  const handleDivClick = (link?: string) => {
    window.open(link, "_blank");
  };

  return (
    <div className="ml-config-card-group flex aic jcc">
      <div
        className="cursor-pointer ml-config-card"
        onClick={() => handleDivClick(mlServerBaseUrl)}
      >
        <h2 className="m-b">ML Configurations and Model Management</h2>
        <div className="tr">
          <ExternalLinkIcon />
        </div>
      </div>
      <div
        className="cursor-pointer ml-config-card"
        onClick={() => handleDivClick(annotationStudioBaseUrl)}
      >
        <h2 className="m-b">
          Data Labeling and <span>Annotations</span>
        </h2>
        <div className="tr">
          <ExternalLinkIcon />
        </div>
      </div>
    </div>
  );
};
