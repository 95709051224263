import React from "react";
import { EvidencePageResponseInfo, PegaPageDataSummary } from "../../../api";
import { EvidenceViewerDefault } from "./EvidenceContentDefault";
import { Collapse, Table } from "antd";
import { CollapseProps } from "antd/lib";
import { EvidenceContentFormData } from "./EvidenceContentFormData";
import { Loading } from "../../../components";

interface ExtractionOutputProps {
  pageInfo: EvidencePageResponseInfo;
  searchKeywords: string;
  selectedLegends: string[];
  loading: boolean;
  pegaPageInfo?: PegaPageDataSummary;
}

interface Procedure {
  procedureCode: string;
  dateOfServiceStart: string;
  dateOfServiceEnd: string;
}

interface Diagnosis {
  diagnosisCode: string;
  diagnosisQualifier: string;
}

export const ExtractionOutput = ({
  pageInfo,
  searchKeywords,
  selectedLegends,
  loading,
  pegaPageInfo,
}: ExtractionOutputProps) => {

  const columns = [
    {
      title: "Key",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Values",
      dataIndex: "values",
      key: "values",
      render: (values: string | Procedure[] | Diagnosis[]) => {
        if (Array.isArray(values)) {
          if (values.length && "procedureCode" in values[0]) {
            // Procedures
            return (
              <ul>
                {values.map((procedure, index) => (
                  (procedure as Procedure).procedureCode && (
                    <li key={index}>Procedure Code: {(procedure as Procedure).procedureCode}</li>
                  )
                ))}
              </ul>
            );
          } else if (values.length && "diagnosisCode" in values[0]) {
            // Diagnoses
            return (
              <ul>
                {values.map((diagnosis, index) => (
                  (diagnosis as Diagnosis).diagnosisCode && (
                    <li key={index}>Diagnosis Code: {(diagnosis as Diagnosis).diagnosisCode}</li>
                  )
                ))}
              </ul>
            );
          } else {
            // Other array types - ignore
            return null;
          }
        }
  
        return <p>{values}</p>;
      },
    },
  ];
  const items: CollapseProps["items"] = pegaPageInfo
    ? [
        {
          key: "1",
          label: "Text Content",
          children: (
            <EvidenceViewerDefault {...{ pageInfo, searchKeywords, selectedLegends, loading }} />
          ),
          className: "extractionOutput-",
        },
        {
          key: "2",
          label: "Form Data",
          children: loading ? <Loading /> : <EvidenceContentFormData formData={pageInfo.form} />,
        },
        {
          key: "3",
          label: "Keyword Extraction Output",
          children: loading ? (
            <Loading />
          ) : (
            <Table
              className="summaryTable"
              dataSource={JSON.parse(pegaPageInfo?.KeywordExtractionOutput)?.sort((a: any, b: any) =>
                a.key.localeCompare(b.key)
              )}
              columns={columns}
              pagination={false}
            />
          ),
        },
        {
          key: "4",
          label: "Tolstoy Extraction Output",
          children: loading ? (
            <Loading />
          ) : (
            <Table
              className="summaryTable"
              dataSource={JSON.parse(pegaPageInfo?.TolstoyExtractionOutput)?.sort((a: any, b: any) =>
                a.key.localeCompare(b.key)
              )}
              columns={columns}
              pagination={false}
            />
          ),
        },
      ]
    : [
        {
          key: "1",
          label: "Text Content",
          children: (
            <EvidenceViewerDefault {...{ pageInfo, searchKeywords, selectedLegends, loading }} />
          ),
          className: "extractionOutput-",
        },
        {
          key: "2",
          label: "Form Data",
          children: loading ? <Loading /> : <EvidenceContentFormData formData={pageInfo.form} />,
        },
      ];
  return (
    <div>
      {/* <h3 style={{marginBottom: "20px"}}>Cognitive Search</h3> */}
      <Collapse style={{ marginBottom: "20px" }} items={items} />
    </div>
  );
};
