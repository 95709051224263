import React, { useEffect, useState } from "react";
import { EvidencePageResponseInfo, PegaPageDataSummary } from "../../../../api";
import { EvidenceContentJSON, EvidenceContentPegaOCR } from "..";
import { ENHANCED_VIEW_TABS_PEGA } from "../../EvidenceViewerContainer";
import { Loading } from "../../../../components";
import "../evidence-content.scss";
import { isEmptyObject } from "../../../../utils";
import EvidenceContentSummaryPega, { KeyValue } from "./EvidenceContentSummaryPega";

interface Props {
  pageInfo: PegaPageDataSummary;
  loading: boolean;
  selectedTab: string;
  searchKeywords: string;
  selectedLegends: string[];
  fileId: string;
  pageData: EvidencePageResponseInfo;
}

export const EvidenceContentPega = ({
  pageInfo,
  loading,
  selectedTab,
  searchKeywords,
  selectedLegends,
  fileId,
  pageData,
}: Props) => {
  const [component, setComponent] = useState(<></>);

  useEffect(() => {
    if (!isEmptyObject(pageInfo)) {
      let res = <></>;
      switch (selectedTab) {
        case ENHANCED_VIEW_TABS_PEGA.SUMMARY:
          res = (
            <EvidenceContentSummaryPega
              values={
                typeof pageInfo?.FaxInsights === "string"
                  ? JSON.parse(pageInfo?.FaxInsights)?.data?.values
                  : (pageInfo?.FaxInsights?.data?.values)
              }
              fileId={fileId}
            />
          );
          break;
        case ENHANCED_VIEW_TABS_PEGA.PAGE:
          res = (
            <EvidenceContentPegaOCR
              pageInfo={pageData}
              searchKeywords={searchKeywords}
              selectedLegends={selectedLegends}
              loading={loading}
              pegaPageInfo={pageInfo}
            />
          );
          break;
        case ENHANCED_VIEW_TABS_PEGA.JSON:
          res = <EvidenceContentJSON pageInfo={pageInfo} />;
          break;
      }
      setComponent(res);
    }
  }, [pageInfo, searchKeywords, selectedLegends, selectedTab, loading]);

  return (
    <>
      <div className="e-content">{component}</div>
    </>
  );
};
