import React from "react";
import { IconsProps } from "./Icons";

export const LogoutIcon = ({ className, fill, width, height, viewBox }: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : "0 0 20 16"}
      width={width ? width : "20"}
      height={height ? height : "16"}
      fill={fill ? fill : "none"}
    >
      <path d="M12.6845 12.1667V13.8333C12.6845 14.2754 12.4932 14.6993 12.1528 15.0118C11.8123 15.3244 11.3505 15.5 10.869 15.5H2.69939C2.21789 15.5 1.75612 15.3244 1.41565 15.0118C1.07518 14.6993 0.883911 14.2754 0.883911 13.8333V2.16667C0.883911 1.72464 1.07518 1.30072 1.41565 0.988155C1.75612 0.675595 2.21789 0.5 2.69939 0.5H10.869C11.3505 0.5 11.8123 0.675595 12.1528 0.988155C12.4932 1.30072 12.6845 1.72464 12.6845 2.16667V3.83333H10.869V2.16667H2.69939V13.8333H10.869V12.1667H12.6845ZM14.9539 3.41667L13.6703 4.595L16.4716 7.16667H7.23808V8.83333H16.4716L13.6703 11.405L14.9539 12.5833L19.9464 8L14.9539 3.41667Z" fill={fill ? fill : "currentColor"} />
    </svg>
  );
};
