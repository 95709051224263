import { apiClient, GetSearchResultsRequest } from ".";
import { IPatient } from "../interfaces/patientInsight";
import { COPILOTS_NAME, SEARCH_KEYWORD_DELIMINATOR } from "../utils";

export interface Trait {
  Score: number;
  Name: string;
}

export interface BaseEntity {
  BeginOffset: number;
  EndOffset: number;
  Category: string;
  Id: number;
  Score: number;
  Text: string;
  Traits: Trait[];
  Type: string;
}

export interface Attribute extends BaseEntity {
  RelationshipScore: number;
  RelationshipType: string;
}

export interface Entities extends BaseEntity {
  Traits: Trait[];
  Attributes?: Entities[];
}

export interface MedicalEntities {
  Entities: Entities[];
  ModelVersion: string;
  ResponseMetaData: any;
  UnmappedAttributes: any[];
}
export interface Form {
  key: string;
  value: string;
}
export interface NormalEntities {
  ResultList: [{ Entities: [] }, { Entities: Partial<Entities>[]; Index: number }];
}
export interface EvidencePageResponseInfo extends Pick<IPatient, "threshold_confidence_score"> {
  content: string;
  heading: string;
  form: Form[];
  medicalEntities: Entities[];
  normalEntities: Partial<Entities>[];
  pageNumber: number;
  patient: { patientId: number; name: string; fileName: string; mrn: string };
}

interface KeyValue {
  key: string;
  values: string | KeyValue[];
}

export interface PegaPageDataSummary {
  DocumentUUID: string;
  FileName: string;
  FaxProcessedStatus: string;
  FaxIncludedMultipleMembers: string;
  FaxInsights: {
    metadata: {
      id: string;
      formatVersion: string;
      producingSoftwareProduct: string;
      producingSoftwareProductVersion: string;
    };
    data: {
      id: string;
      faxProcessedDateTime: string;
      faxImageBlobstorageURL: string;
      faxProcessedStatus: {
        status: string;
        errorMessage: string;
        missingFields: string[];
      };
      faxIncludedMultipleMembers: boolean;
      values: { key: string; values: string | KeyValue[] }[];
    };
  };
  Metadata: {
    FaxDocID: null;
    docURL: null;
    AzureDocID: string;
  };
  KeywordExtractionOutput: string;
  TolstoyExtractionOutput: string;
}

export interface SearchOccurrence {
  pageNumber: number;
  name: string;
  documentId: string;
  inOccured: number;
  content: string;
}
export interface EvidencePageSearchOccurrencesResponse {
  occurences: number;
  occurencesList: SearchOccurrence[];
}

export interface EvidenceFileData {
  fileName: string;
  url: string;
}

export interface EvidenceFileResponse {
  fileData: EvidenceFileData;
}

const getPageData = async (page: number, fileId: string) => {
  const res = await apiClient.get<EvidencePageResponseInfo>(
    `evidence/viewer/page/${page}/file/${fileId}`
  );
  return res;
};

const getAngCCAData = async (fileId: number) => {
  const res = await apiClient.get<EvidencePageResponseInfo>(`requests/${fileId}`);
  return res;
};

const getPDFData = async () => {
  const res = await apiClient.get<EvidencePageResponseInfo>(`extracttextpdf`);
  return res;
};

const getOccurrencesPages = async (
  fileId: string,
  searchQuery: string | null,
  tileType: string | null
) => {
  let request: Partial<GetSearchResultsRequest> = {
    search: searchQuery ? searchQuery.split(SEARCH_KEYWORD_DELIMINATOR) : [],
  };
  if (tileType) request = { ...request, type: tileType };
  const res = await apiClient.post<EvidencePageSearchOccurrencesResponse>(
    `evidence/v2/occurance/${fileId}`,
    request
  );
  return res;
};

const submitFeedback = async (pageNo: number, fileUUID: string, comment: string) => {
  const res = await apiClient.post(`/evidence/feedback/${pageNo}/${fileUUID}`, {
    comment,
  });
  return res;
};

const getFile = async (fileUUID: string, copilotName: string) => {
  const suffix =
    copilotName === COPILOTS_NAME.PEGA_PRIOR_AUTH || copilotName === COPILOTS_NAME.ANG_INSIGHTS
      ? `?copilot=pega`
      : ``;
  const res = await apiClient.get<EvidenceFileResponse>(`/evidence/uuid/${fileUUID}/file${suffix}`);
  return res;
};

const getPegaPageData = async (fileUUID: string) => {
  const res = await apiClient.get<{ data: PegaPageDataSummary }>(
    `/priorauth/pegaSummary?document_uuid=${fileUUID.toLowerCase()}`
  );
  return res.data;
};

const downloadSummary = async (fileUUID: string) => {
  const res = await apiClient.get(
    `priorauth/downloadPegaSummary?document_uuid=${fileUUID.toLowerCase()}`
  );
  return res.data;
};

export const evidenceViewerService = {
  getPageData,
  getOccurrencesPages,
  submitFeedback,
  getFile,
  getPDFData,
  getPegaPageData,
  downloadSummary,
  getAngCCAData,
};
