import { apiClient } from "./apiClient";

const getAllRolesMapping = async () => {
  const res = await apiClient.get<IGetAllRolesMappingResponse[]>("/rbac/role");
  return res;
};

const validateLicenseKey = async (
  licenseKey: string
): Promise<{ data: { isValid: boolean; licenseKey: string }; status: string }> => {
  // const res = await apiClient.post<IValidateLicenseKeyResponse>("", { licenseKey });
  // return res;
  return { data: { isValid: true, licenseKey }, status: "" };
};

const getPermissions = async (roleId: number, limit: number, offset: number) => {
  const res = await apiClient.get<IGetPermissionResponseWrapper>(
    `/rbac/role/${roleId}/permissions?limit=${limit}&offset=${offset}`
  );
  return res;
};

export const RBACService = {
  getAllRolesMapping,
  validateLicenseKey,
  getPermissions,
};
