import React from "react";
import { ColorConfigurationSetting } from "./ColorConfigurationSetting";
import { HEADING_AND_TEXT_COLOR } from "../../../utils";

export const HeadingAndTextColor = () => {
  return (
    <div className="theme-config-card">
      <h4>Heading & Text Color</h4>
      <div className="heading-text-color">
        {HEADING_AND_TEXT_COLOR.map((item, index) => (
          <ColorConfigurationSetting key={index} colorConfiguration={item} />
        ))}
      </div>
    </div>
  );
};
