import React from "react";
import { IconsProps } from "./Icons";

export const AnGIcon = ({ className, fill, width, height, viewBox }: IconsProps) => {
  return (
    <svg width="32" height="42" viewBox="0 0 32 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 6C14.6739 6 13.4021 6.52678 12.4645 7.46447C11.5268 8.40215 11 9.67392 11 11C11 12.3261 11.5268 13.5979 12.4645 14.5355C13.4021 15.4732 14.6739 16 16 16C17.3261 16 18.5979 15.4732 19.5355 14.5355C20.4732 13.5979 21 12.3261 21 11C21 9.67392 20.4732 8.40215 19.5355 7.46447C18.5979 6.52678 17.3261 6 16 6ZM13.5 11C13.5 10.337 13.7634 9.70107 14.2322 9.23223C14.7011 8.76339 15.337 8.5 16 8.5C16.663 8.5 17.2989 8.76339 17.7678 9.23223C18.2366 9.70107 18.5 10.337 18.5 11C18.5 11.663 18.2366 12.2989 17.7678 12.7678C17.2989 13.2366 16.663 13.5 16 13.5C15.337 13.5 14.7011 13.2366 14.2322 12.7678C13.7634 12.2989 13.5 11.663 13.5 11ZM7.25 32.25C6.91848 32.25 6.60054 32.3817 6.36612 32.6161C6.1317 32.8505 6 33.1685 6 33.5C6 33.8315 6.1317 34.1495 6.36612 34.3839C6.60054 34.6183 6.91848 34.75 7.25 34.75H24.75C25.0815 34.75 25.3995 34.6183 25.6339 34.3839C25.8683 34.1495 26 33.8315 26 33.5C26 33.1685 25.8683 32.8505 25.6339 32.6161C25.3995 32.3817 25.0815 32.25 24.75 32.25H7.25ZM1 6C1 4.67392 1.52678 3.40215 2.46447 2.46447C3.40215 1.52678 4.67392 1 6 1H26C27.3261 1 28.5979 1.52678 29.5355 2.46447C30.4732 3.40215 31 4.67392 31 6V36C31 37.3261 30.4732 38.5979 29.5355 39.5355C28.5979 40.4732 27.3261 41 26 41H6C4.67392 41 3.40215 40.4732 2.46447 39.5355C1.52678 38.5979 1 37.3261 1 36V6ZM26 3.5H6C5.33696 3.5 4.70107 3.76339 4.23223 4.23223C3.76339 4.70107 3.5 5.33696 3.5 6V26H8.5V21C8.5 20.337 8.76339 19.7011 9.23223 19.2322C9.70107 18.7634 10.337 18.5 11 18.5H21C21.663 18.5 22.2989 18.7634 22.7678 19.2322C23.2366 19.7011 23.5 20.337 23.5 21V26H28.5V6C28.5 5.33696 28.2366 4.70107 27.7678 4.23223C27.2989 3.76339 26.663 3.5 26 3.5ZM21 21H11V26H21V21ZM3.5 28.5V36C3.5 36.663 3.76339 37.2989 4.23223 37.7678C4.70107 38.2366 5.33696 38.5 6 38.5H26C26.663 38.5 27.2989 38.2366 27.7678 37.7678C28.2366 37.2989 28.5 36.663 28.5 36V28.5H3.5Z"
        fill="#5F3CAB"
      />
    </svg>
  );
};
