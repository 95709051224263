import { DownloadOutlined } from "@ant-design/icons";
import Table, { ColumnsType } from "antd/es/table";
import React, { Dispatch, useCallback, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { AnyAction } from "redux";
import { usePagination } from "../../hooks";
import { useFetchDeployments } from "../../hooks/useFetchDeployments";
import { getLocalDateTimeStringFromISO, getOffsetFromPageAndPageSize } from "../../utils";
import { AppPagination } from "../../components";
import "./deployment-container.scss";

interface IDeploymentsDataType {
  key: string;
  deploymentPackage: string;
  version: string;
  owner: string;
  lastUpdated: string;
  download: string;
}

const DATA: IDeployment[] = [
  {
    id: 1,
    package: "packageA",
    version: "1.0.0",
    owner: "John Doe",
    lastUpdated: "2023-07-24T14:44:19.316Z",
  },
  {
    id: 2,
    package: "packageB",
    version: "2.1.0",
    owner: "Jane Smith",
    lastUpdated: "2023-07-24T14:44:19.316Z",
  },
  {
    id: 3,
    package: "packageC",
    version: "3.5.2",
    owner: "Michael Johnson",
    lastUpdated: "2023-07-24T14:44:19.316Z",
  },
  // Add more entries as needed
  {
    id: 4,
    package: "packageD",
    version: "4.2.1",
    owner: "Emily Brown",
    lastUpdated: "2023-07-24T14:44:19.316Z",
  },
  {
    id: 5,
    package: "packageE",
    version: "5.3.0",
    owner: "David Lee",
    lastUpdated: "2023-07-24T14:44:19.316Z",
  },
  {
    id: 6,
    package: "packageF",
    version: "6.1.3",
    owner: "Sophia Wang",
    lastUpdated: "2023-07-24T14:44:19.316Z",
  },
  {
    id: 7,
    package: "packageG",
    version: "7.0.2",
    owner: "Daniel Kim",
    lastUpdated: "2023-07-24T14:44:19.316Z",
  },
  {
    id: 8,
    package: "packageH",
    version: "8.4.5",
    owner: "Olivia Chen",
    lastUpdated: "2023-07-24T14:44:19.316Z",
  },
  {
    id: 9,
    package: "packageI",
    version: "9.2.1",
    owner: "James Wong",
    lastUpdated: "2023-07-24T14:44:19.316Z",
  },
  {
    id: 10,
    package: "packageJ",
    version: "10.3.2",
    owner: "Emma Kim",
    lastUpdated: "2023-07-24T14:44:19.316Z",
  },
  {
    id: 11,
    package: "packageK",
    version: "11.1.0",
    owner: "William Liu",
    lastUpdated: "2023-07-24T14:44:19.316Z",
  },
  {
    id: 12,
    package: "packageL",
    version: "12.0.3",
    owner: "Ava Smith",
    lastUpdated: "2023-07-24T14:44:19.316Z",
  },
  {
    id: 13,
    package: "packageM",
    version: "13.5.1",
    owner: "Ethan Wang",
    lastUpdated: "2023-07-24T14:44:19.316Z",
  },
  {
    id: 14,
    package: "packageN",
    version: "14.2.0",
    owner: "Isabella Johnson",
    lastUpdated: "2023-07-24T14:44:19.316Z",
  },
  {
    id: 15,
    package: "packageO",
    version: "15.0.4",
    owner: "Mia Davis",
    lastUpdated: "2023-07-24T14:44:19.316Z",
  },
];

const getColumns = (dispatch: Dispatch<AnyAction>): ColumnsType<IDeploymentsDataType> => [
  { title: "Deployment Package", dataIndex: "deploymentPackage" },
  { title: "Version", dataIndex: "version" },
  { title: "Owner", dataIndex: "owner" },
  { title: "Last Updated", dataIndex: "lastUpdated" },
  {
    title: "Download",
    dataIndex: "download",
    render: (value, record) => (
      <div className="link-color">
        <DownloadOutlined />
      </div>
    ),
  },
];
export const DeploymentContainer = () => {
  const dispatch = useDispatch();
  const {
    deployments,
    deploymentsLoading,
    fetchDeployments,
    setDeploymentsLoading,
    totalDeployments,
    setTotalDeployments,
    setDeployments,
    getDeploymentsFilterObject,
    paginationObj,
    sortFilter,
  } = useFetchDeployments();

  const { onPaginationChange, page, pageSize, pageSizeOptions } = paginationObj;
  const { setSort, sort } = sortFilter;

  useEffect(() => {
    fetchDeployments();
  }, [fetchDeployments]);

  useEffect(() => {
    setDeployments(DATA);
    setTotalDeployments(100);
  }, [setDeployments, setTotalDeployments]);

  const dataSource: IDeploymentsDataType[] = useMemo(
    () =>
      deployments.map((deployment) => ({
        key: deployment.id.toString(),
        deploymentPackage: deployment.package,
        version: deployment.version,
        owner: deployment.owner,
        lastUpdated: getLocalDateTimeStringFromISO(deployment.lastUpdated),
        download: "",
      })),
    [deployments]
  );

  return (
    <div>
      <AppPagination
        className="m-b tr"
        {...{
          showSizeChanger: true,
          current: page,
          total: totalDeployments,
          pageSize,
          pageSizeOptions,
          onChange: onPaginationChange,
        }}
      />
      <Table
        className="deployment-table"
        columns={getColumns(dispatch)}
        dataSource={dataSource}
        pagination={false}
      />
    </div>
  );
};
