import { apiClient } from ".";

interface IUserLoginRequest {
  email: string;
  password: string;
}

interface IUserSignUpRequest {
  firstName: string;
  lastName: string;
  email: string;
}

interface IUserLoginResponse {
  data: IAuthUser;
  status: string;
}

interface IUserForgotPasswordResponse {
  data: string;
  password: string;
}

interface IUser {
  firstName: string;
  lastName: string;
  email: string;
  isInvited: boolean;
  isActive: boolean;
}

interface IUserSignUpResponse {
  data: IUser;
  status: string;
}

// interface IGenerateResetPasswordLinkResponse {
//   data: {
//     token: string;
//     link: string;
//   };
// }

const login = async (requestObject: IUserLoginRequest) => {
  const res = await apiClient.post<IUserLoginResponse>(`/auth/login`, requestObject);
  return res;
};

const forgotPassword = async (reqObj: { email: string }) => {
  const res = await apiClient.post<IUserForgotPasswordResponse>(
    "/auth/forgot-password",
    reqObj
  );
  return res;
};

const resetPassword = async (reqObj: { password: string }, token: string) => {
  const res = await apiClient.post<IUserForgotPasswordResponse>(
    `/auth/reset-password`,
    reqObj,
    {
      params: { token },
    }
  );
  return res;
};

const createPassword = async (reqObj: { password: string }, token: string) => {
  const res = await apiClient.post<IUserForgotPasswordResponse>(
    `/auth/create-password`,
    reqObj,
    {
      params: { token },
    }
  );
  return res;
};

// const editSelf = async (reqObj: Partial<IEditUserRequestData>) => {
//   const res = await apiClient.put<IEditUserResponse>(`/auth/me/update`, reqObj);
//   return res;
// };

// const generateResetPasswordLink = async (email: string) => {
//   const res = await apiClient.post<IGenerateResetPasswordLinkResponse>(
//     `/auth/generate-reset-password-link`,
//     { email }
//   );
//   return res;
// };

const signUp = async (reqObject: IUserSignUpRequest) => {
  const res = await apiClient.post<IUserSignUpResponse>("/auth/signup", reqObject);
  return res;
};

export const authService = {
  login,
  // editSelf,
  forgotPassword,
  resetPassword,
  createPassword,
  signUp,
  // generateResetPasswordLink,
};
