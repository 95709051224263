import React, { Dispatch, useEffect, useMemo } from "react";
import { useFetchDocuments } from "../../hooks/useFetchDocuments";
import Table, { ColumnsType, TableProps } from "antd/es/table";
import {
  DOCUMENT_STATUS,
  MODAL_TYPE,
  PAGE_URL,
  getLocalDateTimeStringFromISO,
  openNotificationWithIcon,
} from "../../utils";
import { AppPagination, Loading, NotAccessibleFallback } from "../../components";
import { useDispatch } from "react-redux";
import { AnyAction } from "redux";
import { openModal, setDocumentIdFOrPagesStatus, setDocumentDetail } from "../../store";
import "./document-container.scss";
import { NavigateFunction, useNavigate } from "react-router";

const disableEvidenceViewer = process.env.REACT_APP_HIDE_EVIDENCE_VIEWER === "true";

const getClass = (value: string) => {
  switch (value) {
    case DOCUMENT_STATUS.SUCCEEDED:
      return "#44c658";
    case DOCUMENT_STATUS.ERROR:
      return "#f44336";
    default:
      return "#475569";
  }
};

interface IDocumentsDataType extends IDocument {
  key: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  status: string;
  action: string;
  pageStatus: string;
}

const getColumns = (
  dispatch: Dispatch<AnyAction>,
  navigate: NavigateFunction
): ColumnsType<IDocumentsDataType> => [
  {
    title: "Name",
    dataIndex: "name",
    render: (item, record) => (
      <p
        className="cc flex cursor-pointer"
        onClick={() => {
          if (disableEvidenceViewer) return;
          record.status === DOCUMENT_STATUS.SUCCEEDED
            ? navigate(`${PAGE_URL.EVIDENCE_VIEWER}?fileId=${record.DocumentUUID}`)
            : openNotificationWithIcon(
                "",
                "The File is still processing or failed. Please try with completed files",
                "info"
              );
        }}
      >
        {item}
      </p>
    ),
  },
  { title: "Created At", dataIndex: "createdAt" },
  { title: "Updated At", dataIndex: "updatedAt" },
  {
    title: "Status",
    dataIndex: "status",
    render: (text, value) => (
      <div>
        <div className="status" style={{ color: getClass(value.status) }}>
          <p style={{ color: getClass(value.status) }}>{value.status}</p>
        </div>
      </div>
    ),
  },
  {
    title: "Action",
    dataIndex: "action",
    render: (text, value) => (
      <a
        onClick={() => {
          dispatch(setDocumentDetail(value));
          dispatch(openModal(MODAL_TYPE.DOCUMENT_PROCESSING_DETAIL_STATUS));
        }}
      >
        View More
      </a>
    ),
  },
  {
    title: "Page Status",
    dataIndex: "pageStatus",
    render: (text, value) => (
      <a
        onClick={() => {
          dispatch(setDocumentIdFOrPagesStatus(value.Id));
          dispatch(openModal(MODAL_TYPE.PAGES_STATUS));
        }}
      >
        Check Page Status
      </a>
    ),
  },
];

export const DocumentContainer = () => {
  const {
    documents,
    fetchDocuments,
    getDocumentsFilterObject,
    isDocumentsLoading,
    paginationObj,
    setDocuments,
    setTotalDocuments,
    sortFilter,
    totalDocuments,
    isAuthorized,
  } = useFetchDocuments();
  const { onPaginationChange, page, pageSize, pageSizeOptions } = paginationObj;
  const { setSort, sort } = sortFilter;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onTableChange: TableProps<IDocumentsDataType>["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {
    setSort(sorter);
  };

  useEffect(() => {
    fetchDocuments();
  }, [fetchDocuments]);

  const dataSource: IDocumentsDataType[] = useMemo(
    () =>
      documents.map((document) => ({
        key: document.DocumentUUID,
        name: document.FileName.split("/").splice(-1)[0],
        ...document,
        createdAt: getLocalDateTimeStringFromISO(document.createdAt),
        updatedAt: getLocalDateTimeStringFromISO(document.updatedAt),
        status:
          document.DocumentProcessStatuses.length !== 0
            ? document.DocumentProcessStatuses[document.DocumentProcessStatuses.length - 1].Status
            : "",
        pageStatus: "",
        action: "",
      })),
    [documents]
  );

  if (!isAuthorized) {
    return <NotAccessibleFallback />;
  }

  return (
    <>
      {isDocumentsLoading ? (
        <Loading />
      ) : (
        <div>
          <AppPagination
            className="tr m-b"
            {...{
              showSizeChanger: true,
              current: page,
              total: totalDocuments,
              pageSize,
              pageSizeOptions,
              onChange: onPaginationChange,
            }}
          />
          <Table
            className="document-container-table"
            columns={getColumns(dispatch, navigate)}
            dataSource={dataSource}
            onChange={onTableChange}
            loading={isDocumentsLoading}
            pagination={false}
          />
        </div>
      )}
    </>
  );
};
