import { useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  GetSearchResultsRequest,
  SearchResultResponse,
  searchService,
} from '../api';
import { usePagination } from './usePagination';
import { SEARCH_KEYWORD_DELIMINATOR } from '../utils';

export const useFetchSearchResult = () => {
  const [searchParams] = useSearchParams();
  const { page, pageSize, pageSizeOptions, onPaginationChange } =
    usePagination();
  const [searchResult, setSearchResult] = useState({} as SearchResultResponse);
  const [isApiCalled, setIsApiCalled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalSearchResults, setTotalSearchResults] = useState(0);

  const fetchData = useCallback(
    async (qs?: string) => {
      setLoading(true);
      const params = Object.fromEntries(searchParams.entries());
      const newQueryParams = {
        type: params['type'] || 'All',
        search: params['search'] || [],
        gender: params['gender'] || 'Both',
        ageGroup: params['ageGroup'] || ['All'],
        location: params['location'] || 'All Countries',
        interventions: params['interventions'] || ['All'],
      };
      const searchParamsList =
        params['search']?.split(SEARCH_KEYWORD_DELIMINATOR) || [];
      const ageGroupParamsList = params['ageGroup']?.split(
        SEARCH_KEYWORD_DELIMINATOR,
      ) || ['All'];
      let requestObject = {
        search: searchParamsList,
      } as Partial<GetSearchResultsRequest>;
      if (
        ageGroupParamsList.find((item) => item === 'All' || item === '') ===
        undefined
      ) {
        requestObject = {
          ...requestObject,
          ageGroup: ageGroupParamsList.join(' | '),
        };
      }
      if (newQueryParams.gender !== 'Both') {
        requestObject = { ...requestObject, gender: newQueryParams.gender };
      }
      if (params['fileName']) {
        requestObject = { ...requestObject, fileName: params['fileName'] };
      }
      if (params['patient_id']) {
        requestObject = { ...requestObject, patient_id: params['patient_id'] };
      }
      if (params['tile_type']) {
        requestObject = { ...requestObject, type: params['tile_type'] };
      }
      const { data } = await searchService.getSearchResult(
        requestObject,
        qs ?? '',
      );
      setSearchResult(data);
      if (!params['patient_id']) {
        setTotalSearchResults(data.pagination.total);
      }
      setIsApiCalled(true);
      setLoading(false);
    },
    [searchParams],
  );

  return {
    searchLoading: loading,
    fetchSearchResults: fetchData,
    searchResult,
    isApiCalled,
    page,
    pageSize,
    pageSizeOptions,
    onPaginationChange,
    totalSearchResults,
  };
};
