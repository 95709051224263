import React from "react";
import { Statistic, Progress, Row } from "antd";
import { COPILOTS_NAME } from "../../../utils";
import { APICallsIcon } from "../../../assets/icons/APICallsIcon";

interface ApiCallStatisticsType {
  tokenCount: TokenData;
  copilotName?: string;
}

type TokenData = {
  date: string;
  token_count: {
    service_name: string;
    moduleName: string;
    token_count: number;
  }[];
}[];

interface CopilotTokenCount {
  [moduleName: string]: number;
}

const COLORS = [
  "#DA7C7C",
  "#7280CD",
  "#5EADA6",
  "#E2B039",
  "#DA7C7C",
  "#7280CD",
  "#5EADA6",
  "#E2B039",
];

const BACKGROUND = [
  "#FFF0EA",
  "#DDE1F7",
  "#D1E9E7",
  "#F7EBCF",
  "#FFF0EA",
  "#DDE1F7",
  "#D1E9E7",
  "#F7EBCF",
];

const CopilotStatistic = ({
  title,
  value,
  total,
  color,
  backgroundColor,
}: {
  title: string;
  value: number;
  total: number;
  color: string;
  backgroundColor: string;
}) => (
  <div className="flex gp-10 m-t">
    <APICallsIcon fill={color} backGroundFill={backgroundColor} />
    <Statistic
      title={title}
      value={value}
      suffix="Calls"
      valueStyle={{ color: color, fontSize: "20px", fontWeight: "600" }}
    />
  </div>
);

const ApiCallStatistics = ({ tokenCount, copilotName }: ApiCallStatisticsType) => {
  const transformData = (tokenData: TokenData) => {
    if (!tokenData || tokenData.length === 0) {
      return {};
    }

    const result: Record<string, Record<string, number>> = {};
    tokenData.forEach(({ token_count }) => {
      token_count.forEach(({ service_name, moduleName, token_count: count }) => {
        if (!result[service_name]) {
          result[service_name] = {};
        }

        if (!result[service_name][moduleName]) {
          result[service_name][moduleName] = 0;
        }

        result[service_name][moduleName] += +count;
      });
    });

    const totalResult: Record<string, number> = {};

    Object.keys(result).forEach((service_name) => {
      Object.keys(result[service_name]).forEach((moduleName) => {
        if (!totalResult[moduleName]) {
          totalResult[moduleName] = 0;
        }

        totalResult[moduleName] += result[service_name][moduleName];
      });
    });

    switch (copilotName) {
      case COPILOTS_NAME.PEGA_PRIOR_AUTH:
      case COPILOTS_NAME.ANG_INSIGHTS:
        return result["Pega UM"];
      case COPILOTS_NAME.HEDIS_INSIGHTS:
        return result["Hedis"];
      case COPILOTS_NAME.CAREGPT_CHATBOT:
        return result["Payment Integrity"];
    }

    return totalResult;
  };

  const copilotTokenCount = transformData(tokenCount);

  return (
    <div className="statistics">
      <h4 className="summary-title">API Call Statistics Overview</h4>
      <p>Summary description for api call statistics overview</p>
      <Row className="grid grid-cols-2">
        {copilotTokenCount &&
          Object.entries(copilotTokenCount).map(([moduleName, count], index) => (
            <CopilotStatistic
              key={moduleName}
              title={moduleName}
              value={count}
              total={Object.values(copilotTokenCount).reduce((acc, val) => acc + val, 0)}
              color={COLORS[index]}
              backgroundColor={BACKGROUND[index]}
            />
          ))}
      </Row>
    </div>
  );
};

export default ApiCallStatistics;
