import { Content } from "antd/lib/layout/layout";
import React from "react";
import { Loading } from "../../components";
import { PageAsideLeftWrapper } from "./PageAsideLeftWrapper";
import { PageContainerWrapper } from "./PageContainerWrapper";

interface Props {
  loading?: boolean;
  containerClassName?: string;
  pageLeftAsideChildren?: JSX.Element;
  pageContainerChildren: JSX.Element;
}

export const PageWrapper = ({
  loading,
  pageContainerChildren,
  pageLeftAsideChildren,
  containerClassName,
}: Props) => {
  const noGrid = !pageLeftAsideChildren || loading;

  return (
    <Content className={`${noGrid ? "no-grid" : ""}`}>
      {loading ? (
        <Loading />
      ) : (
        <>
          {pageLeftAsideChildren && (
            <PageAsideLeftWrapper>{pageLeftAsideChildren}</PageAsideLeftWrapper>
          )}
          <PageContainerWrapper className={containerClassName}>{pageContainerChildren}</PageContainerWrapper>
        </>
      )}
    </Content>
  );
};
