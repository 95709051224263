import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Modal, Button } from 'antd';
import {
  FooterClick,
  SearchOccurrencesFilterFooter,
} from './SearchOccurrencesFilterFooter';
import './search-occurrences-filter.scss';
import { SEARCH_KEYWORD_DELIMINATOR } from '../../../utils';

interface Props {
  modalState: {
    visible: boolean;
    setVisible: Dispatch<SetStateAction<boolean>>;
  };
  searchKeywords: string | null;
  setSearchKeywords: React.Dispatch<React.SetStateAction<string | null>>;
  initialSearchKeywords: string | null;
}

export const SearchOccurrencesFilter = ({
  modalState,
  initialSearchKeywords,
  searchKeywords,
  setSearchKeywords,
}: Props) => {
  const { visible, setVisible } = modalState;
  const [footerClick, setFooterClick] = useState(FooterClick.NONE);
  const [localSearchKeywords, setLocalSearchKeywords] = useState(
    [] as { key: string; active: boolean }[],
  );

  const handleOptionClick = (index: number) => {
    const { active, key } = localSearchKeywords[index];
    const local = [
      ...localSearchKeywords.slice(0, index),
      { key, active: !active },
      ...localSearchKeywords.slice(index + 1),
    ];
    setLocalSearchKeywords([...local]);
  };

  const closeDrawer = useCallback(
    (isSearch = false) => {
      if (isSearch) {
        setSearchKeywords(
          localSearchKeywords
            .filter(({ active }) => active === true)
            .map(({ key }) => key)
            .join(SEARCH_KEYWORD_DELIMINATOR),
        );
      }
      setVisible(false);
    },
    [localSearchKeywords, setSearchKeywords, setVisible],
  );

  useEffect(() => {
    if (initialSearchKeywords && searchKeywords) {
      const searchKeywordSplit = searchKeywords.split(
        SEARCH_KEYWORD_DELIMINATOR,
      );
      const local = [
        ...initialSearchKeywords
          .split(SEARCH_KEYWORD_DELIMINATOR)
          .map((key) => ({ key, active: searchKeywordSplit.includes(key) })),
      ];
      setLocalSearchKeywords([...local]);
    }
  }, [initialSearchKeywords, searchKeywords]);

  useEffect(() => {
    if (footerClick === FooterClick.CLOSE) closeDrawer();
    else if (footerClick === FooterClick.SEARCH) closeDrawer(true);
    setFooterClick(FooterClick.NONE);
  }, [closeDrawer, footerClick]);

  const disableSearch =
    localSearchKeywords.filter((item) => item.active).length === 0;

  return (
    <Modal
      className=""
      title="Filter Search Keywords"
      visible={visible}
      footer={
        <SearchOccurrencesFilterFooter
          setFooterClick={setFooterClick}
          disableSearch={disableSearch}
        />
      }
      closable={false}
      width={1000}
    >
      <div className="modal-content">
        <div className="flex gp-10 modal-btn-group">
          {localSearchKeywords.map(({ key, active }, i) => (
            <Button
              key={`occurence-drawer-${i}`}
              className={active ? 'active' : ''}
              onClick={() => handleOptionClick(i)}
            >
              {key}
            </Button>
          ))}
        </div>
      </div>
    </Modal>
  );
};
