import Table, { ColumnsType, TableProps } from "antd/es/table";
import React, { useEffect, useMemo } from "react";
import { useFetchLogs } from "../../hooks/useFetchLogs";
import { AppPagination } from "../../components";
import { getLocalDateTimeStringFromISO } from "../../utils";
import { LogsFilter } from "./logs-container";
import { Button } from "antd";
import "./log-container.scss";

interface ILogsDataType {
  key: string;
  message: string;
  containerNmae: string;
  time: string;
}

const COLUMNS: ColumnsType<ILogsDataType> = [
  { title: "Message", dataIndex: "message" },
  { title: "Container Name", dataIndex: "containerNmae", sorter: true },
  { title: "Time", dataIndex: "time", sorter: true },
];

const DATA: ILog[] = [
  {
    id: 1,
    message: "Log entry 1",
    containerName: "Container A",
    time: "2023-07-06T10:30:00",
  },
  {
    id: 2,
    message: "Log entry 2",
    containerName: "Container B",
    time: "2023-07-06T11:15:00",
  },
  {
    id: 3,
    message: "Log entry 3",
    containerName: "Container C",
    time: "2023-07-06T12:00:00",
  },
  {
    id: 4,
    message: "Log entry 4",
    containerName: "Container D",
    time: "2023-07-06T12:00:00",
  },
  {
    id: 5,
    message: "Log entry 5",
    containerName: "Container E",
    time: "2023-07-06T12:00:00",
  },
  {
    id: 6,
    message: "Log entry 6",
    containerName: "Container F",
    time: "2023-07-06T12:00:00",
  },
  {
    id: 7,
    message: "Log entry 7",
    containerName: "Container G",
    time: "2023-07-06T12:00:00",
  },
  {
    id: 8,
    message: "Log entry 8",
    containerName: "Container H",
    time: "2023-07-06T12:00:00",
  },
  {
    id: 9,
    message: "Log entry 9",
    containerName: "Container I",
    time: "2023-07-06T12:00:00",
  },
  {
    id: 10,
    message: "Log entry 10",
    containerName: "Container J",
    time: "2023-07-06T12:00:00",
  },
];

export const LogsContainer = () => {
  const {
    paginationObj,
    sortFilter,
    searchFilter,
    fetchLogs,
    isLogsLoading,
    logs,
    totalLogs,
    setLogs,
    setTotalLogs,
  } = useFetchLogs();
  const { onPaginationChange, page, pageSize, pageSizeOptions } = paginationObj;
  const { setSort, sort } = sortFilter;
  const { onSearchChange, searchKeyword } = searchFilter;

  const onTableChange: TableProps<ILogsDataType>["onChange"] = (pagination, filters, sorter) => {
    setSort(sorter);
  };

  // useEffect(() => {
  //   fetchLogs();
  // }, [fetchLogs]);

  useEffect(() => {
    setLogs(DATA);
    setTotalLogs(100);
  }, [setLogs, setTotalLogs]);

  const dataSource: ILogsDataType[] = useMemo(
    () =>
      logs.map((log) => ({
        key: log.id.toString(),
        message: log.message,
        containerNmae: log.containerName,
        time: getLocalDateTimeStringFromISO(log.time),
      })),
    [logs]
  );

  return (
    <div>
      <div className="flex jcsb aic gp m-b">
        <LogsFilter
          {...{
            onSearchChange,
            searchKeyword,
          }}
        />
        <Button className="fill">Download</Button>
      </div>
      <AppPagination
      className=" tr m-b"
        {...{
          showSizeChanger: true,
          current: page,
          total: totalLogs,
          pageSize,
          pageSizeOptions,
          onChange: onPaginationChange,
        }}
      />
      <Table
      className="logs-table"
        columns={COLUMNS}
        dataSource={dataSource}
        onChange={onTableChange}
        loading={isLogsLoading}
        pagination={false}
      />
    </div>
  );
};
