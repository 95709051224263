export const getObjectKeys = (obj: any) => {
  const propertyHaveValue = [];
  const propertyHaveNoValue = [];

  for (const propName in obj) {
    if (obj[propName] === "" || obj[propName] === undefined || obj[propName] === null) {
      propertyHaveNoValue.push(propName);
    } else {
      propertyHaveValue.push(propName);
    }
  }
  return propertyHaveValue.concat(propertyHaveNoValue);
};

export function isEmptyObject(obj: any) {
  try {
    if (typeof obj === "undefined" || obj === null || Object.keys(obj).length == 0) {
      return true;
    }
    return false;
  } catch (e) {
    return true;
  }
}

export const isEmptyObjectKeys = (object: any) => {
  return Object.keys(object).every((x) => x === null || x === "");
};
