import React from "react";
import { IconsProps } from "./Icons";

export const MenuIcon = ({ className, fill, width, height, viewBox }: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : "0 0 15 15"}
      width={width ? width : "15"}
      height={height ? height : "15"}
      fill={fill ? fill : "none"}
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M7.66663 0.666672C5.81744 0.690254 4.05063 1.43532 2.74296 2.743C1.43528 4.05068 0.690208 5.81749 0.666626 7.66667C0.67527 8.74391 0.93243 9.80462 1.41808 10.7662C1.90374 11.7278 2.60478 12.5643 3.46663 13.2107V13.2667H3.53663C4.72185 14.1749 6.17342 14.6671 7.66663 14.6671C9.15983 14.6671 10.6114 14.1749 11.7966 13.2667H11.8666V13.2107C12.7285 12.5643 13.4295 11.7278 13.9152 10.7662C14.4008 9.80462 14.658 8.74391 14.6666 7.66667C14.643 5.81749 13.898 4.05068 12.5903 2.743C11.2826 1.43532 9.51581 0.690254 7.66663 0.666672ZM4.91563 12.5177C5.01782 12.0497 5.27693 11.6307 5.64998 11.3302C6.02304 11.0297 6.48761 10.8658 6.96663 10.8657H8.36663C8.84564 10.8658 9.31021 11.0297 9.68327 11.3302C10.0563 11.6307 10.3154 12.0497 10.4176 12.5177C9.58383 13.0082 8.63403 13.2669 7.66663 13.2669C6.69922 13.2669 5.74942 13.0082 4.91563 12.5177ZM11.5936 11.6147C11.3274 10.9783 10.879 10.4349 10.3049 10.0525C9.73074 9.67018 9.05642 9.46602 8.36663 9.46567H6.96663C6.27683 9.46602 5.60252 9.67018 5.02838 10.0525C4.45424 10.4349 4.00589 10.9783 3.73963 11.6147C3.21509 11.0988 2.79736 10.4846 2.5103 9.80714C2.22323 9.12972 2.07247 8.40238 2.06663 7.66667C2.08478 6.1871 2.68061 4.77324 3.7269 3.72695C4.7732 2.68066 6.18705 2.08483 7.66663 2.06667C9.1462 2.08483 10.5601 2.68066 11.6063 3.72695C12.6526 4.77324 13.2485 6.1871 13.2666 7.66667C13.2608 8.40238 13.11 9.12972 12.823 9.80714C12.5359 10.4846 12.1182 11.0988 11.5936 11.6147Z"
        fill="currentColor"
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M7.66665 3.46667C7.29659 3.45805 6.92863 3.52458 6.58502 3.66224C6.24141 3.7999 5.9293 4.00583 5.66756 4.26757C5.40582 4.52931 5.19989 4.84143 5.06222 5.18504C4.92456 5.52865 4.85803 5.89661 4.86665 6.26667C4.85803 6.63672 4.92456 7.00469 5.06222 7.3483C5.19989 7.6919 5.40582 8.00402 5.66756 8.26576C5.9293 8.5275 6.24141 8.73343 6.58502 8.87109C6.92863 9.00875 7.29659 9.07529 7.66665 9.06667C8.03671 9.07529 8.40467 9.00875 8.74828 8.87109C9.09189 8.73343 9.404 8.5275 9.66574 8.26576C9.92749 8.00402 10.1334 7.6919 10.2711 7.3483C10.4087 7.00469 10.4753 6.63672 10.4667 6.26667C10.4753 5.89661 10.4087 5.52865 10.2711 5.18504C10.1334 4.84143 9.92749 4.52931 9.66574 4.26757C9.404 4.00583 9.09189 3.7999 8.74828 3.66224C8.40467 3.52458 8.03671 3.45805 7.66665 3.46667ZM7.66665 7.66667C7.48042 7.67567 7.29436 7.64562 7.12042 7.57845C6.94649 7.51128 6.78853 7.40847 6.65669 7.27663C6.52485 7.14479 6.42204 6.98683 6.35487 6.81289C6.2877 6.63896 6.25765 6.4529 6.26665 6.26667C6.25765 6.08043 6.2877 5.89437 6.35487 5.72044C6.42204 5.54651 6.52485 5.38855 6.65669 5.2567C6.78853 5.12486 6.94649 5.02205 7.12042 4.95488C7.29436 4.88771 7.48042 4.85766 7.66665 4.86667C7.85289 4.85766 8.03895 4.88771 8.21288 4.95488C8.38681 5.02205 8.54477 5.12486 8.67661 5.2567C8.80845 5.38855 8.91126 5.54651 8.97843 5.72044C9.0456 5.89437 9.07565 6.08043 9.06665 6.26667C9.07565 6.4529 9.0456 6.63896 8.97843 6.81289C8.91126 6.98683 8.80845 7.14479 8.67661 7.27663C8.54477 7.40847 8.38681 7.51128 8.21288 7.57845C8.03895 7.64562 7.85289 7.67567 7.66665 7.66667Z"
        fill="currentColor"
      />
    </svg>
  );
};
