export const calculateTimeAgo = (targetDate: string | number | Date) => {
  if(!targetDate) {
    return null;
  }
  const currentDate = new Date();
  const targetDateTime = new Date(targetDate);
  const timeDifference = currentDate.getTime() - targetDateTime.getTime();
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));

  if (daysDifference === 0) {
    if (hoursDifference === 1) {
      return "1 hour ago";
    } else {
      return `${hoursDifference} hours ago`;
    }
  } else if (daysDifference === 1) {
    return "1 day ago";
  } else {
    return `${daysDifference} days ago`;
  }
};
