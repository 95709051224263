import React, { useState } from "react";
import { autonomizeFullLogo, autonomizeLogo } from "../../../assets/images";
import { SketchPicker, ColorResult } from "react-color";
import { CloseCircleOutlined } from "@ant-design/icons";
import { ColorConfigurationSetting } from "./ColorConfigurationSetting";
import { LOGIN_LINK } from "../../../utils";
import "./theme-configuration.scss";

export const BrandingAndLogo = () => {
  const [currentColor, setCurrentColor] = useState("#FFFFFF");
  const [showColorPicker, setShowColorPicker] = useState(false);

  const handleColorChange = (color: ColorResult) => {
    setCurrentColor(color.hex);
  };

  const handleColorClick = () => {
    setShowColorPicker(!showColorPicker);
  };

  return (
    <div className="theme-config-card">
      <h4>Branding & Logo</h4>
      <div className="theme-config-card-container branding-logo">
        <div>
          <h5>Logo</h5>
          <div className="theme-config-card-img flex jcc">
            <img src={autonomizeFullLogo} alt="autonomize-logo" />
          </div>
          <a>Upload Logo</a>
        </div>
        <div>
          <h5>Logo Icon</h5>
          <div className="theme-config-card-img flex jcc">
            <img src={autonomizeLogo} alt="autonomize-logo" />
          </div>
          <a>Upload Logo</a>
        </div>
        <div>
          <h5>{`Login Keyword (Product Name)`}</h5>
          <div className="theme-config-card-img flex jcc">
            <img src={autonomizeLogo} alt="autonomize-logo" />
          </div>
          <a className="m-b">Upload Logo</a>
          <div className="pr theme-config-card-container-item">
            <p>Text Color</p>
            <div className="cursor-pointer flex aic gp-10" onClick={handleColorClick}>
              <div
                style={{
                  backgroundColor: currentColor,
                  width: "20px",
                  height: "20px",
                  cursor: "pointer",
                  border: "1px solid #eee",
                  borderRadius: "2px,",
                }}
              ></div>
              {currentColor}
            </div>
            {showColorPicker && (
              <div className="pa tr sketch-picker">
                <CloseCircleOutlined onClick={handleColorClick} />
                <SketchPicker color={currentColor} onChange={handleColorChange} />
              </div>
            )}
          </div>
        </div>
        <div>
          <h5 className="sub-heading">Login Link</h5>
          {LOGIN_LINK.map((item, index) => (
            <ColorConfigurationSetting key={index} colorConfiguration={item} />
          ))}
        </div>
      </div>
    </div>
  );
};
