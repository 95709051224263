import React from "react";

interface Props {
  copilotName?: string;
  totalTokens: string;
  completionTokens: string;
  promptTokens: string;
}

export const TokenOverview = (props: Props) => {
  const { totalTokens, completionTokens, promptTokens } = props;

  return (
    <div className="ant-card" style={{ marginBottom: "2em" }}>
      <h2 style={{ marginLeft: 0 }}>Overview</h2>
      <div className="task-card-grid" style={{ marginTop: "2em" }}>
        <div style={{ display: "flex" }}>
          <p style={{ fontFamily: "Font-Semibold" }}>Total Tokens:&nbsp;</p>
          <p>{totalTokens}</p>
        </div>
        <div style={{ display: "flex" }}>
          <p style={{ fontFamily: "Font-Semibold" }}>Total Completion Tokens:&nbsp;</p>
          <p>{completionTokens}</p>
        </div>
        <div style={{ display: "flex" }}>
          <p style={{ fontFamily: "Font-Semibold" }}>Total Prompt Tokens:&nbsp;</p>
          <p>{promptTokens}</p>
        </div>
      </div>
    </div>
  );
};
