import React, { useEffect, useState } from "react";
import { EvidencePageResponseInfo, PegaPageDataSummary, evidenceViewerService } from "../../../../api";
import { EvidenceViewerDefault } from "../EvidenceContentDefault";
import { useFetchEvidenceViewer } from "../../../../hooks";
import { ExtractionOutput } from "../ExtractionOutput";
import Keywords from "../../../copilot-details/copilots-details-container/keywords/Keywords";

interface Props {
  pageInfo: EvidencePageResponseInfo;
  searchKeywords: string;
  selectedLegends: string[];
  loading: boolean;
  pegaPageInfo: PegaPageDataSummary;
}

export const EvidenceContentPegaOCR = ({
  pageInfo,
  searchKeywords,
  selectedLegends,
  loading,
  pegaPageInfo
}: Props) => {
  return (
    <>
      <ExtractionOutput {...{ pageInfo, searchKeywords, selectedLegends, loading, pegaPageInfo }} />
      <Keywords />
    </>
  );
};
