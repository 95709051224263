import { Table } from 'antd';
import React, { useEffect, useState } from 'react'
import { SuccessIcon } from '../../../assets/icons';
import { ProcessingReportData } from './constants';
import qaHistory from './qa_history.json'
import moment from 'moment';
import { IApplication } from '../../../utils';
import { DatePicker } from "../../../components";

interface CleanedQaObject {
  id: number;
  question: string;
  answer: string;
  isDeleted: string;
  createdAt: string;
  updatedAt: string;
  createdBy: number;
  updatedBy: number;
  qa_id: string;
}

const cleanedQAHistory: CleanedQaObject[] = qaHistory.map((item) => {
  const cleanedItem: CleanedQaObject = {
    id: item.id,
    question: JSON.parse(item.question).q,
    answer: JSON.parse(item.answer).answer,
    isDeleted: item.isDeleted,
    createdAt: item.createdAt,
    updatedAt: item.updatedAt,
    createdBy: item.createdBy,
    updatedBy: item.updatedBy,
    qa_id: item.qa_id,
  };
  return cleanedItem;
});

const getColumns = () => [
  {
    title: "Question",
    key: "question",
    dataIndex: "question",
  },
  {
    title: "Answer",
    key: "answer",
    dataIndex: "answer",
  },
  {
    title: "Created Date",
    key: "createdAt",
    dataIndex: "createdAt",
  }
];

const QAHistory = () => {
  const defaultFromDate = '2000-01-01';
  const defaultToDate = moment();
  const [fromDate, setFromDate] = useState(moment(defaultFromDate, 'YYYY-MM-DD'));
  const [toDate, setToDate] = useState(defaultToDate);
  const [filteredReportData, setFilteredReportData] = useState(cleanedQAHistory);
  useEffect(() => {
    const updatedReportData = cleanedQAHistory.filter((data) => {
      return moment(data['createdAt'], 'YYYY-MM-DD').isBetween(fromDate, toDate, 'day', '[]')
    }
    )
    setFilteredReportData(updatedReportData);
  }, [fromDate, toDate])
  return (
    <div>
      <div style={{ display: 'flex', marginBottom: '2em' }}>
        <p style={{marginBottom: 'auto', marginTop: 'auto'}}>From: </p>
        <DatePicker defaultValue={fromDate} onChange={(value) => value ? setFromDate(value): moment(defaultFromDate, 'YYYY-MM-DD')} style={{marginBottom: 'auto', marginTop: 'auto', marginLeft: '1em', marginRight: '1em'}}></DatePicker>
        <p style={{marginBottom: 'auto', marginTop: 'auto', marginLeft: '2em'}}>To: </p>
        <DatePicker defaultValue={toDate} onChange={(value) => value ? setToDate(value): defaultToDate} style={{marginBottom: 'auto', marginTop: 'auto', marginLeft: '1em', marginRight: '1em'}}></DatePicker>
      </div>
      <Table
        className="table"
        columns={getColumns()}
        rowClassName={"cursor-pointer"}
        dataSource={filteredReportData}
        pagination={false}
      />
    </div>
  );
}

export default QAHistory