import React, { useEffect, useState } from "react";
import { EvidencePageResponseInfo, PegaPageDataSummary } from "../../../../api";
import { EvidenceContentJSON } from "..";
import { ENHANCED_VIEW_TABS_PEGA } from "../../EvidenceViewerContainer";
import { Loading } from "../../../../components";
import "../evidence-content.scss";
import { isEmptyObject } from "../../../../utils";
import EvidenceContentSummaryAng from "./EvidenceContentSummaryAng";

interface Props {
  pageInfo: any;
  loading: boolean;
  selectedTab: string;
  searchKeywords: string;
  selectedLegends: string[];
  fileId: string;
}

export const EvidenceContentAng = ({
  pageInfo,
  loading,
  selectedTab,
  searchKeywords,
  selectedLegends,
  fileId,
}: Props) => {
  const [component, setComponent] = useState(<></>);
  useEffect(() => {
    if (!isEmptyObject(pageInfo)) {
      let res = <></>;
      switch (selectedTab) {
        case ENHANCED_VIEW_TABS_PEGA.SUMMARY:
          res = <EvidenceContentSummaryAng pageInfo={pageInfo} />;
          break;
      }
      setComponent(res);
    }
  }, [pageInfo, searchKeywords, selectedLegends, selectedTab]);

  return (
    <>
      {isEmptyObject(pageInfo) || loading ? (
        <Loading />
      ) : (
        <div className="e-content">{component}</div>
      )}
    </>
  );
};
