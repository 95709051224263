import { PayloadAction, createSlice } from "@reduxjs/toolkit";
interface IRBACSliceInitialState {
  roleIdToViewPermission: number;
}

export const rbacInitialState: IRBACSliceInitialState = {
  roleIdToViewPermission: -1,
};

export const rbacSlice = createSlice({
  name: "rbac",
  initialState: rbacInitialState,
  reducers: {
    setRoleId: (state: IRBACSliceInitialState, { payload }: PayloadAction<number>) => {
      state.roleIdToViewPermission = payload;
    },
  },
});

export const { setRoleId } = rbacSlice.actions;
export default rbacSlice.reducer;
