import { apiClient } from "./apiClient";

const getDeployments = async (reqObj: IGetDeploymentsRequest) => {
  console.log(reqObj);
  const res = await apiClient.get<IGetDeploymentsResponse>("");
  return res;
};
export const DeploymentService = {
  getDeployments,
};
