import React from "react";
import { IconsProps } from "./Icons";

export const MLIcon = ({ className, fill, width, height, viewBox }: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : "0 0 15 16"}
      width={width ? width : "15"}
      height={height ? height : "16"}
      fill={fill ? fill : "none"}
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M14 12V11H12.9495C12.8851 10.687 12.7608 10.3894 12.5835 10.1235L13.3285 9.3785L12.6215 8.6715L11.8765 9.4165C11.6106 9.23918 11.313 9.1149 11 9.0505V8H10V9.0505C9.68699 9.1149 9.38936 9.23918 9.1235 9.4165L8.3785 8.6715L7.6715 9.3785L8.4165 10.1235C8.23918 10.3894 8.1149 10.687 8.0505 11H7V12H8.0505C8.1155 12.3185 8.2425 12.6145 8.4165 12.8765L7.6715 13.6215L8.3785 14.3285L9.1235 13.5835C9.38936 13.7608 9.68699 13.8851 10 13.9495V15H11V13.9495C11.313 13.8851 11.6106 13.7608 11.8765 13.5835L12.6215 14.3285L13.3285 13.6215L12.5835 12.8765C12.7608 12.6106 12.8851 12.313 12.9495 12H14ZM10.5 13C9.673 13 9 12.327 9 11.5C9 10.673 9.673 10 10.5 10C11.327 10 12 10.673 12 11.5C12 12.327 11.327 13 10.5 13ZM5 3H7V4H5V3Z"
        fill="currentColor"
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M2 14V2H10V6.5H11V2C11 1.4485 10.5515 1 10 1H2C1.4485 1 1 1.4485 1 2V14C1 14.5515 1.4485 15 2 15H6V14H2Z"
        fill="currentColor"
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M14 12V11H12.9495C12.8851 10.687 12.7608 10.3894 12.5835 10.1235L13.3285 9.3785L12.6215 8.6715L11.8765 9.4165C11.6106 9.23918 11.313 9.1149 11 9.0505V8H10V9.0505C9.68699 9.1149 9.38936 9.23918 9.1235 9.4165L8.3785 8.6715L7.6715 9.3785L8.4165 10.1235C8.23918 10.3894 8.1149 10.687 8.0505 11H7V12H8.0505C8.1155 12.3185 8.2425 12.6145 8.4165 12.8765L7.6715 13.6215L8.3785 14.3285L9.1235 13.5835C9.38936 13.7608 9.68699 13.8851 10 13.9495V15H11V13.9495C11.313 13.8851 11.6106 13.7608 11.8765 13.5835L12.6215 14.3285L13.3285 13.6215L12.5835 12.8765C12.7608 12.6106 12.8851 12.313 12.9495 12H14ZM10.5 13C9.673 13 9 12.327 9 11.5C9 10.673 9.673 10 10.5 10C11.327 10 12 10.673 12 11.5C12 12.327 11.327 13 10.5 13ZM5 3H7V4H5V3Z"
        stroke="currentColor"
        strokeWidth="0.5"
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M2 14V2H10V6.5H11V2C11 1.4485 10.5515 1 10 1H2C1.4485 1 1 1.4485 1 2V14C1 14.5515 1.4485 15 2 15H6V14H2Z"
        stroke="currentColor"
        strokeWidth="0.5"
      />
    </svg>
  );
};
