import React from "react";
import { IconsProps } from "./Icons";

export const EditIcon = ({ className, fill, width, height, viewBox, onClick }: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : "0 0 16 16"}
      width={width ? width : "16"}
      height={height ? height : "16"}
      fill={fill ? fill : "currentColor"}
      onClick={onClick}
    >
      <g clipPath="url(#clip0_2319_911)">
        <path
          d="M15.0534 3.69778L12.4445 1.07556C12.2721 0.904029 12.0388 0.807739 11.7956 0.807739C11.5524 0.807739 11.3191 0.904029 11.1467 1.07556L1.8978 10.3111L1.05336 13.9556C1.02423 14.0888 1.02523 14.2268 1.05628 14.3596C1.08734 14.4924 1.14766 14.6166 1.23285 14.7231C1.31804 14.8296 1.42595 14.9157 1.54868 14.9751C1.67141 15.0346 1.80588 15.0658 1.94225 15.0667C2.00579 15.0731 2.06982 15.0731 2.13336 15.0667L5.8178 14.2222L15.0534 4.99556C15.2249 4.82316 15.3212 4.58986 15.3212 4.34667C15.3212 4.10347 15.2249 3.87018 15.0534 3.69778ZM5.37336 13.4222L1.92003 14.1467L2.70669 10.76L9.62669 3.86667L12.2934 6.53333L5.37336 13.4222ZM12.8889 5.88889L10.2222 3.22222L11.7689 1.68444L14.3911 4.35111L12.8889 5.88889Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2319_911">
          <rect width="16" height="16" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
};
