import React from "react";
import { EvidencePageResponseInfo } from "../../../api";
import { getEvidenceViewerMedicalEntitiesText } from ".";
import { Loading } from "../../../components";

interface EvidenceViewerDefaultProps {
  pageInfo: EvidencePageResponseInfo;
  searchKeywords: string;
  selectedLegends: string[];
  loading: boolean;
}

interface EvidenceViewerOCRProps {
  pageInfo: EvidencePageResponseInfo;
}

export const EvidenceViewerDefault = ({
  pageInfo,
  searchKeywords,
  selectedLegends,
  loading,
}: EvidenceViewerDefaultProps) => {
  return (
    <div className="e-text">
      <h2>{pageInfo.heading}</h2>
      {loading ? (
        <Loading />
      ) : Object.keys(pageInfo).length !== 0 ? (
        <div
          className="e-paragraph"
          dangerouslySetInnerHTML={{
            __html: getEvidenceViewerMedicalEntitiesText(
              pageInfo.content,
              pageInfo.medicalEntities.length > 0 ? pageInfo.medicalEntities : [],
              searchKeywords,
              selectedLegends
            ),
          }}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export const EvidenceViewerOCR = ({ pageInfo }: EvidenceViewerOCRProps) => {
  return (
    <div className="e-text">
      <h2>{pageInfo.heading}</h2>
      <div className="e-paragraph">{pageInfo.content}</div>
    </div>
  );
};
