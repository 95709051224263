import React from "react";
import { IconsProps } from "./Icons";

export const PaginationRightArrowIcon = ({
  className,
  fill,
  width,
  height,
  viewBox,
}: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : "0 0 10 14"}
      width={width ? width : "10"}
      height={height ? height : "14"}
      fill={fill ? fill : "none"}
    >
      <path
        d="M0 2L6.125 7L0 12L1.225 14L9.8 7L1.225 0L0 2Z"
        fill="currentColor"
      />
    </svg>
  );
};
