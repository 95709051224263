import { openNotificationWithIcon } from "./notification";
import { isEmptyObject } from "./objectOperations";

export const filteredArray = (
  array: Array<Record<string, unknown>>,
  valueToRemove: unknown,
  filterByKey: string
) => array.filter((item) => item[filterByKey] !== valueToRemove);

export const getExistingSearchParams = (searchParams: URLSearchParams) => {
  const params = [];
  for (const entry of searchParams.entries()) {
    params.push(entry);
  }
  const res: { [key: string]: string } = {};
  params.map(([key, value]) => {
    res[key] = value;
  });
  return res;
};

export const hexToRGBColor = (hex: string, alpha = 1) => {
  const match = hex.match(/\w\w/g);
  if (match && match.length) {
    const [r, g, b] = match.map((x) => parseInt(x, 16));
    return `rgba(${r},${g},${b},${alpha})`;
  }
  return `rgba(255,255,255,${alpha})`;
};

export const roundToNearestNumber = (value: number, roundToNumber: number) =>
  Math.ceil(value / roundToNumber) * roundToNumber;

export const mergeQueryStrings = (qsArray: string[]): string => {
  if (!qsArray.length) return "";
  return qsArray.join("&");
};

export const getQueryStringFromPaginationObj = (obj: IPagination): string => {
  let qs = "";
  if (isEmptyObject(obj)) return qs;
  const qsArray = Object.entries(obj).map(([key, value]) => `${key}=${value}`);
  qs = mergeQueryStrings(qsArray);
  return qs;
};

export const getOffsetFromPageAndPageSize = (page: number, pageSize: number) =>
  (page - 1) * pageSize;

export const appendTabToRouteQuery = (selectedTab: string) => {
  const queryParams = new URLSearchParams(window.location.search);
  queryParams.set("tab", selectedTab);
  const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
  window.history.replaceState(null, "", newUrl);
};

export const createTxtFile = (filename: string, content: string) => {
  const file = new File([content], `${filename}.txt`, { type: "text/plain" });
  return file;
};

export const formatLargeNumber = (number: number) => {
  if (number < 1000) {
    return number.toString(); // Numbers less than 1,000 are returned as-is.
  } else if (number < 1000000) {
    return (number / 1000).toFixed(1) + 'k'; // Thousands, one decimal place (e.g., 3.5k).
  } else if (number < 1000000000) {
    return (number / 1000000).toFixed(1) + 'M'; // Millions, one decimal place (e.g., 2.7M).
  } else {
    return (number / 1000000000).toFixed(1) + 'B'; // Billions, one decimal place (e.g., 1.2B).
  }
}

export const downloadPDF = async (url: string) => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const blob = await response.blob();
    const contentDisposition = response.headers.get("Content-Disposition");
    let filename = "download.pdf";

    if (contentDisposition) {
      const matches = /filename="?(.+)"?/.exec(contentDisposition);
      if (matches != null && matches[1]) {
        filename = matches[1];
      }
    } else {
      const urlParts = url.split("/");
      const lastPart = urlParts[urlParts.length - 1];
      filename = lastPart.split("?")[0];
    }

    const blobUrl = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = blobUrl;
    link.download = filename;
    link.click();
    URL.revokeObjectURL(blobUrl);
  } catch (error) {
    openNotificationWithIcon("", "Download Failed", "error");
  }
};
