import React from "react";

interface Props {
  colorConfiguration: IColorConfiguration;
}

export const ColorConfigurationSetting = ({ colorConfiguration }: Props) => {
  const { color, key } = colorConfiguration;
  return (
    <div className="theme-config-card-container-item">
      <p>{key}</p>
      <div className="flex gp-10 aic">
        <div className="color-box" style={{ backgroundColor: color}}></div>
        <p>{color}</p>
      </div>
    </div>
  );
};
