import React, { useLayoutEffect } from "react";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter, useLocation } from "react-router-dom";
import { AppRoutes } from "./navigation/AppRoutes";
import { Modal } from "./containers/modals/Modal";
import createStore from "./store";
import { useThemeProvider, ThemeContext } from "./hooks";
import "antd/dist/reset.css";
import "./assets/sass/main.scss";
import "./App.css";

export const store = createStore();
const persistor = persistStore(store);

const ScrollWrapper = ({ children }: any) => {
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

function App() {
  const { theme, toggleTheme } = useThemeProvider();

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <BrowserRouter>
          <ScrollWrapper>
            <ThemeContext.Provider value={{ theme, toggleTheme }}>
              <Modal />
              <AppRoutes />
            </ThemeContext.Provider>
          </ScrollWrapper>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
