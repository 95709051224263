import { SorterResult } from "antd/es/table/interface";
import { usePagination } from "./usePagination";
import { useCallback, useState } from "react";
import { openNotificationWithIcon } from "../utils";
import { LogsService } from "../api/log";

interface ColumnSort extends SorterResult<any> {
  sortOrder: "asc" | "desc" | null;
}

export const useFetchLogs = () => {
  const { page, onPaginationChange, pageSize, pageSizeOptions } = usePagination();
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState({} as ColumnSort);
  const [isLoading, setIsLoading] = useState(false);
  const [logs, setLogs] = useState([] as ILog[]);
  const [total, setTotal] = useState(0);

  const onSortChange = (newSort: any) => {
    if (newSort.order === "ascend") setSort({ ...newSort, sortOrder: "asc" });
    else if (newSort.order === "descend") setSort({ ...newSort, sortOrder: "desc" });
    else setSort({ ...newSort, sortOrder: null });
  };

  const onSearchChange = (str: string) => {
    onPaginationChange(1, pageSize);
    setSearch(str);
  };

  const getLogsFilterObject = useCallback(() => {
    let obj: IGetLogsRequest = {
      limit: pageSize,
      offset: page - 1,
      search: search,
    };
    if (sort.sortOrder && sort.field) {
      obj = { ...obj, sortField: sort.field.toString(), sortType: sort.sortOrder };
    }
    return { ...obj };
  }, [page, pageSize, search, sort.field, sort.sortOrder]);

  const fetchLogs = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await LogsService.getLogs(getLogsFilterObject());
      setLogs(data.data);
      setTotal(data.pagination.totalCount ? data.pagination.totalCount : 0);
    } catch (err: any) {
      openNotificationWithIcon("", err.response.data.message, "error");
    } finally {
      setIsLoading(false);
    }
  }, [getLogsFilterObject]);

  return {
    paginationObj: { onPaginationChange, pageSizeOptions, page, pageSize },
    sortFilter: { sort, setSort: onSortChange },
    searchFilter: { searchKeyword: search, onSearchChange },
    getLogsFilterObject,
    fetchLogs,
    isLogsLoading: isLoading,
    logs,
    totalLogs: total,
    setLogs,
    setTotalLogs: setTotal,
  };
};
