import React, { useCallback, useEffect, useMemo } from "react";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { SearchResultResponse } from "../../../api";
import { Loading } from "../../../components";
import { getExistingSearchParams } from "../../../utils";

interface DataType {
  key: React.Key;
  fileId: string;
  fileName: string;
  occurences: number;
}

const columns: ColumnsType<DataType> = [
  {
    title: "File Name",
    dataIndex: "fileName",
    ellipsis: {
      showTitle: true,
    },
  },
  {
    title: "No. of Occurences",
    dataIndex: "occurences",
  },
];

interface EvidenceViewerMultipleFileSearchResultProps {
  searchLoading: boolean;
  searchResult: SearchResultResponse;
  fileId: string;
}

export const EvidenceViewerMultipleFileSearchResult = ({
  searchLoading,
  searchResult,
  fileId,
}: EvidenceViewerMultipleFileSearchResultProps) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const occurences = useMemo(() => {
    let x = 0;
    searchResult.data && searchResult.data.forEach((item) => (x = item.occurences + x));
    return x;
  }, [searchResult.data]);

  const dataSource: DataType[] =
    searchResult.data &&
    searchResult.data.map((item, index) => ({
      key: `multiple-file-search-result-${index}`,
      fileId: item.fileId,
      fileName: item.fileName,
      occurences: item.occurences,
    }));

  const updateQueryParam = useCallback(
    (id: string) => {
      navigate(
        {
          search: createSearchParams({
            ...getExistingSearchParams(searchParams),
            fileId: id,
          }).toString(),
        },
        { replace: true }
      );
    },
    [navigate, searchParams]
  );

  const onClick = (selectedFileId: string) => {
    if (selectedFileId !== fileId) updateQueryParam(selectedFileId);
  };

  useEffect(() => {
    if (searchResult.data && searchResult.data.length > 0 && fileId === "") {
      updateQueryParam(searchResult.data[0].fileId);
    }
  }, [fileId, searchResult.data, updateQueryParam]);

  return (
    <>
      {!searchLoading && searchResult.data && searchResult.data.length > 1 && (
        <div className="multiple-search-result">
          <div className="multiple-search-result-title">
            <h5>Search Results</h5>
            <p className="">{`We have a total of ${occurences} occurrences across ${searchResult.data.length} files`}</p>
          </div>
          {searchLoading ? (
            <Loading tip="Seaching..." />
          ) : (
            <Table
              className="two-row"
              onRow={(record) => ({ onClick: () => onClick(record.fileId) })}
              rowClassName={(record) =>
                `cursor-pointer ${record.fileId === fileId ? "selected-row" : ""}`
              }
              columns={columns}
              dataSource={dataSource}
              loading={searchLoading}
              pagination={false}
            />
          )}
        </div>
      )}
    </>
  );
};
