import React from "react";
import { Outlet } from "react-router-dom";
import { Layout } from "antd";
import { AppHeader, AppMenu } from "../containers";
import { useAuthUser } from "../hooks";
import { PAGE_URL } from "../utils";

export const PrivateLayout = () => {
  const { accessToken } = useAuthUser();
  if (!accessToken) window.location.replace(PAGE_URL.LOGIN);
  // else window.location.replace(PAGE_URL.DASHBOARD)
  

  return (
    <Layout>
      <AppMenu />
      <Layout>
        <AppHeader />
        <Outlet />
        {/* <AppFooter /> */}
      </Layout>
    </Layout>
  );
};
