import { apiClient } from "./apiClient";

const getLogs = async (reqObj: IGetLogsRequest) => {
  console.log(reqObj);
  const res = await apiClient.get<IGetLogsResponse>("");
  return res;
};

export const LogsService = {
  getLogs,
};
