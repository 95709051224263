import React, { useEffect, useState } from "react";
import { TOKENS_USAGE_APPS } from "../../../utils";

interface Props {
  totalTokens: string;
  qaTokens?: string;
  feedbacks?: string;
  ocrCalls: string;
  documents: string;
  openAITokens: string;
  copilotName?: string;
}

export const Overview = (props: Props) => {
  const { totalTokens, qaTokens, feedbacks, ocrCalls, documents, openAITokens, copilotName } =
    props;

  return (
    <div className="ant-card" style={{ marginBottom: "2em" }}>
      <h2 style={{ marginLeft: 0 }}>Overview</h2>
      <div className="task-card-grid" style={{ marginTop: "2em" }}>
        <div style={{ display: "flex" }}>
          <p style={{ fontFamily: "Font-Semibold" }}>Total Tokens:&nbsp;</p>
          <p> {totalTokens}</p>
        </div>
        <div style={{ display: "flex" }}>
          <p style={{ fontFamily: "Font-Semibold" }}>Total OCR Calls:&nbsp;</p>
          <p> {ocrCalls}</p>
        </div>
        <div style={{ display: "flex" }}>
          <p style={{ fontFamily: "Font-Semibold" }}>
            Total Documents {copilotName == TOKENS_USAGE_APPS.PI ? "Ingested" : "Processed"}:&nbsp;
          </p>
          <p> {documents}</p>
        </div>
        <div style={{ display: "flex" }}>
          <p style={{ fontFamily: "Font-Semibold" }}>Total Open AI Tokens:&nbsp;</p>
          <p> {openAITokens}</p>
        </div>
        {copilotName == TOKENS_USAGE_APPS.PI && (
          <div style={{ display: "flex" }}>
            <p style={{ fontFamily: "Font-Semibold" }}>Total Questions Answered:&nbsp;</p>
            <p> {qaTokens}</p>
          </div>
        )}
        {copilotName == TOKENS_USAGE_APPS.PI && (
          <div style={{ display: "flex" }}>
            <p style={{ fontFamily: "Font-Semibold" }}>Total Feedbacks:&nbsp;</p>
            <p> {feedbacks}</p>
          </div>
        )}
      </div>
    </div>
  );
};
