import React from "react";
import { LeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import "./back-button.scss";

export const BackArrow = () => {
  const navigate = useNavigate();
  return <LeftOutlined onClick={() => navigate(-1)} />;
};

interface Props {
  className?: string;
}

export const BackButton = ({ className }: Props) => {
  const navigate = useNavigate();

  return (
    <div className={`back-icon ${className}`} onClick={() => navigate(-1)}>
      <a>
        <LeftOutlined />
        Back
      </a>
    </div>
  );
};
