import { PAGE_URL } from "./routes";

export enum TOKENS_USAGE_APPS {
    HEDIS = 'HEDIS Insights',
    PI = 'Payment Integrity Chat BOT',
    PEGA = 'PEGA Prior Auth'
}

interface TokensTabItems {
    link: string;
    title: string;
    tab: TOKENS_USAGE_APPS;
}

export const TOKENS_USAGE_TABS: TokensTabItems[] = [
    {
        link: PAGE_URL.DASHBOARD,
        title: "Hedis Insights",
        tab: TOKENS_USAGE_APPS.HEDIS
    },
    {
        link: PAGE_URL.TASKS,
        title: "Payment Integrity Chat BOT",
        tab: TOKENS_USAGE_APPS.PI
    },
    {
        link: PAGE_URL.APPLICATIONS,
        title: "PEGA Prior Auth",
        tab: TOKENS_USAGE_APPS.PEGA
    }
]