import React, { useEffect, useMemo, useState } from "react";
import { ViewPermissionsProps } from ".";
import { Checkbox, Modal, Table } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { RBACService } from "../../../api/rbac";
import {
  OPERATIONS,
  STATUS_CODE,
  getOffsetFromPageAndPageSize,
  openNotificationWithIcon,
} from "../../../utils";
import { AppPagination, Loading, NotAccessibleFallback } from "../../../components";
import { usePagination } from "../../../hooks";
import { ColumnsType } from "antd/es/table";
import "./permission.scss";

interface IPermissionDataType extends IPermission {
  key: number;
  resource: string;
  view: string;
  create: string;
  update: string;
  delete: string;
}

const COLUMNS: ColumnsType<IPermissionDataType> = [
  {
    title: "Resource",
    dataIndex: "resource",
    render: (value, record) => <p title={record.resource}>{record.title}</p>,
  },
  {
    title: "View",
    dataIndex: "view",
    render: (value, record) => (
      <div>
        <Checkbox disabled checked={record.operations.includes(OPERATIONS.VIEW)} />
      </div>
    ),
  },
  {
    title: "Create",
    dataIndex: "create",
    render: (value, record) => (
      <div>
        <Checkbox disabled checked={record.operations.includes(OPERATIONS.CREATE)} />
      </div>
    ),
  },
  {
    title: "Update",
    dataIndex: "update",
    render: (value, record) => (
      <div>
        <Checkbox disabled checked={record.operations.includes(OPERATIONS.UPDATE)} />
      </div>
    ),
  },
  {
    title: "Delete",
    dataIndex: "delete",
    render: (value, record) => (
      <div>
        <Checkbox disabled checked={record.operations.includes(OPERATIONS.DELETE)} />
      </div>
    ),
  },
];

const Permissions = ({ handleClose, roleId, visibility }: ViewPermissionsProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [permissions, setPermissions] = useState([] as IPermission[]);
  const { onPaginationChange, page, pageSize, pageSizeOptions } = usePagination();
  const [total, setTotal] = useState(0);
  const [isAuthorized, setIsAuthorized] = useState(true);

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        setIsLoading(true);
        const { data } = await RBACService.getPermissions(
          roleId,
          pageSize,
          getOffsetFromPageAndPageSize(page, pageSize)
        );
        setPermissions(data.data.permissions);
        setTotal(data.data.pagination.totalCount ? data.data.pagination.totalCount : 0);
      } catch (err: any) {
        if (err.response.status === STATUS_CODE.FORBIDDEN) {
          setIsAuthorized(false);
        } else {
          openNotificationWithIcon("", err.response.data.message, "error");
          handleClose();
        }
      } finally {
        setIsLoading(false);
      }
    };
    fetchPermissions();
  }, [handleClose, page, pageSize, roleId]);

  const dataSource: IPermissionDataType[] = useMemo(
    () =>
      permissions.map((permission, index) => ({
        key: index,
        create: "",
        view: "",
        delete: "",
        update: "",
        ...permission,
        resource: permission.resource,
      })),
    [permissions]
  );

  return (
    <Modal
      className="autonomizeModal"
      centered
      width={900}
      title="Delete MOdal"
      visible={visibility}
      footer={null}
      closeIcon={<CloseOutlined onClick={handleClose} />}
    >
      <div className="addClient-title">
        <h1>Permissions</h1>
      </div>
      <div className="addClient-content">
        {isLoading ? (
          <Loading />
        ) : !isAuthorized ? (
          <NotAccessibleFallback />
        ) : (
          <div>
            <AppPagination
              className="m-b tr"
              {...{
                showSizeChanger: true,
                current: page,
                total: total,
                pageSize,
                pageSizeOptions,
                onChange: onPaginationChange,
              }}
            />
            <Table
              className="permission-table"
              pagination={false}
              columns={COLUMNS}
              dataSource={dataSource}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default Permissions;
