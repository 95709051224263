import React from "react";
import { IApplication, servicesHealth } from "../../../utils";
import { MonitoringContainer, ServiceStatusContainer } from "../../";
import { SuccessIcon } from "../../../assets/icons";
import { SummaryDataAndGraphs } from "../../summary-data";

interface Props {
  application?: IApplication;
  copilotName?: string;
}

const getColumns = () => [
  {
    title: "Status",
    key: "status",
    dataIndex: "status",
    render: (text: any, record: any) => {
      if (record?.status === "success") return <SuccessIcon />;
    },
  },
  {
    title: "Service Name",
    key: "name",
    dataIndex: "name",
  },
  {
    title: "Description",
    key: "description",
    dataIndex: "description",
  },
  {
    title: "Availability",
    key: "availability",
    dataIndex: "availability",
    render: (text: any, record: any) => {
      return (
        <div style={{ display: "flex", gap: "5px" }}>
          {record.availability.map((item: any, index: number) => (
            <div key={index} style={{ background: "#D3D3D3", padding: "2px", borderRadius: "4px" }}>
              {item}
            </div>
          ))}
        </div>
      );
    },
  },
];

const ServiceStatus = (props: Props) => {
  const { application, copilotName } = props;
  return (
    <div>
      <SummaryDataAndGraphs copilotName={copilotName} />
      {/* {application && (
        <ServiceStatusContainer
          services={
            servicesHealth.data.filter((item) =>
              item.coPilots.includes(application.title)
            ) as unknown as IService[]
          }
        />
      )} */}
      {application && <MonitoringContainer copilotName={copilotName} />}
    </div>
  );
};

export default ServiceStatus;
