import { Dispatch } from "redux";
import { connect } from "react-redux";
import { closeModal, RootState } from "../../../store";
import { MODAL_TYPE } from "../../../utils";
import { ViewMedicalEntities } from "./ViewMedicalEntities";

type StateProps = {
  visibility: boolean;
  documentId: string;
  pageNumber: string;
};
type DispatchProps = {
  handleClose: () => void;
};
export type ViewMedicalEntitiesProps = StateProps & DispatchProps;

const mapStateToProps = (state: RootState): StateProps => ({
  visibility: state.ui.modal[MODAL_TYPE.VIEW_MEDICAL_ENTITIES],
  documentId: state.document.documentIDForPagesStatus,
  pageNumber: state.document.pageNumber,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  handleClose: () => {
    dispatch(closeModal(MODAL_TYPE.VIEW_MEDICAL_ENTITIES));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewMedicalEntities);
