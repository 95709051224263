import React from "react";
import { IconsProps } from "./Icons";

export const PaginationLeftArrowIcon = ({
  className,
  fill,
  width,
  height,
  viewBox,
}: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : "0 0 10 14"}
      width={width ? width : "10"}
      height={height ? height : "14"}
      fill={fill ? fill : "none"}
    >
      <path
        d="M9.7998 2L3.6748 7L9.7998 12L8.5748 14L-0.000195503 7L8.5748 0L9.7998 2Z"
        fill="currentColor"
      />
    </svg>
  );
};
