import { useCallback, useState } from "react";
import { LicenseManagementService } from "../api";
import { openNotificationWithIcon } from "../utils";
import { usePagination } from "./usePagination";
import { SorterResult } from "antd/es/table/interface";

interface ColumnSort extends SorterResult<any> {
  sortOrder: "asc" | "desc" | null;
}

export const useFetchLicenses = () => {
  const { page, onPaginationChange, pageSize, pageSizeOptions } = usePagination();
  const [sort, setSort] = useState({} as ColumnSort);
  const [isLoading, setIsLoading] = useState(false);
  const [licenses, setLicenses] = useState([] as ILicense[]);
  const [total, setTotal] = useState(0);

  const onSortChange = (newSort: any) => {
    if (newSort.order === "ascend") setSort({ ...newSort, sortOrder: "asc" });
    else if (newSort.order === "descend") setSort({ ...newSort, sortOrder: "desc" });
    else setSort({ ...newSort, sortOrder: null });
  };

  const getLicensesFilterObject = useCallback(() => {
    let obj: IGetLicensesRequest = {
      limit: pageSize,
      offset: page - 1,
    };
    if (sort.sortOrder && sort.field) {
      obj = { ...obj, sortField: sort.field.toString(), sortType: sort.sortOrder };
    }
    return { ...obj };
  }, [page, pageSize, sort.field, sort.sortOrder]);

  const fetchLicenses = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await LicenseManagementService.getLicenses(getLicensesFilterObject());
      setLicenses(data.data);
      setTotal(data.pagination.totalCount ? data.pagination.totalCount : 0);
    } catch (err: any) {
      openNotificationWithIcon("", err.response.data.message, "error");
    } finally {
      setIsLoading(false);
    }
  }, [getLicensesFilterObject]);

  return {
    paginationObj: { onPaginationChange, pageSizeOptions, page, pageSize },
    sortFilter: { sort, setSort: onSortChange },
    licensesLoading: isLoading,
    licenses,
    totalLicenses: total,
    getLicensesFilterObject,
    fetchLicenses,
    setLicensesLoading: setIsLoading,
    setTotalLicenses: setTotal,
    setLicenses,
  };
};
