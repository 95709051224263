import React from "react";
import { Form } from "antd";
import { MailIcon } from "../../../assets/icons";

interface Props {
  email: string;
}

export const ForgotPasswordSuccess = ({ email }: Props) => {
  return (
    <Form className="email-confirmation">
      <MailIcon />
      <h3>Please Check Your Email</h3>
      <p className="user-form-message">
        Reset password link has been sent to your registered email address {email}.
      </p>
    </Form>
  );
};
