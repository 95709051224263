import React, { useEffect, useState } from "react";
import { ViewMedicalEntitiesProps } from ".";
import { Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { STATUS_CODE, openNotificationWithIcon } from "../../../utils";
import { documentService } from "../../../api/document";
import { Loading, NotAccessibleFallback } from "../../../components";
import ReactJson from "react-json-view";

export const ViewMedicalEntities = ({
  documentId,
  handleClose,
  pageNumber,
  visibility,
}: ViewMedicalEntitiesProps) => {
  const [medicalEntities, setMedicalEntities] = useState([] as IMedicalEntity[]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(true);

  useEffect(() => {
    const fetchMedicalEntities = async () => {
      try {
        setIsLoading(true);
        const { data } = await documentService.getDocumentMedicalEntitiesByPage(
          documentId,
          pageNumber
        );
        setMedicalEntities(data.data.medicalEntities);
      } catch (err: any) {
        if (err.response.status === STATUS_CODE.FORBIDDEN) {
          setIsAuthorized(false);
        } else {
          openNotificationWithIcon("", err.response.data.message, "error");
          handleClose();
        }
      } finally {
        setIsLoading(false);
      }
    };
    fetchMedicalEntities();
  }, [documentId, pageNumber, handleClose]);

  return (
    <Modal
      className="autonomizeModal"
      centered
      width={1000}
      title="Delete MOdal"
      visible={visibility}
      footer={null}
      closeIcon={<CloseOutlined onClick={handleClose} />}
    >
      <div className="addClient-title">
        <h1>Medical Entities</h1>
      </div>
      <div className="addClient-content medical-entities">
        {isLoading ? (
          <Loading />
        ) : !isAuthorized ? (
          <NotAccessibleFallback />
        ) : (
          <ReactJson src={JSON.parse(JSON.stringify(medicalEntities))} enableClipboard />
        )}
      </div>
    </Modal>
  );
};
