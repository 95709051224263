import Table, { ColumnsType, TableProps } from "antd/es/table";
import React, { Dispatch, useCallback, useEffect, useMemo } from "react";
import { AnyAction } from "redux";
import { DeleteIcon, EditIcon } from "../../assets/icons";
import { useDispatch } from "react-redux";
import { useFetchLicenses, usePagination } from "../../hooks";
import {
  formatKeys,
  getLocalDateTimeStringFromISO,
  getOffsetFromPageAndPageSize,
} from "../../utils";
import { AppPagination } from "../../components";
import "./license-management.scss";

interface ILicensesDataType {
  key: string;
  licenseKey: string;
  version: string;
  licenseVersionKey: string;
  expiration: string;
  action: string;
}

const DATA: ILicense[] = [
  {
    id: 1,
    licenseKey: "ABCDEFGHIJKLMNOPQRSTUVWXY1",
    version: "1.0",
    key: "ABCDEFGHIJKLMNOPQRSTUVWXY1234567890",
    expiration: "2023-12-31",
    action: "read",
  },
  {
    id: 2,
    licenseKey: "BCDEFGHIJKLMNOPQRSTUVWXY12",
    version: "2.0",
    key: "BCDEFGHIJKLMNOPQRSTUVWXY1234567890",
    expiration: "2024-06-30",
    action: "write",
  },
  {
    id: 4,
    licenseKey: "CDEFGHIJKLMNOPQRSTUVWXY123",
    version: "3.0",
    key: "CDEFGHIJKLMNOPQRSTUVWXY1234567890",
    expiration: "2022-09-15",
    action: "execute",
  },
  {
    id: 5,
    licenseKey: "CDEFGHIJKLMNOPQRSTUVWXY123",
    version: "3.0",
    key: "CDEFGHIJKLMNOPQRSTUVWXY1234567890",
    expiration: "2022-09-15",
    action: "execute",
  },
  {
    id: 6,
    licenseKey: "CDEFGHIJKLMNOPQRSTUVWXY123",
    version: "3.0",
    key: "CDEFGHIJKLMNOPQRSTUVWXY1234567890",
    expiration: "2022-09-15",
    action: "execute",
  },
  {
    id: 7,
    licenseKey: "CDEFGHIJKLMNOPQRSTUVWXY123",
    version: "3.0",
    key: "CDEFGHIJKLMNOPQRSTUVWXY1234567890",
    expiration: "2022-09-15",
    action: "execute",
  },
  {
    id: 8,
    licenseKey: "CDEFGHIJKLMNOPQRSTUVWXY123",
    version: "3.0",
    key: "CDEFGHIJKLMNOPQRSTUVWXY1234567890",
    expiration: "2022-09-15",
    action: "execute",
  },
  {
    id: 9,
    licenseKey: "CDEFGHIJKLMNOPQRSTUVWXY123",
    version: "3.0",
    key: "CDEFGHIJKLMNOPQRSTUVWXY1234567890",
    expiration: "2022-09-15",
    action: "execute",
  },
  {
    id: 10,
    licenseKey: "CDEFGHIJKLMNOPQRSTUVWXY123",
    version: "3.0",
    key: "CDEFGHIJKLMNOPQRSTUVWXY1234567890",
    expiration: "2022-09-15",
    action: "execute",
  },
  {
    id: 11,
    licenseKey: "CDEFGHIJKLMNOPQRSTUVWXY123",
    version: "3.0",
    key: "CDEFGHIJKLMNOPQRSTUVWXY1234567890",
    expiration: "2022-09-15",
    action: "execute",
  },
  {
    id: 12,
    licenseKey: "CDEFGHIJKLMNOPQRSTUVWXY123",
    version: "3.0",
    key: "CDEFGHIJKLMNOPQRSTUVWXY1234567890",
    expiration: "2022-09-15",
    action: "execute",
  },
  {
    id: 13,
    licenseKey: "CDEFGHIJKLMNOPQRSTUVWXY123",
    version: "3.0",
    key: "CDEFGHIJKLMNOPQRSTUVWXY1234567890",
    expiration: "2022-09-15",
    action: "execute",
  },
  {
    id: 14,
    licenseKey: "CDEFGHIJKLMNOPQRSTUVWXY123",
    version: "3.0",
    key: "CDEFGHIJKLMNOPQRSTUVWXY1234567890",
    expiration: "2022-09-15",
    action: "execute",
  },
  {
    id: 15,
    licenseKey: "CDEFGHIJKLMNOPQRSTUVWXY123",
    version: "3.0",
    key: "CDEFGHIJKLMNOPQRSTUVWXY1234567890",
    expiration: "2022-09-15",
    action: "execute",
  },
  {
    id: 16,
    licenseKey: "CDEFGHIJKLMNOPQRSTUVWXY123",
    version: "3.0",
    key: "CDEFGHIJKLMNOPQRSTUVWXY1234567890",
    expiration: "2022-09-15",
    action: "execute",
  },
  {
    id: 17,
    licenseKey: "CDEFGHIJKLMNOPQRSTUVWXY123",
    version: "3.0",
    key: "CDEFGHIJKLMNOPQRSTUVWXY1234567890",
    expiration: "2022-09-15",
    action: "execute",
  },
  {
    id: 18,
    licenseKey: "STUVWXYZABCDEFGHIJKLMNOPQRS1",
    version: "5.1",
    key: "STUVWXYZABCDEFGHIJKLMNOPQRS1234567890",
    expiration: "2025-01-31",
    action: "read",
  },
  {
    id: 19,
    licenseKey: "TUVWXYZABCDEFGHIJKLMNOPQRS12",
    version: "2.1",
    key: "TUVWXYZABCDEFGHIJKLMNOPQRS1234567890",
    expiration: "2023-05-31",
    action: "write",
  },
  {
    id: 20,
    licenseKey: "UVWXYZABCDEFGHIJKLMNOPQRST1",
    version: "4.0",
    key: "UVWXYZABCDEFGHIJKLMNOPQRST1234567890",
    expiration: "2024-12-31",
    action: "execute",
  },
];

const getColumns = (dispatch: Dispatch<AnyAction>): ColumnsType<ILicensesDataType> => [
  {
    title: "License Key",
    dataIndex: "licenseKey",
  },
  {
    title: "Version",
    dataIndex: "version",
  },
  {
    title: "Key",
    dataIndex: "licenseVersionKey",
  },
  {
    title: "Expiration",
    dataIndex: "expiration",
    sorter: true,
  },
  {
    title: "Action",
    dataIndex: "action",
    render: (value, record) => (
      <div className="flex jcc gp link-color">
        <EditIcon />
        <DeleteIcon />
      </div>
    ),
  },
];

export const LicenseManagementContainer = () => {
  const dispatch = useDispatch();
  const {
    licenses,
    licensesLoading,
    fetchLicenses,
    setLicensesLoading,
    totalLicenses,
    setTotalLicenses,
    setLicenses,
    getLicensesFilterObject,
    paginationObj,
    sortFilter,
  } = useFetchLicenses();
  const { onPaginationChange, page, pageSize, pageSizeOptions } = paginationObj;
  const { setSort, sort } = sortFilter;

  const onTableChange: TableProps<ILicensesDataType>["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {
    setSort(sorter);
  };

  // useEffect(() => {
  //   getLicenses();
  // }, [getLicenses]);

  useEffect(() => {
    setLicenses(DATA);
    setTotalLicenses(100);
  }, [setLicenses, setTotalLicenses]);

  const dataSource: ILicensesDataType[] = useMemo(
    () =>
      licenses.map((license) => ({
        key: license.id.toString(),
        licenseKey: formatKeys(license.licenseKey),
        version: license.version,
        licenseVersionKey: formatKeys(license.key),
        expiration: getLocalDateTimeStringFromISO(license.expiration),
        action: "",
      })),
    [licenses]
  );

  return (
    <div>
      <AppPagination
        className="tr m-b"
        {...{
          showSizeChanger: true,
          current: page,
          total: totalLicenses,
          pageSize,
          pageSizeOptions,
          onChange: onPaginationChange,
        }}
      />

      <Table
        className="license-management-table"
        columns={getColumns(dispatch)}
        dataSource={dataSource}
        pagination={false}
        onChange={onTableChange}
        loading={licensesLoading}
      />
    </div>
  );
};
