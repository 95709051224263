import { Card } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IApplication, PAGE_URL } from "../../../utils";
import "../applications-container.scss";
import { SuccessIcon } from "../../../assets/icons";

interface ApplicationCardProps {
  application: IApplication;
  identifier: number;
}

export const ApplicationCard = (props: ApplicationCardProps) => {
  const navigate = useNavigate();
  const { application, identifier } = props;
  const { title, description, link, icon, isActive, id } = application;
  const [isHovered, setIsHovered] = useState(false);

  const handleNavigate = () => {
    const dataToSend = {
      identifier: identifier,
      application: { title, description, link, isActive },
    };
    navigate("/copilots/activate", { state: { data: dataToSend } });
  };

  const handleNavigateActive = () => {
    navigate(`${PAGE_URL.COPILOTS_DETAILS}/${application.id}`);
  };

  return (
    <Card
      className={
        isHovered ? "cursor-pointer application-card hover" : "cursor-pointer application-card"
      }
      // onClick={isActive ? handleNavigateActive : handleNavigate}
      onClick={handleNavigateActive}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        backgroundColor: isHovered ? "#5F3CAB" : "inherit",
        color: isHovered ? "white" : "inherit",
      }}
    >
      <div>{icon}</div>
      <div>
        <p className={isHovered ? "application-card-title-hovered" : "application-card-title"}>
          {title}
        </p>
        <p className={isHovered ? "application-card-description-hovered" : ""}>{description}</p>
      </div>
      <div className="flex jcsb">
        <div className="flex gp-10">
          <SuccessIcon fill={isActive ? "#4CAF50" : "rgb(71 85 105 / 30%)"} />
          <p style={isHovered ? { color: "white" } : { color: "#635f6a" }}>
            {isActive ? "Active" : "In Active"}
          </p>
        </div>
        <p style={isHovered ? { color: "white" } : { color: "#5F3CAB" }}>{"See Details  >"}</p>
      </div>
    </Card>
  );
};
