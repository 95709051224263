import React, { useEffect, useState } from "react";
import { DatePicker, Loading } from "../../components";
import { useFetchDocuments } from "../../hooks";
import { RangeValue } from "rc-picker/lib/interface";
import moment, { Moment } from "moment";
import { rangePresets } from "../copilot-details/copilots-details-container/helper";
import DonutChart from "./graphs/DonutChart";
import { ProcessingTrend } from "./graphs/ProcessingTrend";
import ApiCallStatistics from "./graphs/APICallStatistics";
import { APICallTrend } from "./graphs/APICallTrend";
import "./graphs/graphs.scss";
import { COPILOTS_NAME } from "../../utils";
import { ExtractionData } from "./graphs/ExtractionData";
import { documentService } from "../../api/document";
import { RootState, setSelectedDateRange } from "../../store";
import { useDispatch, useSelector } from "react-redux";

interface SummaryDataAndGraphsProps {
  copilotName?: string;
  overallSummary?: boolean;
  hideExtractionData?: boolean;
}

interface DocStatsType {
  totalDocsWithData: number;
  totalFields: number;
  totalExtractedFields: number;
  totalMissingFields: number;
  totalSrfPageFound: number;
  totalSuccess: number;
  totalFailed: number;
  totalInProgress: number;
  dateWiseExtractionData: { date: string; processed: number }[];
}

type TokenData = {
  date: string;
  token_count: {
    service_name: string;
    moduleName: string;
    token_count: number;
  }[];
}[];

interface ProcessingTrendProps {
  data: { date: string; processed: number }[];
}

export const SummaryDataAndGraphs = ({
  copilotName,
  overallSummary,
  hideExtractionData
}: SummaryDataAndGraphsProps) => {
  const { RangePicker } = DatePicker;
  const {
    setFromDate,
    setToDate,
    defaultFromDate,
    defaultToDate,
    searchTerm,
    setSearchTerm,
    defaultOverviewFromDate,
    overviewFromDate,
    setOverviewFromDate,
  } = useFetchDocuments();
  const dispatch = useDispatch();
  const [docStats, setDocStats] = useState<ChartStatsData[]>([]);
  const [chartData, setChartData] = useState({
    successes: 0,
    failures: 0,
  });
  // const [tokenCount, setTokenCount] = useState<TokenData | null>(null);
  const [tokenCount, setTokenCount] = useState<any>(null);
  const [extractionDataCount, setExtractionDataCount] = useState<ProcessingTrendProps | null>(null);
  const [isLoadingChartStats, setIsLoadingChartStats] = useState(false);
  const [isLoadingApiStats, setIsLoadingApiStats] = useState(false);

  const { fromDate: selectedFromDate, toDate: selectedToDate } = useSelector(
    (state: RootState) => state.ui.selectedDateRange
  );

  const onRangeChange = (dates: RangeValue<Moment>, dateStrings: string[]) => {
    if (dates) {
      dispatch(
        setSelectedDateRange({
          fromDate: dates[0]?.toString() ?? null,
          toDate: dates[1]?.toString() ?? null,
        })
      );
    } else {
      dispatch(
        setSelectedDateRange({
          fromDate: defaultOverviewFromDate?.toString() ?? null,
          toDate: defaultToDate?.toString() ?? null,
        })
      );
    }
  };

  const fetchApiStats = async () => {
    setIsLoadingApiStats(true);
    const apiData = await documentService.getDocumentApiStats(
      moment(selectedFromDate).startOf("day").format("YYYY-MM-DD HH:mm:ss"),
      moment(selectedToDate).endOf("day").format("YYYY-MM-DD HH:mm:ss"),
      copilotName
    );
    if (apiData.data && apiData.data.data) {
      const data = [];
      for (const date in apiData.data.data) {
        data.push({
          date,
          token_count: apiData.data.data[date],
        });
      }
      setTokenCount(data);
    } else {
      setTokenCount([]);
    }
    setIsLoadingApiStats(false);
  };

  const fetchChartStats = async () => {
    if (copilotName === COPILOTS_NAME.CCA || copilotName === COPILOTS_NAME.ANG_INSIGHTS) {
      setIsLoadingChartStats(true);
      try {
        const response = await documentService.getDocumentChartStatsCCAandANG(
          moment(selectedFromDate).startOf("day").format("YYYY-MM-DD HH:mm:ss"),
          moment(selectedToDate).endOf("day").format("YYYY-MM-DD HH:mm:ss"),
          copilotName
        );

        const chartStats = { successes: 0, failures: 0 };
        response.data.forEach((dayStat: ChartStatsData) => {
          if (dayStat.status === "SUCCEEDED") {
            chartStats.successes += dayStat.processed;
          } else if (dayStat.status === "FAILED") {
            chartStats.failures += dayStat.processed;
          }
        });

        setDocStats(response.data);
        setChartData(chartStats);
      } catch (err: any) {
        console.error("Error fetching chart stats:", err);
      } finally {
        setIsLoadingChartStats(false);
      }
      return;
    }

    setIsLoadingChartStats(true);
    try {
      const response = await documentService.getDocumentChartStats(
        moment(selectedFromDate).startOf("day").format("YYYY-MM-DD HH:mm:ss"),
        moment(selectedToDate).endOf("day").format("YYYY-MM-DD HH:mm:ss"),
        copilotName
      );

      setDocStats(response.data);

      const chartStats = { successes: 0, failures: 0 };
      response.data.forEach((dayStat: ChartStatsData) =>
        dayStat.status === "SUCCESS"
          ? (chartStats.successes += dayStat.processed)
          : (chartStats.failures += dayStat.processed)
      );

      setChartData(chartStats);
    } catch (err: any) {
      console.error("Error fetching chart stats:", err);
    } finally {
      setIsLoadingChartStats(false);
    }
  };

  useEffect(() => {
    fetchApiStats();
    fetchChartStats();
  }, [selectedFromDate, selectedToDate]);

  const renderDatePicker = () => (
    <div className="service-datePicker">
      <p className="service-datePicker-title">Select Date Range:</p>
      <RangePicker
        defaultValue={[
          selectedFromDate ? moment(selectedFromDate) : null,
          selectedToDate ? moment(selectedToDate) : null,
        ]}
        presets={rangePresets}
        onChange={onRangeChange}
        className="range-picker"
        allowClear={false}
        disabledDate={(current) => {
          return current && current.valueOf() > moment().endOf("day").valueOf();
        }}
      />
    </div>
  );

  const renderDonutChart = () => (
    <div className="donut-container">
      <h3 className="summary-title">
        {copilotName === "CCA" || copilotName === "A&G Insights"
          ? "Requests Processed"
          : "Documents Processed"}
      </h3>
      <p>
        {copilotName === "CCA" || copilotName === "A&G Insights"
          ? "Total requests processed with status success, failed"
          : "Total documents processed with status success, failed"}
      </p>
      <DonutChart
        donutData={[
          { type: "Success", value: chartData.successes },
          { type: "Failed", value: chartData.failures },
        ]}
      />
    </div>
  );

  const renderProcessingTrend = () => (
    <div className="processing-trend">
      <ProcessingTrend
        data={docStats}
        fromDate={defaultOverviewFromDate}
        toDate={defaultToDate}
        copilotName={copilotName}
      />
    </div>
  );

  const renderApiCallStatistics = () => (
    <div className={`summary-container other-copilots`}>
      <div style={{ flex: 1 }}>
        {tokenCount && <ApiCallStatistics tokenCount={tokenCount} copilotName={copilotName} />}
      </div>
      <div style={{ flex: 1 }}>
        {tokenCount && <APICallTrend data={{ tokenData: tokenCount }} />}
      </div>
    </div>
  );

  const renderLoading = (message: string) => (
    <div style={{ height: "200px", width: "50%" }}>
      <Loading tip={message} />
    </div>
  );

  const renderExtractionData = () => (
    <ExtractionData
      copilotName={copilotName || ""}
      overviewFromDate={
        selectedFromDate
          ? moment(selectedFromDate).startOf("day").format("YYYY-MM-DD HH:mm:ss")
          : moment().endOf("day").format("YYYY-MM-DD HH:mm:ss")
      }
      toDate={
        selectedToDate
          ? moment(selectedToDate).startOf("day").format("YYYY-MM-DD HH:mm:ss")
          : moment().endOf("day").format("YYYY-MM-DD HH:mm:ss")
      }
      chartData={chartData}
    />
  );

  return (
    <div className="wrapper">
      <div className="service-status">
        <h4 className="service-status-title">Insights</h4>
        <div className="service-horizontal"></div>
        {renderDatePicker()}
      </div>
      {(copilotName === COPILOTS_NAME.PEGA_PRIOR_AUTH ||
      copilotName === COPILOTS_NAME.HEDIS_INSIGHTS) && (!hideExtractionData) ? (
        renderExtractionData()
      ) : (
        <></>
      )}
      <div style={{ display: "flex", gap: "20px", justifyContent: "space-evenly" }}>
        {isLoadingChartStats
          ? renderLoading("Loading documents stats...")
          : docStats && (
              <div
                className={`summary-container ${
                  copilotName === "CCA" || copilotName === "A&G Insights"
                    ? "row-layout"
                    : "other-copilots"
                }`}
              >
                {renderDonutChart()}
                {renderProcessingTrend()}
              </div>
            )}
        {!(copilotName === "CCA" || copilotName === "A&G Insights") &&
          (isLoadingApiStats ? renderLoading("Loading API stats...") : renderApiCallStatistics())}
      </div>
    </div>
  );
};
