import React, { Dispatch, SetStateAction, useState } from "react";
import { Button } from "antd";
import { Loading } from "../../../components";
import { SearchOccurrence } from "../../../api";
import { SearchOccurrencesFilter } from "../../modals";
import { EditLineIcon } from "../../../assets/icons";
import "./evidence-viewer-container.scss";

interface SearchResultOccurrencesProps {
  currPage: number;
  setCurrPage: Dispatch<SetStateAction<number>>;
  searchKeywords: string | null;
  initialSearchKeywords: string | null;
  setSearchKeywords: Dispatch<SetStateAction<string | null>>;
  loading: boolean;
  occurencesList: SearchOccurrence[];
}

export const SearchResultOccurrences = (props: SearchResultOccurrencesProps) => {
  const {
    currPage,
    setCurrPage,
    loading,
    searchKeywords,
    setSearchKeywords,
    initialSearchKeywords,
    occurencesList,
  } = props;
  const [displayDrawer, setDisplayDrawer] = useState(false);

  const showDrawer = () => {
    setDisplayDrawer(true);
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="result-pdf">
          <div className="result-occurrences-title flex">
            {`Search Results (${occurencesList.length})`}
            &nbsp;
            {initialSearchKeywords && searchKeywords && (
              <div className="edit-icon flex gp-10 aic" onClick={showDrawer}>
                <EditLineIcon />
                <span>Filter</span>
              </div>
            )}
          </div>

          {displayDrawer && (
            <SearchOccurrencesFilter
              {...{
                modalState: { visible: displayDrawer, setVisible: setDisplayDrawer },
                searchKeywords,
                setSearchKeywords,
                initialSearchKeywords,
              }}
            />
          )}
          <div className="occurrences-group flex flex-column gp-10">
            {occurencesList.map((x, i) => (
              <Button
                key={`search-occurences-${i}`}
                className={`result-occurrences-content aic w-full ${
                  currPage === x.pageNumber ? "tab-active" : "tab-inactive"
                }`}
                onClick={() => {
                  setCurrPage(x.pageNumber);
                }}
              >
                <span className="result-occurrences-count flex-center">
                  {x.inOccured ? x.inOccured : 1}
                </span>
                <p>Results in Page No. {x.pageNumber}</p>
              </Button>
            ))}
          </div>
        </div>
      )}
    </>
  );
};
