import React, { useEffect, useState, useRef } from "react";
import { EvidencePageResponseInfo } from "../../../api";
import { Collapse } from "antd";
import { CollapseProps } from "antd/lib";
import { EvidenceContentJSON } from "./EvidenceContentJSON";
import { documentService } from "../../../api/document";
import { Loading } from "../../../components";

interface ExtractionOutputProps {
  pageInfo: EvidencePageResponseInfo;
  fileId: string;
  currPage: number;
  loading: boolean;
}

interface HedisMeasurementProps {
  pages: {
    measurements: any;
    ocr_text: string;
    page_number: number;
  }[];
  total_pages: number;
}

export const EvidenceContentJSONOutput = ({
  pageInfo,
  fileId,
  currPage,
  loading,
}: ExtractionOutputProps) => {
  const [hedisSummary, setHedisSummary] = useState();
  const [hedisMeasurements, setHedisMeasurements] = useState({} as HedisMeasurementProps);
  const [isLoading, setIsLoading] = useState(true);
  const isInitialRender = useRef(true);
  const prevPageNumberRef = useRef<number | null>(null);

  const fetchSummary = async () => {
    const { data } = await documentService.getHedisSummary(fileId);
    setHedisSummary(data.data);
    const hedisMeasurement = await documentService.getHedisMeasurement(fileId, currPage);
    setHedisMeasurements(hedisMeasurement.data.data);
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);

    if (
      isInitialRender.current ||
      prevPageNumberRef.current !== null ||
      prevPageNumberRef.current !== currPage
    ) {
      fetchSummary();
    }
    if (isInitialRender.current) {
      isInitialRender.current = false;
    }
    prevPageNumberRef.current = currPage;
  }, [currPage]);

  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: "Output",
      children: loading ? <Loading /> : <EvidenceContentJSON pageInfo={pageInfo} />,
    },
    {
      key: "2",
      label: "Summary",
      children: loading ? (
        <Loading />
      ) : isLoading ? (
        <Loading />
      ) : (
        <EvidenceContentJSON pageInfo={hedisSummary} />
      ),
    },
    {
      key: "3",
      label: "Measurements",
      children: loading ? (
        <Loading />
      ) : isLoading ? (
        <Loading />
      ) : (
        <EvidenceContentJSON pageInfo={hedisMeasurements} />
      ),
    },
  ];

  return (
    <div style={{ height: "100%" }}>
      <Collapse style={{ marginBottom: "20px" }} items={items} />
    </div>
  );
};
