import React, { useEffect, useState } from "react";
import {
  DOCUMENT_STATUS,
  getLocalDateTimeString,
  getLocalDateTimeStringFromISO,
  roundToNearestNumber,
} from "../../../utils";
import { Modal, Progress, Steps, Button, Table } from "antd";
import { CloseOutlined, ReloadOutlined } from "@ant-design/icons";
import { DocumentProcessingDetailStatusProps } from ".";
import moment from "moment";
import { documentService } from "../../../api/document";
import { Loading } from "../../../components";

const { Step } = Steps;

const getTotalSteps = () => {
  let count = 0;
  for (const item in DOCUMENT_STATUS) {
    if (isNaN(Number(item))) count++;
  }
  // remove error status
  return count - 1;
};

const RetryMessage = () => {
  return (
    <span className="re-try">
      <div className="ant-steps-item-tail"></div>
      <p>The uploading failed. Please retry.</p>
      <Button className="outline" icon={<ReloadOutlined />} disabled={true}>
        Retry
      </Button>
    </span>
  );
};

export const DocumentProcessingHedisStatus = ({
  visibility,
  documentDetail,
  handleClose,
}: DocumentProcessingDetailStatusProps) => {
  const [hedisStats, setHedisStats] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getHedisStatus = async () => {
    setIsLoading(true);
    const hedisStatuses = await documentService.getHedisStats(documentDetail.Id);
    setIsLoading(false);
    setHedisStats(
      hedisStatuses.map((item: any) => ({
        key: item.DocumentUUID,
        ...item,
        StartTime: getLocalDateTimeString(item.StartTime),
        EndTime: getLocalDateTimeString(item.EndTime),
      }))
    );
  };
  useEffect(() => {
    getHedisStatus();
  }, [visibility]);

  const nestedColumns = [
    {
      title: "Process",
      dataIndex: "Process",
      key: "Process",
    },
    {
      title: "Start Time",
      dataIndex: "StartTime",
      key: "StartTime",
    },
    {
      title: "End Time",
      dataIndex: "EndTime",
      key: "EndTime",
    },
  ];

  return (
    <Modal
      centered
      open={visibility}
      footer={null}
      title={"Detailed Logs"}
      width={"60%"}
      closeIcon={<CloseOutlined onClick={handleClose} style={{ color: "black" }} />}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <Table
          rowKey={documentDetail.DocumentUUID}
          rowClassName={"cursor-pointer"}
          columns={nestedColumns}
          dataSource={hedisStats}
          pagination={false}
          loading={isLoading}
        />
      )}
    </Modal>
  );
};
