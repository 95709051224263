import { useCallback, useState } from "react";

const PAGE_SIZE_OPTIONS = [10, 20, 50, 100];

export const usePagination = () => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE_OPTIONS[1]);

  const onChange = useCallback(
    (updatedPage: number, updatedPageSize: number) => {
      if (updatedPage !== page) setPage(updatedPage);
      if (updatedPageSize !== pageSize) setPageSize(updatedPageSize);
    },
    [page, pageSize]
  );

  return { page, pageSize, pageSizeOptions: PAGE_SIZE_OPTIONS, onPaginationChange: onChange };
};
