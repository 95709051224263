import React from "react";
import { IconsProps } from "./Icons";

export const APICallsIcon = ({ className, fill, width, height, viewBox, backGroundFill }: IconsProps) => {
  return (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="52" height="52" rx="6" fill={backGroundFill} />
      <path
        d="M23.2705 17.0958C23.763 16.9473 24.2914 16.972 24.7678 17.1657C25.2442 17.3594 25.64 17.7104 25.8891 18.1603L25.9724 18.328L26.7173 19.9833C26.9434 20.4857 27.0168 21.0435 26.9283 21.5873C26.8399 22.1311 26.5936 22.6369 26.2199 23.0418L26.0703 23.1904L24.8966 24.2853C24.685 24.4856 24.8437 25.2609 25.6089 26.5865C26.2976 27.7793 26.858 28.3363 27.1337 28.3667H27.1821L27.2417 28.3555L29.5485 27.6499C29.8586 27.5548 30.1894 27.5511 30.5015 27.6391C30.8136 27.7271 31.0938 27.9031 31.3085 28.1462L31.4109 28.2756L32.9379 30.3911C33.2372 30.8058 33.3863 31.31 33.3607 31.8207C33.3352 32.3315 33.1364 32.8183 32.7973 33.201L32.66 33.3427L32.0501 33.9211C31.5024 34.4398 30.8173 34.7903 30.0762 34.9309C29.3351 35.0716 28.5692 34.9964 27.8696 34.7145C25.6922 33.8367 23.7139 31.8315 21.9168 28.7189C20.1164 25.5985 19.368 22.8787 19.7023 20.5493C19.8034 19.8452 20.0933 19.1816 20.5413 18.629C20.9893 18.0764 21.5786 17.6555 22.2465 17.4109L22.4637 17.3389L23.2705 17.0958Z"
        fill={fill}
      />
    </svg>
  );
};
