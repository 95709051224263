import { Collapse } from "antd";
import React from "react";
import ReactJson from "react-json-view";

const { Panel } = Collapse;

interface PageInfo {
  data: {
    input_payload: string;
    output_payload: string | null;
    token_details: string;
  };
}

interface EvidenceContentSummaryAngProps {
  pageInfo: PageInfo;
}

const EvidenceContentSummaryAng: React.FC<EvidenceContentSummaryAngProps> = ({ pageInfo }) => {
  const { data } = pageInfo;
  const { input_payload, output_payload, token_details } = data;

  // Parse the JSON strings into objects
  const inputPayloadJson = JSON.parse(input_payload);
  const outputPayloadJson = output_payload ? output_payload : null;
  const tokenDetailsJson = JSON.parse(token_details);

  return (
    <Collapse>
      <Panel header="Input Payload" key="1">
        <div>
          <ReactJson src={inputPayloadJson} collapsed={1} />
        </div>
      </Panel>

      <Panel header="Output Payload" key="2">
        <div>{outputPayloadJson ? <div>{outputPayloadJson}</div> : "No output data available"}</div>
      </Panel>
      <Panel header="Token Details" key="3">
        <div>
          <ReactJson src={tokenDetailsJson} collapsed={1} />
        </div>
      </Panel>
    </Collapse>
  );
};

export default EvidenceContentSummaryAng;
