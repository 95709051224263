import React from 'react';
import { TIFFViewer } from './TiffViewerCore';

interface Props {
  tiff: any;
  pageInfo?: {
    width?: number;
    height?: number;
    scale?: number;
  };
  currPage: number;
  setCurrPage: (page: number) => void;
}

const TiffViewer = (props: Props) => {
  return (
    <TIFFViewer
      tiff={props.tiff}
      pageInfo={{ height: 700, width: 400 }}
      setCurrPage={props.setCurrPage}
      currPage={props.currPage}
    />
  );
};

export default TiffViewer;
