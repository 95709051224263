import { combineReducers } from "redux";

import uiReducer, { uiInitialState } from "./ui/slice";
import authUserReducer, { authUserInitialState } from "./auth-user/slice";
import documentReducer, { documentInitialState } from "./document/slice";
import rbacReducer, { rbacInitialState } from "./rbac/slice";

export const initialState = {
  ui: uiInitialState,
  authUser: authUserInitialState,
  document: documentInitialState,
  rbac: rbacInitialState,
};

export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    ui: uiReducer,
    authUser: authUserReducer,
    document: documentReducer,
    rbac: rbacReducer,
    ...injectedReducers,
  });

  return rootReducer;
}
