import { apiClient } from "./apiClient";

const getLicenses = async (reqObj: IGetLicensesRequest) => {
  console.log(reqObj);
  const res = await apiClient.get<IGetLicensesResponse>("");
  return res;
};

export const LicenseManagementService = {
  getLicenses,
};
