import React from "react";
import { LEFT_SIDEBAR, LOGIN_BACKGROUND, MODAL_HEADER } from "../../../utils";
import { ColorConfigurationSetting } from "./ColorConfigurationSetting";
import "./theme-configuration.scss";

export const GradientsAndBackgrounds = () => {
  return (
    <div className="theme-config-card">
      <h4>Gradients & Backgrounds</h4>
      <div className="theme-config-card-container">
        <div>
          <h5>Login Background</h5>
          {LOGIN_BACKGROUND.map((item, index) => (
            <ColorConfigurationSetting key={index} colorConfiguration={item} />
          ))}
        </div>
        <div>
          <h5>Left Sidebar</h5>
          {LEFT_SIDEBAR.map((item, index) => (
            <ColorConfigurationSetting key={index} colorConfiguration={item} />
          ))}
        </div>
        <div>
          <h5>Modal Header</h5>
          {MODAL_HEADER.map((item, index) => (
            <ColorConfigurationSetting key={index} colorConfiguration={item} />
          ))}
        </div>
      </div>
    </div>
  );
};
