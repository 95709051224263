import React from "react";
import { IconsProps } from "./Icons";

export const RBCAIcon = ({ className, fill, width, height, viewBox }: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : "0 0 15 15"}
      width={width ? width : "15"}
      height={height ? height : "15"}
      fill={fill ? fill : "currentColor"}
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M7.66699 0.666672C5.81781 0.690254 4.051 1.43532 2.74332 2.743C1.43564 4.05068 0.690575 5.81749 0.666992 7.66667C0.675636 8.74391 0.932797 9.80462 1.41845 10.7662C1.9041 11.7278 2.60515 12.5643 3.46699 13.2107V13.2667H3.53699C4.72222 14.1749 6.17379 14.6671 7.66699 14.6671C9.1602 14.6671 10.6118 14.1749 11.797 13.2667H11.867V13.2107C12.7288 12.5643 13.4299 11.7278 13.9155 10.7662C14.4012 9.80462 14.6583 8.74391 14.667 7.66667C14.6434 5.81749 13.8983 4.05068 12.5907 2.743C11.283 1.43532 9.51618 0.690254 7.66699 0.666672ZM4.91599 12.5177C5.01818 12.0497 5.2773 11.6307 5.65035 11.3302C6.02341 11.0297 6.48798 10.8658 6.96699 10.8657H8.36699C8.84601 10.8658 9.31058 11.0297 9.68363 11.3302C10.0567 11.6307 10.3158 12.0497 10.418 12.5177C9.5842 13.0082 8.63439 13.2669 7.66699 13.2669C6.69959 13.2669 5.74979 13.0082 4.91599 12.5177ZM11.594 11.6147C11.3277 10.9783 10.8794 10.4349 10.3052 10.0525C9.7311 9.67018 9.05679 9.46602 8.36699 9.46567H6.96699C6.2772 9.46602 5.60288 9.67018 5.02875 10.0525C4.45461 10.4349 4.00625 10.9783 3.73999 11.6147C3.21546 11.0988 2.79772 10.4846 2.51066 9.80714C2.2236 9.12972 2.07284 8.40238 2.06699 7.66667C2.08515 6.1871 2.68098 4.77324 3.72727 3.72695C4.77356 2.68066 6.18742 2.08483 7.66699 2.06667C9.14656 2.08483 10.5604 2.68066 11.6067 3.72695C12.653 4.77324 13.2488 6.1871 13.267 7.66667C13.2611 8.40238 13.1104 9.12972 12.8233 9.80714C12.5363 10.4846 12.1185 11.0988 11.594 11.6147Z"
        fill="currentColor"
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M7.66702 3.46667C7.29696 3.45805 6.929 3.52458 6.58539 3.66224C6.24178 3.7999 5.92967 4.00583 5.66792 4.26757C5.40618 4.52931 5.20025 4.84143 5.06259 5.18504C4.92493 5.52865 4.8584 5.89661 4.86702 6.26667C4.8584 6.63672 4.92493 7.00469 5.06259 7.3483C5.20025 7.6919 5.40618 8.00402 5.66792 8.26576C5.92967 8.5275 6.24178 8.73343 6.58539 8.87109C6.929 9.00875 7.29696 9.07529 7.66702 9.06667C8.03708 9.07529 8.40504 9.00875 8.74865 8.87109C9.09225 8.73343 9.40437 8.5275 9.66611 8.26576C9.92785 8.00402 10.1338 7.6919 10.2714 7.3483C10.4091 7.00469 10.4756 6.63672 10.467 6.26667C10.4756 5.89661 10.4091 5.52865 10.2714 5.18504C10.1338 4.84143 9.92785 4.52931 9.66611 4.26757C9.40437 4.00583 9.09225 3.7999 8.74865 3.66224C8.40504 3.52458 8.03708 3.45805 7.66702 3.46667ZM7.66702 7.66667C7.48078 7.67567 7.29472 7.64562 7.12079 7.57845C6.94686 7.51128 6.7889 7.40847 6.65706 7.27663C6.52521 7.14479 6.42241 6.98683 6.35523 6.81289C6.28806 6.63896 6.25801 6.4529 6.26702 6.26667C6.25801 6.08043 6.28806 5.89437 6.35523 5.72044C6.42241 5.54651 6.52521 5.38855 6.65706 5.2567C6.7889 5.12486 6.94686 5.02205 7.12079 4.95488C7.29472 4.88771 7.48078 4.85766 7.66702 4.86667C7.85325 4.85766 8.03931 4.88771 8.21324 4.95488C8.38718 5.02205 8.54514 5.12486 8.67698 5.2567C8.80882 5.38855 8.91163 5.54651 8.9788 5.72044C9.04597 5.89437 9.07602 6.08043 9.06702 6.26667C9.07602 6.4529 9.04597 6.63896 8.9788 6.81289C8.91163 6.98683 8.80882 7.14479 8.67698 7.27663C8.54514 7.40847 8.38718 7.51128 8.21324 7.57845C8.03931 7.64562 7.85325 7.67567 7.66702 7.66667Z"
        fill="currentColor"
      />
    </svg>
  );
};
