import React from "react";
import { Routes, useLocation, Route } from "react-router-dom";
import { GuestLayout } from "./GuestLayout";
import { PrivateLayout } from "./PrivateLayout";
import { PAGE_URL } from "../utils";
import * as Pages from "../pages";
import { RedirectLayout } from "./RedirectLayout";

export const AppRoutes = () => {
  const location = useLocation();

  return (
    <Routes location={location}>
      <Route element={<PrivateLayout />}>
        <Route path={PAGE_URL.DASHBOARD} element={<Pages.Dashboard />} />
        <Route path={PAGE_URL.LOGS} element={<Pages.Logs />} />
        <Route path={PAGE_URL.RBAC} element={<Pages.RBAC />} />
        <Route path={PAGE_URL.API_KEYS} element={<Pages.APIKeys />} />
        <Route path={PAGE_URL.LICENSE_MANAGEMENT} element={<Pages.LicenseManagement />} />
        <Route path={PAGE_URL.ML_CONFIGURATION} element={<Pages.MLConfiguration />} />
        <Route path={PAGE_URL.DEPLOYMENT} element={<Pages.Deployment />} />
        <Route path={PAGE_URL.THEME_CONFIGURATION} element={<Pages.ThemeConfiguration />} />
        <Route path={PAGE_URL.DOCUMENT} element={<Pages.Document />} />
        <Route
          path={`${PAGE_URL.COPILOTS_DETAILS}/:copilotId${PAGE_URL.EVIDENCE_VIEWER}`}
          element={<Pages.EvidenceViewer />}
        />
        <Route path={PAGE_URL.BILLING_DASHBOARD} element={<Pages.BillingDashboard />} />
        <Route
          path={`${PAGE_URL.COPILOTS_DETAILS}/:copilotId`}
          element={<Pages.CopilotDetails />}
        />
      </Route>
      <Route element={<GuestLayout />}>
        <Route path={PAGE_URL.LOGIN} element={<Pages.Login />} />
        <Route path={PAGE_URL.RESET_PASSWORD} element={<Pages.ResetPassword.Form />} />
        <Route path={PAGE_URL.RESET_PASSWORD_SUCCESS} element={<Pages.ResetPassword.Success />} />
        <Route path={PAGE_URL.CREATE_PASSWORD} element={<Pages.CreatePassword.Form />} />
        <Route path={PAGE_URL.CREATE_PASSWORD_SUCCESS} element={<Pages.CreatePassword.Success />} />
      </Route>
      <Route element={<RedirectLayout />}>
        <Route path={PAGE_URL.REDIRECTION} element={<Pages.Redirect />} />
      </Route>
    </Routes>
  );
};
