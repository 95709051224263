import React from "react";
import { IconsProps } from "./Icons";

export const ExternalLinkIcon = ({ className, fill, width, height, viewBox }: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : "0 0 44 44"}
      width={width ? width : "44"}
      height={height ? height : "44"}
      fill={fill ? fill : "none"}
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M28.4168 4.125C28.4168 3.76033 28.5617 3.41059 28.8195 3.15273C29.0774 2.89487 29.4271 2.75 29.7918 2.75H39.8751C40.2398 2.75 40.5895 2.89487 40.8474 3.15273C41.1053 3.41059 41.2501 3.76033 41.2501 4.125V14.2083C41.2501 14.573 41.1053 14.9227 40.8474 15.1806C40.5895 15.4385 40.2398 15.5833 39.8751 15.5833C39.5104 15.5833 39.1607 15.4385 38.9028 15.1806C38.645 14.9227 38.5001 14.573 38.5001 14.2083V7.44333L27.0968 18.8467C26.9709 18.9818 26.8191 19.0901 26.6504 19.1653C26.4818 19.2404 26.2997 19.2808 26.1151 19.2841C25.9305 19.2873 25.7471 19.2534 25.5759 19.1842C25.4046 19.1151 25.2491 19.0121 25.1185 18.8816C24.988 18.751 24.885 18.5955 24.8159 18.4243C24.7467 18.253 24.7128 18.0697 24.716 17.885C24.7193 17.7004 24.7597 17.5183 24.8349 17.3497C24.91 17.181 25.0184 17.0292 25.1535 16.9033L36.5568 5.5H29.7918C29.4271 5.5 29.0774 5.35513 28.8195 5.09727C28.5617 4.83941 28.4168 4.48967 28.4168 4.125Z"
        fill="currentColor"
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M4.58301 7.79166C4.58301 6.02066 6.02034 4.58333 7.79134 4.58333H23.3747C23.7393 4.58333 24.0891 4.72819 24.3469 4.98606C24.6048 5.24392 24.7497 5.59366 24.7497 5.95833C24.7497 6.323 24.6048 6.67274 24.3469 6.9306C24.0891 7.18846 23.7393 7.33333 23.3747 7.33333H7.79134C7.66978 7.33333 7.5532 7.38162 7.46725 7.46757C7.3813 7.55352 7.33301 7.6701 7.33301 7.79166V36.2083C7.33301 36.4613 7.53834 36.6667 7.79134 36.6667H36.208C36.3296 36.6667 36.4461 36.6184 36.5321 36.5324C36.6181 36.4465 36.6663 36.3299 36.6663 36.2083V20.625C36.6663 20.2603 36.8112 19.9106 37.0691 19.6527C37.3269 19.3949 37.6767 19.25 38.0413 19.25C38.406 19.25 38.7557 19.3949 39.0136 19.6527C39.2715 19.9106 39.4163 20.2603 39.4163 20.625V36.2083C39.4163 37.0592 39.0783 37.8753 38.4766 38.477C37.875 39.0786 37.0589 39.4167 36.208 39.4167H7.79134C6.94044 39.4167 6.12439 39.0786 5.52271 38.477C4.92103 37.8753 4.58301 37.0592 4.58301 36.2083V7.79166Z"
        fill="currentColor"
      />
    </svg>
  );
};
