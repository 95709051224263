import { Dispatch } from "redux";
import { connect } from "react-redux";
import ForgotPassword from "./ForgotPassword";
import { closeModal, RootState } from "../../../store";
import { MODAL_TYPE } from "../../../utils";

type StateProps = {
  visibility: boolean;
};
type DispatchProps = {
  handleClose: () => void;
};

export type ForgotPasswordProps = StateProps & DispatchProps;

const mapStateToProps = (state: RootState): StateProps => ({
  visibility: state.ui.modal[MODAL_TYPE.FORGOT_PASSWORD],
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  handleClose: () => {
    dispatch(closeModal(MODAL_TYPE.FORGOT_PASSWORD));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
