import { Dispatch } from "redux";
import { connect } from "react-redux";
import { closeModal, RootState } from "../../../store";
import { MODAL_TYPE } from "../../../utils";
import "./uploading-status.scss";
import { DocumentProcessingHedisStatus } from "./DocumentProcessingHedisStatus";
import "./uploading-status.scss";

type StateProps = {
  visibility: boolean;
  documentDetail: IDocument;
};
type DispatchProps = {
  handleClose: () => void;
};
export type DocumentProcessingDetailStatusProps = StateProps & DispatchProps;

const mapStateToProps = (state: RootState): StateProps => ({
  visibility: state.ui.modal[MODAL_TYPE.DOCUMENT_PROCESSING_DETAIL_HEDIS_STATS],
  documentDetail: state.document.documentDetails,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  handleClose: () => {
    dispatch(closeModal(MODAL_TYPE.DOCUMENT_PROCESSING_DETAIL_HEDIS_STATS));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentProcessingHedisStatus);
