import { apiClient } from "./apiClient";

const getHealthStatus = async (copilotName?: string | null) => {
  const res = await apiClient.get(
    `/healthmap/status${copilotName ? `?copilot=${copilotName}` : ""}`
  );
  return res;
};

const getServices = async () => {
  const res = await apiClient.get<IGetServicesResponse>("/health");
  return res;
};

export const ServiceStatusService = { getHealthStatus, getServices };
