export const PAGE_URL = {
  LOGIN: "/",
  RESET_PASSWORD: "/reset-password",
  RESET_PASSWORD_SUCCESS: "/reset-password-success",
  CREATE_PASSWORD: "/create-password",
  CREATE_PASSWORD_SUCCESS: "/create-password-success",
  DASHBOARD: "/dashboard",
  LOGS: "/logs",
  RBAC: "/rbac",
  API_KEYS: "/api-keys",
  LICENSE_MANAGEMENT: "/license-management",
  ML_CONFIGURATION: "/ml-configuration",
  DEPLOYMENT: "/deployment",
  THEME_CONFIGURATION: "/theme-configuration",
  DOCUMENT: "/document",
  REDIRECTION: "/redirect",
  EVIDENCE_VIEWER: "/evidence",
  BILLING_DASHBOARD: "/billing-dashboard",
  COPILOTS_DETAILS: "/copilots-details",
  TASKS: "/tasks",
  APPLICATIONS: "/copilots",
};

export const PAGE_TITLE = [
  { route: PAGE_URL.DASHBOARD, title: "Welcome to Genesis", displayBackButton: false },
  {
    route: PAGE_URL.COPILOTS_DETAILS,
    title: "Copilot Details",
    displayBackButton: true,
  },
  {
    route: PAGE_URL.LOGS,
    title: "Logs",
    displayBackButton: false,
  },

  {
    route: PAGE_URL.RBAC,
    title: "RBAC",
    displayBackButton: false,
  },
  {
    route: PAGE_URL.API_KEYS,
    title: "API Keys",
    displayBackButton: false,
  },
  {
    route: PAGE_URL.LICENSE_MANAGEMENT,
    title: "License Management",
    displayBackButton: false,
  },
  {
    route: PAGE_URL.ML_CONFIGURATION,
    title: "ML Configuration",
    displayBackButton: false,
  },
  {
    route: PAGE_URL.DEPLOYMENT,
    title: "Deployment",
    displayBackButton: false,
  },
  {
    route: PAGE_URL.THEME_CONFIGURATION,
    title: "Theme Configuration",
    displayBackButton: false,
  },
  {
    route: PAGE_URL.DOCUMENT,
    title: "Documents",
    displayBackButton: false,
  },
  {
    route: PAGE_URL.REDIRECTION,
    title: "Authentication",
    displayBackButton: false,
  },
  { route: PAGE_URL.EVIDENCE_VIEWER, title: "Evidence Viewer", displayBackButton: true },
  { route: PAGE_URL.BILLING_DASHBOARD, title: "Billing Dashboard", displayBackButton: false },
];
