import React from "react";
import { IconsProps } from "./Icons";

export const CollapseIcon = ({ className, fill, width, height, viewBox }: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : "0 0 22 22"}
      width={width ? width : "22"}
      height={height ? height : "22"}
      fill={fill ? fill : "currentColor"}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9167 18.3333C11.9167 17.8271 12.3271 17.4167 12.8333 17.4167H17.4167V4.58333H12.8333C12.3271 4.58333 11.9167 4.17293 11.9167 3.66667C11.9167 3.1604 12.3271 2.75 12.8333 2.75H17.4167C18.4292 2.75 19.25 3.57081 19.25 4.58333V17.4167C19.25 18.4292 18.4292 19.25 17.4167 19.25H12.8333C12.3271 19.25 11.9167 18.8396 11.9167 18.3333Z"
        fill="currentColor"
      />
      <path
        d="M2.09586 11.6422C2.01093 11.5557 1.9466 11.4565 1.90289 11.3509C1.8584 11.2436 1.8337 11.126 1.83334 11.0027L1.83333 11L1.83334 10.9973C1.83403 10.7636 1.92352 10.5301 2.10182 10.3518L5.76848 6.68515C6.12646 6.32717 6.70687 6.32717 7.06485 6.68515C7.42283 7.04313 7.42283 7.62353 7.06485 7.98151L4.96303 10.0833H13.75C14.2563 10.0833 14.6667 10.4937 14.6667 11C14.6667 11.5063 14.2563 11.9167 13.75 11.9167H4.96303L7.06485 14.0185C7.42283 14.3765 7.42283 14.9569 7.06485 15.3148C6.70687 15.6728 6.12646 15.6728 5.76848 15.3148L2.10247 11.6488L2.09586 11.6422Z"
        fill="currentColor"
      />
    </svg>
  );
};
