import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Header } from "antd/lib/layout/layout";
import { PAGE_TITLE } from "../../../utils";
import { MenuIcon } from "../../../assets/icons";
import "./app-header.scss";
import { BackArrow } from "../../../components";

export const RedirectHeader = () => {
  const location = useLocation();

  const { pageTitle, displayBackButton } = useMemo(() => {
    const pathname = location.pathname;
    let title = PAGE_TITLE[0].title;
    let backButton = false;
    PAGE_TITLE.forEach((page) => {
      if (pathname.includes(page.route)) {
        title = page.title;
        backButton = page.displayBackButton;
      }
    });
    return { pageTitle: title, displayBackButton: backButton };
  }, [location.pathname]);

  return (
    <Header className="flex aic jcsb header">
      <div className="flex gp aic">
        {displayBackButton && <BackArrow />}
        <h1 className="header-h1 m-0">{pageTitle}</h1>
      </div>
      <div className="header-right flex gp-10 aic">
        <div className="header-content-menu pr cursor-pointer">
          <a className="header-dropdown" href="#">
            <MenuIcon />
          </a>
        </div>
      </div>
    </Header>
  );
};
