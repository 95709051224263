export const LOGIN_LINK: IColorConfiguration[] = [
  { color: "#FFFFFF", key: "Default Color" },
  { color: "#8626C0", key: "Hover Color" },
];

export const LOGIN_BACKGROUND: IColorConfiguration[] = [
  { color: "#8626C0", key: "Left Color" },
  { color: "#8626C0", key: "Right Color" },
];
export const LEFT_SIDEBAR: IColorConfiguration[] = [
  { color: "#8626C0", key: "Top Color" },
  { color: "#8626C0", key: "Bottom Color" },
];
export const MODAL_HEADER: IColorConfiguration[] = [
  { color: "#8626C0", key: "Left Color" },
  { color: "#8626C0", key: "Right Color" },
];
export const HEADING_AND_TEXT_COLOR: IColorConfiguration[] = [
  { color: "#8626C0", key: "Page Title Color (H1)" },
  { color: "#8626C0", key: "Title 3 Color (H3)" },
  { color: "#8626C0", key: "Sub Title (H2)" },
  { color: "#8626C0", key: "Body Text Color" },
];

export const LOGIN_BUTTON: IColorConfiguration[] = [
  { color: "#8626C0", key: "Background Color" },
  { color: "#8626C0", key: "Border Color" },
  { color: "#8626C0", key: "Text Color" },
];
export const LOGIN_BUTTON_HOVER: IColorConfiguration[] = [
  { color: "#8626C0", key: "Background Color" },
  { color: "#8626C0", key: "Border Color" },
  { color: "#8626C0", key: "Text Color" },
];

export const GRADIENT_BUTTON: IColorConfiguration[] = [
  { color: "#8626C0", key: "Left Color" },
  { color: "#8626C0", key: "Right Color" },
  { color: "#8626C0", key: "Text Color" },
];
export const GRADIENT_BUTTON_HOVER: IColorConfiguration[] = [
  { color: "#8626C0", key: "Background Color" },
  { color: "#8626C0", key: "Text Color" },
];

export const BORDER_BUTTON: IColorConfiguration[] = [
  { color: "#8626C0", key: "Background Color" },
  { color: "#8626C0", key: "Border Color" },
  { color: "#8626C0", key: "Text Color" },
];
export const BORDER_BUTTON_HOVER: IColorConfiguration[] = [
  { color: "#8626C0", key: "Background Color" },
  { color: "#8626C0", key: "Border Color" },
  { color: "#8626C0", key: "Text Color" },
];

export const LEFT_SIDEBAR_LINKS: IColorConfiguration[] = [
  { color: "#8626C0", key: "Default Color" },
  { color: "#8626C0", key: "Hover Color" },
  { color: "#8626C0", key: "Selected Color" },
];
export const LINK_OR_ANCHOR: IColorConfiguration[] = [
  { color: "#8626C0", key: "Default Color" },
  { color: "#8626C0", key: "Hover Color" },
];
