import React from "react";
import { IconsProps } from "./Icons";

export const EditLineIcon = ({ className, fill, width, height, viewBox, onClick }: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : "0 0 10 11"}
      width={width ? width : "10"}
      height={height ? height : "11"}
      fill={fill ? fill : "currentColor"}
      onClick={onClick}
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M9.37533 0.625H0.708659C0.565 0.625 0.427225 0.682068 0.325643 0.78365C0.224061 0.885233 0.166992 1.02301 0.166992 1.16667V2.56958C0.166992 2.85288 0.282367 3.13129 0.482784 3.33171L3.41699 6.26592V10.375C3.4171 10.4673 3.44076 10.558 3.48573 10.6386C3.5307 10.7192 3.5955 10.787 3.67398 10.8355C3.75247 10.8841 3.84204 10.9118 3.93423 10.916C4.02642 10.9203 4.11817 10.9009 4.20078 10.8598L6.36745 9.77646C6.55108 9.68438 6.66699 9.49696 6.66699 9.29167V6.26592L9.6012 3.33171C9.80162 3.13129 9.91699 2.85288 9.91699 2.56958V1.16667C9.91699 1.02301 9.85992 0.885233 9.75834 0.78365C9.65676 0.682068 9.51898 0.625 9.37533 0.625ZM5.74237 5.65871C5.69197 5.70892 5.652 5.76861 5.62476 5.83434C5.59753 5.90006 5.58356 5.97052 5.58366 6.04167V8.95692L4.50033 9.49858V6.04167C4.50043 5.97052 4.48646 5.90006 4.45922 5.83434C4.43198 5.76861 4.39202 5.70892 4.34162 5.65871L1.25033 2.56958V1.70833H8.8342L8.83528 2.56579L5.74237 5.65871Z"
        fill="currentColor"
      />
    </svg>
  );
};
