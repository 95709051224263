import React, { useEffect, useState } from "react";
import { Table, Tabs } from "antd";
import { ColumnsType } from "antd/es/table";
import { getClass } from "../helper";
import { AppPagination } from "../../../../components";
import { usePagination } from "../../../../hooks";
import { documentService } from "../../../../api/document";
import {
  COPILOTS_NAME,
  getLocalDateTimeString,
  getLocalDateTimeStringFromISO,
  getOffsetFromPageAndPageSize,
} from "../../../../utils";
import "./expanded-row-content.scss";

const { TabPane } = Tabs;

interface IPageDataType {
  key: string;
  pageNumber: number;
  updatedAt: string;
  status: string;
  message: string;
  medicalEntities: string;
}

interface IDocumentsDataType extends IDocument {
  key: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  status: string;
  action: string;
  pageStatus: string;
}

const getColumnsStatus = (): ColumnsType<IPageDataType> => [
  {
    title: "Page No.",
    dataIndex: "pageNumber",
    fixed: "left",
    className: "expandedContent-pageStatusTable-pageNo",
  },
  {
    title: "Process",
    dataIndex: "message",
    key: "process",
    className: "expandedContent-pageStatusTable-message-process",
    render: (message) => (
      <div>
        {message &&
          message.map((msg: any, index: any) => (
            <div key={index} className="expandedContent-pageStatusTable-message-content">
              {msg.Step}
            </div>
          ))}
      </div>
    ),
  },
  {
    title: "Start Time",
    dataIndex: "message",
    key: "startTime",
    className: "expandedContent-pageStatusTable-message-startTime",
    render: (message) => (
      <div>
        {message &&
          message.map((msg: any, index: any) => (
            <div key={index} className="expandedContent-pageStatusTable-message-content">
              {getLocalDateTimeString(msg.StartTime)}
            </div>
          ))}
      </div>
    ),
  },
  {
    title: "End Time",
    dataIndex: "message",
    key: "endTime",
    className: "expandedContent-pageStatusTable-message-endTime",
    render: (message) => (
      <div>
        {message &&
          message.map((msg: any, index: any) => (
            <div key={index} className="expandedContent-pageStatusTable-message-content">
              {getLocalDateTimeString(msg.EndTime)}
            </div>
          ))}
      </div>
    ),
  },
  {
    title: "Updated At",
    dataIndex: "updatedAt",
    className: "expandedContent-pageStatusTable-time",
  },
  {
    title: "Status",
    dataIndex: "status",
    className: "expandedContent-pageStatusTable-status",
    render: (text, value) => (
      <div>
        <div className="status" style={{ color: getClass(value.status.replace(/-/g, " ")) }}>
          <p style={{ color: getClass(value.status) }}>{value.status.replace(/-/g, " ")}</p>{" "}
        </div>
      </div>
    ),
  },
];

const getColumnsLogs = [
  {
    title: "Process",
    dataIndex: "Process",
    key: "Process",
    className: "expandedContent-logsTable-Process",
  },
  {
    title: "Start Time",
    dataIndex: "StartTime",
    key: "StartTime",
    className: "expandedContent-logsTable-time",
  },
  {
    title: "End Time",
    dataIndex: "EndTime",
    key: "EndTime",
    className: "expandedContent-logsTable-time",
  },
];

const ExpandedRowContent = ({ record, expandedRowKey, copilotName }: any) => {
  const { onPaginationChange, page, pageSize, pageSizeOptions } = usePagination();
  const [pagesData, setPagesData] = useState<any[]>([]);
  const [logsData, setLogsData] = useState<any[]>([]);
  const [isLoadingLogs, setIsLoadingLogs] = useState(false);
  const [total, setTotal] = useState(0);
  const tabTitle =
    copilotName === "CCA" || copilotName === "A&G Insights" ? "Request Status" : "File Status";

  useEffect(() => {
    const fetchPagesData = async () => {
      try {
        setIsLoadingLogs(true);
        const { data } = await documentService.getDocumentPages(
          record.Id,
          pageSize,
          getOffsetFromPageAndPageSize(page, pageSize)
        );
        const pageStatusData = data.data.map((pageItem: any) => ({
          key: pageItem.DocumentId.toString(),
          pageNumber: pageItem.Page,
          updatedAt: getLocalDateTimeString(pageItem.UpdatedAt),
          status: pageItem.Status,
          message: pageItem.historyStats,
          medicalEntities: "",
        }));
        setPagesData(pageStatusData);
        setTotal(data.pagination?.totalCount ? data.pagination.totalCount : 0);
      } catch (error) {
        // Handle error
      } finally {
        setIsLoadingLogs(false);
      }
    };

    if (copilotName === COPILOTS_NAME.HEDIS_INSIGHTS && expandedRowKey) {
      if (record) {
        fetchPagesData();
      }
    }
  }, [expandedRowKey, page, pageSize, record]);

  useEffect(() => {
    const fetchLogsData = async () => {
      try {
        setIsLoadingLogs(true);
        const statuses =
          copilotName === COPILOTS_NAME.PEGA_PRIOR_AUTH
            ? await documentService.getPegaStats(record.DocumentUUID)
            : copilotName === COPILOTS_NAME.CCA || copilotName === COPILOTS_NAME.ANG_INSIGHTS
            ? await documentService.getAngCCAStats(record.id)
            : await documentService.getHedisStats(record.Id);
        const processLogsData = statuses.map((item: any) => ({
          key: item.DocumentUUID,
          ...item,
          StartTime: getLocalDateTimeString(item.StartTime),
          EndTime: getLocalDateTimeString(item.EndTime),
        }));
        const requestsLogsData = statuses.map((item: any) => ({
          key: item.id,
          Process: item.status,
          StartTime: getLocalDateTimeString(item.createdAt),
          EndTime: getLocalDateTimeString(item.updatedAt),
        }));
        const hedisRequestsLogsData = statuses.map((item: any) => ({
          key: item.Id,
          Process: item.Status,
          StartTime: getLocalDateTimeString(item.CreatedAt),
          EndTime: getLocalDateTimeString(item.UpdatedAt),
        }));
        setLogsData(
          copilotName === COPILOTS_NAME.CCA || copilotName === COPILOTS_NAME.ANG_INSIGHTS
            ? requestsLogsData
            : copilotName === COPILOTS_NAME.HEDIS_INSIGHTS
            ? hedisRequestsLogsData
            : processLogsData
        );
      } catch (error) {
        // Handle error
      } finally {
        setIsLoadingLogs(false);
      }
    };

    if (expandedRowKey) {
      if (record) {
        fetchLogsData();
      }
    }
  }, [expandedRowKey]);

  return (
    <div className="expandedContent">
      <h3 className="expandedContent-title">Detailed Logs</h3>
      <Tabs defaultActiveKey="1">
        <TabPane tab={tabTitle} key="1">
          <Table
            loading={isLoadingLogs}
            rowKey={record.DocumentUUID}
            columns={getColumnsLogs}
            dataSource={logsData}
            pagination={false}
          />
        </TabPane>
        {copilotName === COPILOTS_NAME.HEDIS_INSIGHTS && (
          <TabPane tab="Page Level Status" key="2">
            <AppPagination
              className="tl m-b paginationDiv"
              {...{
                showSizeChanger: true,
                current: page,
                total: total,
                pageSize,
                pageSizeOptions,
                onChange: onPaginationChange,
              }}
            />
            <Table
              loading={isLoadingLogs}
              rowKey={record.DocumentUUID}
              dataSource={pagesData}
              columns={getColumnsStatus()}
              pagination={false}
              bordered
            />
          </TabPane>
        )}
      </Tabs>
    </div>
  );
};

export default ExpandedRowContent;
