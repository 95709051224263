import { Tabs } from "antd";
import React from "react";
import { getCopilotDetailsTabs } from "..";
import "../../../assets/sass/_tabs.scss";
import { IApplication } from "../../../utils";
const { TabPane } = Tabs;

interface Props {
  onChange: (key: string) => void;
  selectedTab: string;
  application: IApplication | undefined;
}

const CopilotsDetailsTab = ({ onChange, selectedTab, application }: Props) => {
  const COPILOT_DETAILS_TAB = getCopilotDetailsTabs(application?.title ? application.title: '') 
  return (
      <div className="tabs">
        <Tabs onChange={onChange} activeKey={selectedTab}>
          {Object.values(COPILOT_DETAILS_TAB).map((key) => (
            <TabPane tab={key} key={key} />
          ))}
        </Tabs>
    </div>
  );
};

export default CopilotsDetailsTab;
