import React from "react";
import { IconsProps } from "./Icons";

export const StopProcessIcon = ({ className, fill, width, height, viewBox }: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      enableBackground="new 0 0 24 24"
      xmlSpace="preserve"
      width={width}
      height={height}
      style={{ fontSize: "24px", fill: "red" }}
    >
      <path d="M12,6c3.9,0,7,3.1,7,7s-3.1,7-7,7s-7-3.1-7-7S8.1,6,12,6 M12,5c-4.4,0-8,3.6-8,8s3.6,8,8,8s8-3.6,8-8S16.4,5,12,5L12,5z" />
      <rect x="9" y="10" width="6" height="6" />
    </svg>
  );
};
