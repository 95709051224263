import React from "react";
import {
  BrandingAndLogo,
  ButtonsLinksAndSidebar,
  GradientsAndBackgrounds,
  HeadingAndTextColor,
} from "./theme-configuration-container";
import { Button } from "antd";
import "./theme-configuration-container/theme-configuration.scss";
export const ThemeConfigurationContainer = () => {
  return (
    <>
      <div className="theme-config-container">
        <div>
          <BrandingAndLogo />
          <GradientsAndBackgrounds />
          <HeadingAndTextColor />
        </div>
        <div>
          <ButtonsLinksAndSidebar />
        </div>
      </div>
      <div className="tr">
        <Button className="fill">Update Theme</Button>
      </div>
    </>
  );
};
