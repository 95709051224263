import React, { Dispatch, SetStateAction } from "react";
import { Checkbox, CheckboxOptionType } from "antd";
import type { CheckboxValueType } from "antd/es/checkbox/Group";
import "./legends.scss";
import { CATEGORY_COLOR_MAPPING } from "./evidenceViewerUtil";
import { toTitleCase } from "../../../utils";

interface Props {
  selectedLegends: string[];
  setSelectedLegends: Dispatch<SetStateAction<string[]>>;
}

const getOptions = () => {
  const res = [] as CheckboxOptionType[];
  const addExtraSCandidates = Object.entries(CATEGORY_COLOR_MAPPING)
    .map((item) => item[0])
    .slice(0, 3);
  Object.entries(CATEGORY_COLOR_MAPPING).forEach(([key, value]) => {
    const addExtraS = addExtraSCandidates.includes(key);
    res.push({
      label: (
        <div className="legends-item" style={{ background: value, color: "#fff" }}>
          {`${toTitleCase(key.split("_").join(" "))}${addExtraS ? "s" : ""}`}
        </div>
      ),
      value: key,
    });
  });
  return res;
};

export const Legends = ({ selectedLegends, setSelectedLegends }: Props) => {
  const onChange = (checkedValues: CheckboxValueType[]) => {
    setSelectedLegends(checkedValues.map((item) => (typeof item === "string" ? item : "")));
  };

  return (
    <div className="legends">
      <Checkbox.Group options={getOptions()} value={selectedLegends} onChange={onChange} />
    </div>
  );
};
