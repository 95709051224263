import React from "react";
import { RolesMappings } from "./rbac-container";

export const RBACContainer = () => {
  return (
    <div>
      <RolesMappings />
    </div>
  );
};
