import React, { useCallback, useEffect } from "react";
import { Loading } from "../components";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ActiveDirectoryService } from "../api";
import { useDispatch, useSelector } from "react-redux";
import { RootState, loginUser } from "../store";
import { PAGE_URL, openNotificationWithIcon } from "../utils";
import { PageWrapper } from "../containers";
import { RBACService } from "../api/rbac";

const Redirect = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { verifier, licenseKey } = useSelector((state: RootState) => state.authUser);

  const validateAzureADCode = useCallback(
    async (code: string) => {
      try {
        const { data } = await ActiveDirectoryService.activeDirectoryAcquireToken(
          code,
          `${window.location.origin}/redirect`,
          verifier
        );
        if (!data.data) {
          openNotificationWithIcon(
            "",
            "Authorized Access! Check Azure AD group or contact admin.",
            "error"
          );
          return false;
        }

        const obj: IAuthUser = {
          accessToken: data.data.account.accessToken,
          firstName: data.data.account.name,
          lastName: "",
          email: data.data.account.username,
          role: {
            code: data.data.role.id.toString(),
            name: data.data.role.name,
            id: data.data.role.id,
          },
        };
        dispatch(loginUser(obj));
        return true;
      } catch (err: any) {
        openNotificationWithIcon("", err.response.data.message, "error");
      }
    },
    [dispatch, verifier]
  );

  const verifyLicenseKey = useCallback(async (key: string) => {
    try {
      const { data } = await RBACService.validateLicenseKey(key);
      return data.isValid;
    } catch (err: any) {
      openNotificationWithIcon("", "Error Validating License Key", "error");
    }
  }, []);

  useEffect(() => {
    const validateADCodeAndLicenseKey = async () => {
      // if (searchParams.get("code")) {
      //   await validateAzureADCode(searchParams.get("code") || "");
      //   if (licenseKey) {
      //     const isValid = await verifyLicenseKey(licenseKey);
      //     if (isValid) {
      //       navigate(PAGE_URL.RBAC);
      //     } else {
      //       dispatch(openModal(MODAL_TYPE.LICENSE_KEY));
      //     }
      //   } else {
      //     dispatch(openModal(MODAL_TYPE.LICENSE_KEY));
      //   }
      // } else {
      //   navigate(PAGE_URL.LOGIN);
      // }
      const code = searchParams.get("code");
      if (code) {
        const isAzureADCodeValid = await validateAzureADCode(code || "");
        const redirectPath = localStorage.getItem("redirectPath") || "/dashboard";
        localStorage.removeItem("redirectPath");
        navigate(isAzureADCodeValid ? redirectPath : PAGE_URL.LOGIN);
      } else {
        navigate(PAGE_URL.LOGIN);
      }
    };
    validateADCodeAndLicenseKey();
  }, [dispatch, licenseKey, navigate, searchParams, validateAzureADCode, verifyLicenseKey]);

  return <PageWrapper pageContainerChildren={<Loading tip={"Authenticating"} />} />;
};

export default Redirect;
