import React from "react";
import {
  BORDER_BUTTON,
  BORDER_BUTTON_HOVER,
  GRADIENT_BUTTON,
  GRADIENT_BUTTON_HOVER,
  LEFT_SIDEBAR_LINKS,
  LINK_OR_ANCHOR,
  LOGIN_BUTTON,
  LOGIN_BUTTON_HOVER,
} from "../../../utils";
import { ColorConfigurationSetting } from "./ColorConfigurationSetting";

export const ButtonsLinksAndSidebar = () => {
  return (
    <div className="theme-config-card">
      <h4>Buttons, Links and Sidebar</h4>
      <div className="theme-config-card-container">
        <div>
          <h5>Login Button</h5>
          {LOGIN_BUTTON.map((item, index) => (
            <ColorConfigurationSetting key={index} colorConfiguration={item} />
          ))}
        </div>
        <div>
          <h5>Login Button Hover</h5>
          {LOGIN_BUTTON_HOVER.map((item, index) => (
            <ColorConfigurationSetting key={index} colorConfiguration={item} />
          ))}
        </div>
        <span className="card-divider"></span>
        <div>
          <h5>Gradient Button</h5>
          {GRADIENT_BUTTON.map((item, index) => (
            <ColorConfigurationSetting key={index} colorConfiguration={item} />
          ))}
        </div>
        <div>
          <h5>Gradient Button Hover</h5>
          {GRADIENT_BUTTON_HOVER.map((item, index) => (
            <ColorConfigurationSetting key={index} colorConfiguration={item} />
          ))}
        </div>
        <span className="card-divider"></span>
        <div>
          <h5>Border Button</h5>
          {BORDER_BUTTON.map((item, index) => (
            <ColorConfigurationSetting key={index} colorConfiguration={item} />
          ))}
        </div>
        <div>
          <h5>Border Button Hover</h5>
          {BORDER_BUTTON_HOVER.map((item, index) => (
            <ColorConfigurationSetting key={index} colorConfiguration={item} />
          ))}
        </div>
        <span className="card-divider"></span>
        <div>
          <h5>Left Sidebar Links</h5>
          {LEFT_SIDEBAR_LINKS.map((item, index) => (
            <ColorConfigurationSetting key={index} colorConfiguration={item} />
          ))}
        </div>
        <div>
          <h5>Link/Anchor</h5>
          {LINK_OR_ANCHOR.map((item, index) => (
            <ColorConfigurationSetting key={index} colorConfiguration={item} />
          ))}
        </div>
      </div>
    </div>
  );
};
