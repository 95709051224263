import React from "react";
import { Cost, Requests, Tokens, TokensUsage, UserTraffic } from "./billing-dashboard-container";
import "./billing-dashboard-container.scss";

export const BillingDashboardContainer = () => {
  return (
    <div className="billing-dashboard-container">
        <TokensUsage />
      {/* <div className="billing-dashboard-container-row"> */}
        {/* <Tokens /> */}
        {/* <UserTraffic /> */}
      {/* </div> */}
      {/* <div className="billing-dashboard-container-row">
        <div className="billing-dashboard-container-inner-row">
          <Cost />
          <Requests />
        </div>
        <Tokens />
      </div> */}
    </div>
  );
};
